import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import Home from "@mui/icons-material/Home";

import { useTranslation } from "react-i18next";

export const TotalSalesInventory = ({ inventorySalesInfo }) => {
  const { t } = useTranslation();
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {t("salesInventoryCount")}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {inventorySalesInfo.inventoryCount}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: "#daa335",
                height: 56,
                width: 56,
              }}
            >
              <Home />
            </Avatar>
          </Grid>
        </Grid>
        <Box>
          <Typography color="textSecondary">
            {t("salesRate")} {inventorySalesInfo.salesRate}%
          </Typography>
          <LinearProgress
            value={parseFloat(inventorySalesInfo.salesRate)}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
