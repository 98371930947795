import React from "react";
import style from "./subheader.module.scss";
import { BackIcon } from "../../assets/icons";
import { useNavigate } from "react-router";

const LeadCard = ({ bidDetailData }) => {
  const navigate = useNavigate();

  return (
    <div className={style.leadCard}>
      <button
        className={style.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </button>
      {bidDetailData ? (
        <h2 style={{ marginLeft: "1rem" }}>{bidDetailData.bidName}</h2>
      ) : null}
    </div>
  );
};
function BidDetailMenu({ href, bidDetaillData }) {
  //console.log(bidDetaillData);
  return (
    <div className={style.leadDetailMenu}>
      <div className={style.left}>
        {/* <LeftButton href={href} /> */}
        <LeadCard bidDetailData={bidDetaillData} />
      </div>
      <div className={style.right}>
        {/* <NewButton title={"E-Mail Gönder"} /> */}
      </div>
    </div>
  );
}

export default BidDetailMenu;
