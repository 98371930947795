import style from "./Rapor.module.scss";
import { SubHeader, SideNav } from "../../components";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DropInput2 } from "../../components/Forms/Inputs";
import GenelMusteriAnaliz from "./RaporTypes/GenelMusteriAnaliz";
import GenelSatis from "./RaporTypes/GenelSatis";
import KaynakBazliIletisim from "./RaporTypes/KaynakBazliIletisim";
import GunlukRapor from "./RaporTypes/GunlukRapor";
import GunSonuRaporu from "./RaporTypes/GunSonuRaporu";
import IletisimTipiRaporu from "./RaporTypes/IletisimTipiRaporu";
import IptalRaporu from "./RaporTypes/IptalRaporu";
import SatisKaporaOpsiyonRaporu from "./RaporTypes/SatisKaporaOpsiyonRaporu";
import Personelperformans from "./RaporTypes/Personelperformans";
import PersonelperformansDetay from "./RaporTypes/PersonelperformansDetay";
import GuncelAktiviteAsamaRaporu from "./RaporTypes/GuncelAktiviteAsamaRaporu";
import SatisTahsilat from "./RaporTypes/SatisTahsilat";
import SatisTahsilatOzet from "./RaporTypes/SatisTahsilatOzet";
import UrunTipiStokRaporu from "./RaporTypes/UrunTipiStokRaporu";
import DaireTipiStokRaporu from "./RaporTypes/DaireTipiStokRaporu";
import LogRaporu from "./RaporTypes/LogRaporu";

const Rapor = () => {
  const user = useSelector((state) => state.user.userData);
  const [raporType, setRaporType] = useState("");

  useEffect(() => {}, []);
  let listReport = [
    { name: "Genel Müşteri Analiz Raporu", value: "gma" },
    { name: "Genel Satış Raporu", value: "gs" },
    { name: "Kaynak Bazlı İletişim Raporu", value: "kbi" },
    { name: "Günlük Rapor", value: "gr" },
    { name: "Gün Sonu Raporu", value: "gsr" },
    { name: "İletişim Tipi Raporu", value: "itr" },
    { name: "İptal Raporu", value: "ir" },
    { name: "Satış, Kapora, Opsiyon Raporu", value: "skor" },
    { name: "Personel Performans Raporu", value: "ppr" },
    { name: "Hiç Aktivite Yapılmayan Müşteriler Raporu", value: "pprd" },
    { name: "Güncel Aktivite Aşama Raporu", value: "gaar" },
    // { name: "Satış Tahsilat", value: "st" },
    // { name: "Satış Tahsilat (Özet)", value: "stö" },
    { name: "Ürün Tipi Stok Raporu", value: "utsr" },
    // { name: "Daire Tipi Stok Raporu", value: "dtsr" },
  ];
  if (user.department === "admin") {
    listReport.push({ name: "Log Raporu", value: "logs" });
  }
  return (
    <div className={style.raporContainer}>
      <div>
        <SubHeader rapor form />
        <div className={style.content}>
          <SideNav rapor />
          <div className={style.info}>
            <div className={style.rapors}>
              <DropInput2
                data={listReport}
                setDropValue={(text) => {
                  setRaporType(text);
                }}
                isDefault={raporType === "" ? "asda" : raporType}
                placeholder={"Rapor Tipi"}
                title={"Rapor Tipi"}
              />
              {raporType === "gma" && <GenelMusteriAnaliz />}
              {raporType === "gs" && <GenelSatis />}
              {raporType === "kbi" && <KaynakBazliIletisim />}
              {raporType === "gr" && <GunlukRapor />}
              {raporType === "gsr" && <GunSonuRaporu />}
              {raporType === "itr" && <IletisimTipiRaporu />}
              {raporType === "ir" && <IptalRaporu />}
              {raporType === "skor" && <SatisKaporaOpsiyonRaporu />}
              {raporType === "ppr" && <Personelperformans />}
              {raporType === "pprd" && <PersonelperformansDetay />}
              {raporType === "gaar" && <GuncelAktiviteAsamaRaporu />}
              {raporType === "st" && <SatisTahsilat />}
              {raporType === "stö" && <SatisTahsilatOzet />}
              {raporType === "utsr" && <UrunTipiStokRaporu />}
              {raporType === "dtsr" && <DaireTipiStokRaporu />}
              {raporType === "logs" && <LogRaporu />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rapor;
