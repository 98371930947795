import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getDropDownItems = async (jwt, data) => {
  const request = await axios.post(
    `${BaseUrl}/api/system/getDropDownItems`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    data: request.data.data,
  };
  return payload;
};

export const updateDropDownItems = async (jwt, data) => {
  const request = await axios.post(
    `${BaseUrl}/api/system/updateDropDownItems`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    data: request.data.data,
  };
  return payload;
};
