import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContactsIcon from "@mui/icons-material/Contacts";

import { useTranslation } from "react-i18next";

export const TotalContact = ({ contanctInfo }) => {
  const { t } = useTranslation();
  const dailyContactColor =
    contanctInfo.dailyContactCount < 1 ? "red" : "green";

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {t("contactCount")}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="textPrimary" variant="h4">
                {contanctInfo.contactCount}
              </Typography>
              <Typography
                sx={{ color: dailyContactColor, fontWeight: "bold" }}
                variant="h8"
              >
                {t("daily")}: {contanctInfo.dailyContactCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: "#daa335",
                height: 56,
                width: 56,
              }}
            >
              <ContactsIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            pt: 2,
          }}
        >
          {parseInt(contanctInfo.percentageChange > 0) ? (
            <ArrowUpwardIcon color="success" />
          ) : (
            <ArrowDownwardIcon color="error" />
          )}
          <Typography
            variant="body2"
            sx={{
              mr: 1,
            }}
          >
            {contanctInfo.percentageChange} %
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {t("gecenHaftaGore")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
