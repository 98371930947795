import style from "./sidefilter.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Input = ({ title, value, setChange }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState();
  const toggle = (e) => {
    setOpen(e.target.checked);
    setChange("");
  };

  
  return (
    <>
    
      <label className={style.input}>
        <input onChange={(e) => toggle(e)} type="checkbox" />
        <h3>{title}</h3>
      </label>
      {isOpen ? (
        <input
          className={style.inputBox}
          type="text"
          placeholder={t("bilgiGiriniz")}
          value={value}
          onChange={(e) => setChange(e.target.value)}
        />
      ) : null}
    </>
  );
};

export default Input;
