import style from "./projectcreate.module.scss";
import React, { useState, useEffect } from "react";
import { SubHeader } from "../../components";
import { TextInput, Textarea, DropInput2 } from "../../components/Forms/Inputs";
import axios from "axios";
import { BaseUrl } from "../../ApiConfig";
import ReactTooltip from "react-tooltip";
import ProjectPopup from "./ProjectPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {useTranslation} from "react-i18next";

const ProjectCreate = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [inventoryowners, setInventoryowners] = useState([]);
  const [isGenel, setIsGenel] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [project, setProject] = useState({
    projectName: "",
    address: "",
    city: "",
    country: "",
    inventoryOwnerId: "",
    ownerInventoryName: "",
  });

  const _onClick = () => {
    if (project.projectName.length < 2) {
      setErrorText(t("projeEklemeErrorProjeIsmi"));
      setIsPopupOpen(true);
      return;
    } else if (project.address.length < 2) {
      setErrorText(t("projeEklemeErrorProjeAdres"));
      setIsPopupOpen(true);
      return;
    } else if (project.city.length < 2) {
      setErrorText(t("projeEklemeErrorProjeSehir"));
      setIsPopupOpen(true);
      return;
    } else if (project.country.length < 2) {
      setErrorText(t("projeEklemeErrorProjeUlke"));
      setIsPopupOpen(true);
      return;
    } else if (isGenel && project.inventoryOwnerId.length === 0) {
      setErrorText(t("projeEklemeErrorProjeGenel"));
      setIsPopupOpen(true);
      return;
    }

    axios
      .post(`${BaseUrl}/api/project/add/`, {
        project: project,
        hasGenel: isGenel,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        navigate("/project-list", { replace: true });
      })
      .catch((err) => console.log(err.response));
  };

  useEffect(() => {
    setInventoryowners([]);
    const userType = user.userType;
    axios
      .get(`${BaseUrl}/api/contact/getallInventory/${userType}`,{
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        //console.log(res.data.contact);
        res.data.contact.forEach((element) => {
          //console.log(element)
          if (element.shape === "Kiralayan" || element.shape === "Satıcı") {
            setInventoryowners((prevState) => [
              ...prevState,
              {
                name: element.name + " " + element.surname,
                value: element._id,
              },
            ]);
          }
        });
      })
      .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPopupOpen && (
        <ProjectPopup
          closeClick={() => setIsPopupOpen(false)}
          errorText={errorText}
        />
      )}
      <SubHeader
        onClick={_onClick}
        createProject
        form
        newButtonName={t("kaydet")}
        // buttonName="Kaydet ve Yeni Ekle"
        path="/project-list"
      />
      <div className={style.createLeadContent}>
        <div className={style.leadForm}>
          <div className={style.leftLeadForm}>
            <TextInput
              value={project.projectName}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, projectName: text };
                })
              }
              title={t("projeAdi")}
            />

            <TextInput
              value={project.city}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, city: text };
                })
              }
              title={t("il")}
            />
            <TextInput
              value={project.country}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, country: text };
                })
              }
              title={t("ulke")}
            />
            <p className={style.checkboxContainer}>
              <input
                type="checkbox"
                value={isGenel}
                checked={isGenel}
                id="genel"
                name="genel"
                onChange={() => {
                  setIsGenel(!isGenel);
                }}
              />
              <ReactTooltip id="global" type="warning" aria-haspopup="true">
              {t("projePopUptext1")} <strong> {t("genel")} {t("urunTipi")} </strong> {t("projePopUptext2")} 
              </ReactTooltip>

              <label data-tip data-for="global" for="genel">
              {t("projeLabelText1")} <strong> {t("genel")}</strong>{t("projeLabelText2")}
              </label>
            </p>
            {isGenel && (
              <DropInput2
                data={inventoryowners}
                title={t("malSahibi")}
                placeholder={t("malSahibi")}
                setDropValue={(text) => {
                  const find = inventoryowners.find((element) => {
                    return element.value === text;
                  });

                  setProject((prev) => {
                    return {
                      ...prev,
                      inventoryOwnerId: text,
                      ownerInventoryName: find.name,
                    };
                  });
                }}
              />
            )}
          </div>
          <div className={style.rightLeadForm}>
            <Textarea
              value={project.address}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, address: text };
                })
              }
              title={t("adres")}
              placeholder={t("adres")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCreate;
