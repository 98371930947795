import React, { useState, useEffect } from "react";
import style from "./createinventory.module.scss";
import { SubHeader } from "../../components";

import {
  TextInput,
  DropInput,
  DropInput2,
  NumberInput,
  NumberInputM2,
  DocumentInput3,
  Textarea,
  DisabledNumberInput,
  DropInputJSONProject,
  DateInput,
  DocumentInputIMG,
} from "../../components/Forms/Inputs";
import axios from "axios";
import { BaseUrl } from "../../ApiConfig";
import Popup from "../CreateLead/LeadPopup";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function CreateInventory() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  let userType = user.userType;
  const [popup, setPopup] = useState(false);
  const [selectedCorporateStatusPopup, setSelectedCorporateStatusPopup] =
    useState(false);
  const [projectPopup, setProjectPopup] = useState(false);
  const [inventoryCategoryPopup, setInventoryCategoryPopup] = useState(false);
  const [uniteSunumSekliPopup, setUniteSunumSekliPopup] = useState(false);
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedCorporateStatus, setSelectedCorporateStatus] = useState("");
  // const [ownerContactNames, setOwnerContactNames] = useState();
  // const [ownerContactNamesSalses, setOwnerContactNamesSales] = useState();
  // const [ownerContactNamesRent, setOwnerContactNamesRent] = useState();

  const [inventoryowners, setInventoryowners] = useState([]);

  const [allProjectName, setAllProject] = useState();
  const [project, setProject] = useState({
    id: "",
    projectName: "",
  });
  const [independentSectionPlan, setIndependentSectionPlan] = useState();
  const [situationPlan, setSituationPlan] = useState();
  const [floorPlan, setFloorPlan] = useState();
  const [locusPlan, setLocusPlan] = useState();
  const [projectImage, setProjectImage] = useState();
  const [inventory, setInventory] = useState({
    address: "",
    country: "",
    city: "",
    inventoryName: "",
    inventoryUseShape: "",
    vatRate: 0,
    numberRooms: "",
    island: "",
    parsel: "",
    pafta: "",
    blockNo: "",
    floor: "",
    independentNo: "",
    circleType: "",
    brutM2: "",
    netM2: "",
    gardenM2: "",
    poolM2: "",
    terraceM2: "",
    balconyM2: "",
    currency: "TRY",
    salesPriceM2: 0,
    salesRentM2: 0,
    excludingVatListPrice: 0,
    withVatListPrice: 0,
    rentPrice: 0,
    inventoryOwnerId: "",
    status: "",
    exteriorDoorNo: "",
    inventoryCategory: selectedMenu,
    //files
    //kurumsal alan
    corporateStatus: "", // kuumsal statü
    buildingName: "", //bina adı
    buildingOpeningDate: "", //bina açılış tarihi
    developer: "", //geliştirici
    floorAboveGroundTotalLength: "", //zemin üzeri toplam kat sayısı
    floorTotalLength: "", //toplam kat sayısı
    constructionZone: "", //İnşaat alanı
    totalOfficeSpace: "", //toplam ofis alanı
    totalStoreSpace: "", //toplam dükkan alanı
    ceilingHeight: "", //tavan yüksekliği brüt
    productivity: "", //verimlilik
    storeOptions: "", //dükkan seçenekleri
    floorOptions: "", //kat seçenekleri
    deliveryShape: "", //teslim şekli
    rentFreePeriod: "", //Kirasız dönem
    taxStatus: "", //vergi durumu
    dues: "", //aidat
    duesTotalM2: "", // metrekare toplam aidat
    parkingRight: "", //Otopark hakkı
    airConditioning: "", //iklimlendirme
    mechanicalDetail: "", //mekanik ayrıntı
    numberOfElevators: "", //asansor sayısı
    transportation: "", //ulaşım
    socialOpportunities: "", //sosyal olanaklar
    website: "", //websitesi
    note: "", //not

    //ORTAK ALAN YENI [exelden eklenen (import degil baska exel)]
    facadeWidth: "", //Cephe Genişliği (m)
    grossCeilingHeight: "", //Brüt Tavan Yüksekliği
    atLevelWarehouse: "", //Hemzemin Depo Alanı
    grossAreaBasementWarehouse: "", //Alt Kat Depo Brüt Alanı
    warehouseShaftArea: "", //Depo Şaft Alanı
    openLoadingArea: "", //Açık Yükleme Alanı
    dedicatedWorkspace: "", //Tahsisli Çalışma Alanı
    administrativeOfficeArea: "", //İdari Ofis Alanı
    shelfArea: "", //Sahanlık Alanı
    officeGrossArea: "", //Ofis Brüt Alanı
    truckEntryStatus: "", //Tır Girebilme Durumu
    craneInfrastructure: "", //Kreyn Altyapısı
    assignedRoofMechanicalSpace: "", //Tahsisli Çatı Mekanik Alanı
    totalSalableArea: "", //Toplam Satılabilir Alan
    //  Atolye sadece [exelden eklenen (import degil baska exel)]
    workshopGrossArea123: "", //Atölye Brüt Alanı (1-2-3. akslar)
    workshopGrossArea45: "", //Atölye Brüt Alanı (4-5. akslar)
    workshopGrossArea67: "", //Atölye Brüt Alanı (6-7. akslar)
    workshopGrossArea89: "", //Atölye Brüt Alanı (8-9. akslar)
    workshopShaftArea: "", //Atölye Şaft Alanı
    // dukkan sadece  [exelden eklenen (import degil baska exel)]
    shopGrossArea: "", //Dükkan Brüt Alanı
    shopOpenAreaGrossArea: "", //Dükkan Açık Alan Brüt Alanı
    shopLevelStorageArea: "", //Dükkan Hemzemin Depo Alanı
    shopDownstairsStorageArea: "", //Dükkan Alt Kat Depo Alanı
    shopShaftArea: "", //Dükkan Şaft Alanı
    LandscapeRefractions: "", //manzaraKirilmalari
    Landscape: "", //manzara
    Direction: "", //yön
    flatBrutM2: "", //brüt metrekare
    floorBrutM2: "", //brüt metrekare
  });
  useEffect(() => {
    setInventory({
      ...inventory,
      inventoryCategory: selectedMenu,
      corporateStatus: selectedCorporateStatus,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, selectedCorporateStatus]);
  const _onClick = () => {
    let formData = new FormData();
    formData.append("independentSectionPlan", independentSectionPlan);
    formData.append("situationPlan", situationPlan);
    formData.append("floorPlan", floorPlan);
    formData.append("locusPlan", locusPlan);
    formData.append("projectImage", projectImage);
    formData.append("inventory", JSON.stringify(inventory));
    axios
      .post(`${BaseUrl}/api/project/addInventory/${project.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log(res);
        setTimeout(() => {
          navigate(`/inventory-list`, { replace: true });
        }, 1500);
      })
      .catch((err) => console.log(err.response));
  };
  useEffect(() => {
    const userType = user.userType;
    axios
      .get(`${BaseUrl}/api/contact/getallInventory/${userType}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        // const contact = res.data.ownerContact;
        // const contactSales = res.data.ownerContactSales;
        // const contactRent = res.data.ownerContactRent;
        // setOwnerContactNames(contact);
        // setOwnerContactNamesSales(contactSales);
        // setOwnerContactNamesRent(contactRent);

        //buraasi degisicek
        res.data.contact.forEach((element) => {
          if (element.shape === "Kiralayan" || element.shape === "Satıcı") {
            setInventoryowners((prevState) => [
              ...prevState,
              {
                name: element.name + " " + element.surname,
                value: element._id,
              },
            ]);
          }
        });
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getAll/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setAllProject(res.data.project);
      })
      .catch((err) => console.log(err.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const kdv = () => {
      var fiyat = inventory.excludingVatListPrice;
      var kdv = inventory.vatRate;
      var toplamTutar = parseFloat(fiyat) + (fiyat * kdv) / 100;

      return setInventory((prev) => {
        return { ...prev, withVatListPrice: toplamTutar };
      });
    };
    kdv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory.excludingVatListPrice]);
  useEffect(() => {
    if (inventory.excludingVatListPrice && inventory.brutM2)
      setInventory((prev) => {
        return {
          ...prev,
          salesPriceM2:
            Number(inventory.excludingVatListPrice) / Number(inventory.brutM2),
        };
      });
  }, [
    inventory.salesPriceM2,
    inventory.excludingVatListPrice,
    inventory.brutM2,
  ]);
  const statusRentDatas = [
    { name: t("kirayaUygun"), value: "Kiraya uygun" },
    {
      name: t("kaporali"),
      value: "Kaporalı",
    },
    {
      name: t("opsiyonlu"),
      value: "Opsiyonlu",
    },
    { name: t("kirayaUygunDegil"), value: "Kiraya uygun değil" },
  ];
  const statusSelDatas = [
    { name: t("satisaAcikStok"), value: "Satışa Açık (Stok)" },
    /*
    {
      name: t("satisaAcikOpsiyonlu"),
      value: "Satışa Açık (Opsiyonlu)",
    },
    {
      name: t("satisaAcikKaporali"),
      value: "Satışa Açık (Kaparolu)",
    },
    {
      name: t("satisaKapaliKaparoli"),
      value: "Satış (Kaparo)",
    },
    */
    {
      name: t("satisaKapaliSozlesmeli"),
      value: "Satış (Sözleşme)",
    },
    {
      name: t("satisaKapaliGorusmeAsamasi"),
      value: "Satış (Görüşme Aşamasında)",
    },
    {
      name: t("satisaKapaliOpsiyon"),
      value: "Satış (Opsiyon)",
    },
    {
      name: t("satisOnay"),
      value: "Satış Onay",
    },
    { name: t("satisaKapali"), value: "Satışa Kapalı" },
    { name: t("barter"), value: "Barter" },
  ];

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return " ₺";
    } else if (value === "USD") {
      return " $";
    } else if (value === "EUR") {
      return " €";
    } else if (value === "GBP") {
      return " £";
    } else if (value === "RUB") {
      return " ₽";
    } else {
      return " ₺";
    }
  };

  return (
    <>
      <div>
        <SubHeader
          onClick={() => {
            if (selectedCorporateStatus === "") {
              return setSelectedCorporateStatusPopup(true);
            }
            if (inventory.inventoryCategory === "") {
              return setInventoryCategoryPopup(true);
            }
            if (project.projectName === "") {
              return setProjectPopup(true);
            }
            if (inventory.status === "") {
              return setUniteSunumSekliPopup(true);
            }
            if (inventory.inventoryOwnerId.length > 0) {
              _onClick();
            } else {
              setPopup(true);
            }
          }}
          form
          createInventory
          newButtonName={t("kaydet")}
          // buttonName="Kaydet ve Yeni Ekle"
          path="/inventory-list"
          setSelectedMenu={setSelectedMenu}
          setSelectedCorporateStatus={setSelectedCorporateStatus}
        />
        <div className={style.content}>
          <div className={style.createBidContent}>
            <div className={style.bidForm}>
              <div className={style.leftBidForm}>
                <DropInputJSONProject
                  data={
                    allProjectName
                      ? allProjectName.map((item) => {
                          return {
                            value: {
                              id: item._id,
                              name: item.projectName,
                              city: item.city,
                              country: item.country,
                              address: item.address,
                            },
                            name: item.projectName,
                          };
                        })
                      : null
                  }
                  title={t("projeAdi")}
                  placeholder={t("projeAdi")}
                  setDropValue={(text) => {
                    if (text === "0") {
                      //env dosyası açılıp eklenilecek
                      setProject({ id: "0" });
                      setInventory((prev) => {
                        return { ...prev, city: "", country: "", address: "" };
                      });
                    } else {
                      setInventory((prev) => {
                        return {
                          ...prev,
                          city: JSON.parse(text).city,
                          country: JSON.parse(text).country,
                          address: JSON.parse(text).address,
                        };
                      });
                      setProject({
                        projectName: JSON.parse(text).name,
                        id: JSON.parse(text).id,
                      });
                    }
                  }}
                />

                <TextInput
                  title={t("uniteIsmi")}
                  placeholder={t("uniteIsmi")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, inventoryName: text };
                    })
                  }
                />
                <DropInput
                  data={
                    selectedMenu === "kiralık"
                      ? statusRentDatas
                      : selectedMenu === "satılık"
                      ? statusSelDatas
                      : statusSelDatas
                  }
                  title={t("uniteSunumSekli")}
                  placeholder={t("uniteSunumSekli")}
                  setDropValue={(text) => {
                    setInventory((prev) => {
                      return { ...prev, status: text };
                    });
                  }}
                  styleType={2}
                />
                {!selectedCorporateStatus.length > 0 ? (
                  userType === "Bireysel" ? (
                    <>
                      <DropInput
                        data={[
                          { name: t("peysaj"), value: "Peyzaj" },
                          { name: t("blok"), value: "Blok" },
                          { name: t("deniz"), value: "Deniz" },
                          { name: t("gol"), value: "Göl" },
                          { name: t("nehir"), value: "Nehir" },
                          { name: t("siteDisi"), value: "Site Dışı" },
                          { name: t("sokakcadde"), value: "Sokak/Cadde" },
                          { name: t("orman"), value: "Orman" },
                        ]}
                        title={t("manzaraKirilmalari")}
                        placeholder={t("manzaraKirilmalari")}
                        setDropValue={(text) => {
                          setInventory((prev) => {
                            return { ...prev, LandscapeRefractions: text };
                          });
                        }}
                        styleType={2}
                      />
                      <DropInput
                        data={[
                          { name: t("peysaj"), value: "Peyzaj" },
                          { name: t("blok"), value: "Blok" },
                          { name: t("deniz"), value: "Deniz" },
                          { name: t("gol"), value: "Göl" },
                          { name: t("nehir"), value: "Nehir" },
                          { name: t("siteDisi"), value: "Site Dışı" },
                          { name: t("sokakcadde"), value: "Sokak/Cadde" },
                          { name: t("orman"), value: "Orman" },
                        ]}
                        title={t("manzara")}
                        placeholder={t("manzara")}
                        setDropValue={(text) => {
                          setInventory((prev) => {
                            return { ...prev, Landscape: text };
                          });
                        }}
                        styleType={2}
                      />
                      <DropInput
                        data={[
                          { name: t("kuzey"), value: "Kuzey" },
                          { name: t("guney"), value: "Güney" },
                          { name: t("dogu"), value: "Doğu" },
                          { name: t("bati"), value: "Batı" },
                          { name: t("kuzeyBati"), value: "Kuzey Batı" },
                          { name: t("kuzeyDogu"), value: "Kuzey Doğu" },
                          { name: t("guneyBati"), value: "Güney Batı" },
                          { name: t("guneyDogu"), value: "Güney Doğu" },
                        ]}
                        title={t("yon")}
                        placeholder={t("yon")}
                        setDropValue={(text) => {
                          setInventory((prev) => {
                            return { ...prev, Direction: text };
                          });
                        }}
                        styleType={2}
                      />
                    </>
                  ) : (
                    <TextInput
                      title={t("daireTipi")}
                      placeholder={t("daireTipi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, circleType: text };
                        })
                      }
                    />
                  )
                ) : null}

                <TextInput
                  title={t("odaSayisi")}
                  placeholder={t("odaSayisi")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, numberRooms: text };
                    })
                  }
                />

                <NumberInputM2
                  title={t("balkoMetrekare")}
                  placeholder={t("balkoMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        balconyM2: text,
                      };
                    });
                  }}
                />

                {/* <DropInput2
                  data={[
                    { name: "Konut", value: "Konut" },
                    { name: "Ofis", value: "Ofis" },
                    { name: "Konut veya Ofis", value: "Konut veya Ofis" },
                  ]}
                  title="Kullanım Şekli"
                  placeholder="Kullanım Şekli"
                  setDropValue={(text) => {
                    setInventory((prev) => {
                      return { ...prev, inventoryUseShape: text };
                    });
                  }}
                /> */}

                {userType === "Kurumsal" ? (
                  <>
                    <TextInput
                      title={t("teslimSekli")}
                      placeholder={t("teslimSekli")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, deliveryShape: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("binaAdi")}
                      placeholder={t("binaAdi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, buildingName: text };
                        })
                      }
                    />
                    <DateInput
                      title={t("binaAcilisTarihi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, buildingOpeningDate: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("websitesi")}
                      placeholder={t("websitesi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, website: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("ulasim")}
                      placeholder={t("ulasim")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, transportation: text };
                        })
                      }
                    />

                    <TextInput
                      title={t("sosyalOlanaklar")}
                      placeholder={t("sosyalOlanaklar")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, socialOpportunities: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("gelistirici")}
                      placeholder={t("gelistirici")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, developer: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("insaatAlani")}
                      placeholder={t("insaatAlani")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, constructionZone: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("verimlilik")}
                      placeholder={t("verimlilik")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            productivity: text,
                          };
                        })
                      }
                    />
                    {selectedCorporateStatus === "ofis" ? (
                      <>
                        {/* <TextInput
                          title={t("iklimlendirme")}
                          placeholder={t("iklimlendirme")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return {
                                ...prev,
                                airConditioning: text,
                              };
                            })
                          }
                        /> */}
                        <DropInput
                          data={[
                            { name: t("var"), value: "Var" },
                            { name: t("yok"), value: "Yok" },
                          ]}
                          title={t("iklimlendirme")}
                          placeholder={t("iklimlendirme")}
                          setDropValue={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                airConditioning: text,
                              };
                            });
                          }}
                          styleType={2}
                        />

                        <TextInput
                          title={t("asansorSayisi")}
                          placeholder={t("asansorSayisi")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return {
                                ...prev,
                                numberOfElevators: text,
                              };
                            })
                          }
                        />
                        <TextInput
                          title={t("zeminUzeriToplamKatSayisi")}
                          placeholder={t("zeminUzeriToplamKatSayisi")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return {
                                ...prev,
                                floorAboveGroundTotalLength: text,
                              };
                            })
                          }
                        />
                        <NumberInputM2
                          title={t("toplamOfisAlani")}
                          placeholder={t("toplamOfisAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                totalOfficeSpace: text,
                              };
                            });
                          }}
                        />
                        <TextInput
                          title={t("katSecenekleri")}
                          placeholder={t("katSecenekleri")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return {
                                ...prev,
                                floorOptions: text,
                              };
                            })
                          }
                        />
                      </>
                    ) : selectedCorporateStatus === "dukkan" ? (
                      <>
                        <TextInput
                          title={t("toplamKatSayisi")}
                          placeholder={t("toplamKatSayisi")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return { ...prev, floorTotalLength: text };
                            })
                          }
                        />
                        <NumberInputM2
                          title={t("toplamDukkanAlani")}
                          placeholder={t("toplamDukkanAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                totalStoreSpace: text,
                              };
                            });
                          }}
                        />
                        <TextInput
                          title={t("dukkanSecenekleri")}
                          placeholder={t("dukkanSecenekleri")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return { ...prev, storeOptions: text };
                            })
                          }
                        />
                        <TextInput
                          title={t("mekanikAyrinti")}
                          placeholder={t("mekanikAyrinti")}
                          onChange={(text) =>
                            setInventory((prev) => {
                              return {
                                ...prev,
                                mechanicalDetail: text,
                              };
                            })
                          }
                        />
                        <NumberInputM2
                          title={t("dukkanBrutAlani")}
                          placeholder={t("dukkanBrutAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                shopGrossArea: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("dukkanAcikAlanBrutAlani")}
                          placeholder={t("dukkanAcikAlanBrutAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                shopOpenAreaGrossArea: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("dukkanHemzeminDepoAlani")}
                          placeholder={t("dukkanHemzeminDepoAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                shopLevelStorageArea: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("dukkanAltKatDepoAlani")}
                          placeholder={t("dukkanAltKatDepoAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                shopDownstairsStorageArea: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("dukkanSaftAlani")}
                          placeholder={t("dukkanSaftAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                shopShaftArea: text,
                              };
                            });
                          }}
                        />
                      </>
                    ) : selectedCorporateStatus === "atolye" ? (
                      <>
                        <NumberInputM2
                          title={t("atolyeBrutAlan123")}
                          placeholder={t("atolyeBrutAlan123")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                workshopGrossArea123: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("atolyeBrutAlan45")}
                          placeholder={t("atolyeBrutAlan45")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                workshopGrossArea45: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("atolyeBrutAlan67")}
                          placeholder={t("atolyeBrutAlan67")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                workshopGrossArea67: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("atolyeBrutAlan89")}
                          placeholder={t("atolyeBrutAlan89")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                workshopGrossArea89: text,
                              };
                            });
                          }}
                        />
                        <NumberInputM2
                          title={t("atolyeSaftAlani")}
                          placeholder={t("atolyeSaftAlani")}
                          onChange={(text) => {
                            setInventory((prev) => {
                              return {
                                ...prev,
                                workshopShaftArea: text,
                              };
                            });
                          }}
                        />
                      </>
                    ) : null}
                    <TextInput
                      title={t("manzara")}
                      placeholder={t("manzara")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            Landscape: text,
                          };
                        })
                      }
                    />
                    <TextInput
                      title={t("tavanYuksekligi")}
                      placeholder={t("tavanYuksekligi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            ceilingHeight: text,
                          };
                        })
                      }
                    />
                  </>
                ) : null}

                {!selectedCorporateStatus.length > 0 ? (
                  <>
                    <TextInput
                      title={t("ada")}
                      placeholder={t("ada")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, island: text };
                        })
                      }
                    />

                    <TextInput
                      title={t("parsel")}
                      placeholder={t("parsel")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, parsel: text };
                        })
                      }
                    />

                    <TextInput
                      title={t("pafta")}
                      placeholder={t("pafta")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, pafta: text };
                        })
                      }
                    />
                  </>
                ) : null}
                <TextInput
                  title={t("blokNo")}
                  placeholder={t("blokNo")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, blockNo: text };
                    })
                  }
                />
                <TextInput
                  title={t("disKapiNo")}
                  placeholder={t("disKapiNo")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, exteriorDoorNo: text };
                    })
                  }
                />
                <TextInput
                  title={t("kat")}
                  placeholder={t("kat")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, floor: text };
                    })
                  }
                />
                <TextInput
                  title={t("bagimsizBolumNo")}
                  placeholder={t("bagimsizBolumNo")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, independentNo: text };
                    })
                  }
                />
                <Textarea
                  value={inventory.address}
                  title={t("adres")}
                  placeholder={t("adres")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, address: text };
                    })
                  }
                />
                <TextInput
                  value={inventory.city}
                  title={t("il")}
                  placeholder={t("il")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, city: text };
                    })
                  }
                />
                <TextInput
                  value={inventory.country}
                  title={t("ulke")}
                  placeholder={t("ulke")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, country: text };
                    })
                  }
                />
              </div>

              <div className={style.rightBidForm}>
                {userType === "Kurumsal" &&
                selectedCorporateStatus === "atolye" ? (
                  <>
                    <NumberInputM2
                      title={t("cepheGenisligim")}
                      placeholder={t("cepheGenisligim")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            facadeWidth: text,
                          };
                        });
                      }}
                    />

                    <TextInput
                      title={t("brutTavanYuksekligi")}
                      placeholder={t("brutTavanYuksekligi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, grossCeilingHeight: text };
                        })
                      }
                    />

                    <NumberInputM2
                      title={t("hemzeminDepoAlani")}
                      placeholder={t("hemzeminDepoAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            atLevelWarehouse: text,
                          };
                        });
                      }}
                    />
                    <NumberInputM2
                      title={t("altKatDepoAlaniBrutAlani")}
                      placeholder={t("altKatDepoAlaniBrutAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            grossAreaBasementWarehouse: text,
                          };
                        });
                      }}
                    />

                    <NumberInputM2
                      title={t("depoSaftAlani")}
                      placeholder={t("depoSaftAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            warehouseShaftArea: text,
                          };
                        });
                      }}
                    />
                    <NumberInputM2
                      title={t("acikYuklemeAlani")}
                      placeholder={t("acikYuklemeAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            openLoadingArea: text,
                          };
                        });
                      }}
                    />
                    <NumberInputM2
                      title={t("tahsisliCalismaAlani")}
                      placeholder={t("tahsisliCalismaAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            dedicatedWorkspace: text,
                          };
                        });
                      }}
                    />
                    <NumberInputM2
                      title={t("idariOfisAlani")}
                      placeholder={t("idariOfisAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            administrativeOfficeArea: text,
                          };
                        });
                      }}
                    />

                    <NumberInputM2
                      title={t("sahanlikAlani")}
                      placeholder={t("sahanlikAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            shelfArea: text,
                          };
                        });
                      }}
                    />

                    <NumberInputM2
                      title={t("ofisBrutAlani")}
                      placeholder={t("ofisBrutAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            officeGrossArea: text,
                          };
                        });
                      }}
                    />

                    <DropInput
                      data={[
                        { name: t("var"), value: "var" },
                        { name: t("yok"), value: "yok" },
                      ]}
                      title={t("tirGirebilmeDurumu")}
                      placeholder={t("tirGirebilmeDurumu")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, truckEntryStatus: text };
                        });
                      }}
                      styleType={2}
                    />

                    <TextInput
                      title={t("kreynAltyapisi")}
                      placeholder={t("kreynAltyapisi")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, craneInfrastructure: text };
                        })
                      }
                    />

                    <NumberInputM2
                      title={t("tahsisliCatiMekanikAlani")}
                      placeholder={t("tahsisliCatiMekanikAlani")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            assignedRoofMechanicalSpace: text,
                          };
                        });
                      }}
                    />

                    <NumberInputM2
                      title={t("toplamSatilabilirAlan")}
                      placeholder={t("toplamSatilabilirAlan")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            totalSalableArea: text,
                          };
                        });
                      }}
                    />
                  </>
                ) : null}
                {userType === "Kurumsal" &&
                ["konut", "villa"].includes(selectedCorporateStatus) ? (
                  <>
                    <DropInput
                      data={[
                        { name: t("peysaj"), value: "Peyzaj" },
                        { name: t("blok"), value: "Blok" },
                        { name: t("deniz"), value: "Deniz" },
                        { name: t("gol"), value: "Göl" },
                        { name: t("nehir"), value: "Nehir" },
                        { name: t("siteDisi"), value: "Site Dışı" },
                        { name: t("sokakcadde"), value: "Sokak/Cadde" },
                        { name: t("orman"), value: "Orman" },
                      ]}
                      title={t("manzaraKirilmalari")}
                      placeholder={t("manzaraKirilmalari")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, LandscapeRefractions: text };
                        });
                      }}
                      styleType={2}
                    />
                    <DropInput
                      data={[
                        { name: t("peysaj"), value: "Peyzaj" },
                        { name: t("blok"), value: "Blok" },
                        { name: t("deniz"), value: "Deniz" },
                        { name: t("gol"), value: "Göl" },
                        { name: t("nehir"), value: "Nehir" },
                        { name: t("siteDisi"), value: "Site Dışı" },
                        { name: t("sokakcadde"), value: "Sokak/Cadde" },
                        { name: t("orman"), value: "Orman" },
                      ]}
                      title={t("manzara")}
                      placeholder={t("manzara")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, Landscape: text };
                        });
                      }}
                      styleType={2}
                    />
                    <DropInput
                      data={[
                        { name: t("kuzey"), value: "Kuzey" },
                        { name: t("guney"), value: "Güney" },
                        { name: t("dogu"), value: "Doğu" },
                        { name: t("bati"), value: "Batı" },
                        { name: t("kuzeyBati"), value: "Kuzey Batı" },
                        { name: t("kuzeyDogu"), value: "Kuzey Doğu" },
                        { name: t("guneyBati"), value: "Güney Batı" },
                        { name: t("guneyDogu"), value: "Güney Doğu" },
                      ]}
                      title={t("yon")}
                      placeholder={t("yon")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, Direction: text };
                        });
                      }}
                      styleType={2}
                    />
                  </>
                ) : null}

                <NumberInputM2
                  title={t("satisBrutMetrekare")}
                  placeholder={t("satisBrutMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        brutM2: text,
                      };
                    });
                  }}
                />
                <NumberInputM2
                  title={t("daireBrutMetrekare")}
                  placeholder={t("daireBrutMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        flatBrutM2: text,
                      };
                    });
                  }}
                />
                <NumberInputM2
                  title={t("katBrutMetrekare")}
                  placeholder={t("katBrutMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        floorBrutM2: text,
                      };
                    });
                  }}
                />
                <NumberInputM2
                  title={t("netMetrekare")}
                  placeholder={t("netMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        netM2: text,
                      };
                    });
                  }}
                />
                {!selectedCorporateStatus.length > 0 ? (
                  <>
                    <NumberInputM2
                      title={t("bahceMetrekare")}
                      placeholder={t("bahceMetrekare")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            gardenM2: text,
                          };
                        });
                      }}
                    />
                    <NumberInputM2
                      title={t("havuzMetrekare")}
                      placeholder={t("havuzMetrekare")}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            poolM2: text,
                          };
                        });
                      }}
                    />
                  </>
                ) : null}
                <NumberInputM2
                  title={t("terasMetrekare")}
                  placeholder={t("terasMetrekare")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        terraceM2: text,
                      };
                    });
                  }}
                />
                {userType === "Kurumsal" ? (
                  <>
                    <TextInput
                      title={t("otoparkHakki")}
                      placeholder={t("otoparkHakki")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, parkingRight: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("vergiDurumu")}
                      placeholder={t("vergiDurumu")}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, taxStatus: text };
                        })
                      }
                    />
                  </>
                ) : null}

                {(selectedMenu === "satılık" ||
                  selectedMenu === "kiralık" ||
                  selectedMenu === "satılıkveyakiralık") && (
                  <DropInput2
                    isDefault={
                      inventory.currency === "" ? "asda" : inventory.currency
                    }
                    data={[
                      { name: t("try"), value: "TRY" },
                      { name: t("usd"), value: "USD" },
                      { name: t("eur"), value: "EUR" },
                      { name: t("gbp"), value: "GBP" },
                      { name: t("rub"), value: "RUB" },
                    ]}
                    title={t("dovizBirimi")}
                    placeholder={t("dovizBirimi")}
                    setDropValue={(text) => {
                      setInventory((prev) => {
                        return { ...prev, currency: text };
                      });
                    }}
                  />
                )}
                {selectedMenu === "satılık" ? (
                  <>
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("metrekareBirimSatisFiyati")}
                      placeholder={t("metrekareBirimSatisFiyati")}
                      disabled={userType === "Bireysel" ? true : false}
                      value={Number(inventory.salesPriceM2).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            salesPriceM2: text,
                          };
                        });
                      }}
                    />
                    <NumberInput
                      prefix={"%"}
                      title={t("kdvOrani")}
                      placeholder={t("kdvOrani")}
                      value={Number(inventory.vatRate)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            vatRate: Number(text),
                          };
                        });
                      }}
                    />
                    {/*
                    <DropInput
                      click={() =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            excludingVatListPrice: 0,
                            withVatListPrice: 0,
                          };
                        })
                      }
                      data={[
                        { name: "%1", value: 1 },
                        { name: "%8", value: 8 },
                        { name: "%10", value: 10 },
                        { name: "%18", value: 18 },
                        { name: "%20", value: 20 },
                      ]}
                      title={t("kdvOrani")}
                      placeholder={t("kdvOrani")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, vatRate: Number(text) };
                        });
                      }}
                      styleType={2}
                    />
                    */}
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kdvHaricListeFiyati")}
                      placeholder={t("kdvHaricListeFiyati")}
                      value={inventory.excludingVatListPrice}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            excludingVatListPrice: text,
                          };
                        });
                      }}
                    />
                    <DisabledNumberInput
                      disabled
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kdvDahilSatisFiyati")}
                      value={inventory.withVatListPrice}
                      placeholder={t("kdvDahilSatisFiyati")}
                    />
                  </>
                ) : selectedMenu === "kiralık" ? (
                  <>
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("metrekareBirimKiraBedeli")}
                      placeholder={t("metrekareBirimKiraBedeli")}
                      value={Number(inventory.salesPriceM2).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            salesRentM2: text,
                          };
                        });
                      }}
                    />
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kiraBedeli")}
                      placeholder={t("kiraBedeli")}
                      value={Number(inventory.rentPrice).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            rentPrice: text,
                          };
                        });
                      }}
                    />
                  </>
                ) : selectedMenu === "satılıkveyakiralık" ? (
                  <>
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("metrekareBirimSatisBedeli")}
                      placeholder={t("metrekareBirimSatisBedeli")}
                      value={Number(inventory.salesPriceM2).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            salesPriceM2: text,
                          };
                        });
                      }}
                    />
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("metrekareBirimKiraBedeli")}
                      placeholder={t("metrekareBirimKiraBedeli")}
                      value={Number(inventory.salesRentM2).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            salesRentM2: text,
                          };
                        });
                      }}
                    />
                    <NumberInput
                      prefix={"%"}
                      title={t("kdvOrani")}
                      placeholder={t("kdvOrani")}
                      value={Number(inventory.vatRate)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            vatRate: Number(text),
                          };
                        });
                      }}
                    />
                    {/*
                    <DropInput
                      click={() =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            excludingVatListPrice: 0,
                            withVatListPrice: 0,
                          };
                        })
                      }
                      data={[
                        { name: "%1", value: 1 },
                        { name: "%8", value: 8 },
                        { name: "%10", value: 10 },
                        { name: "%18", value: 18 },
                        { name: "%20", value: 20 },
                      ]}
                      title={t("kdvOrani")}
                      placeholder={t("kdvOrani")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, vatRate: Number(text) };
                        });
                      }}
                      styleType={2}
                    />
                    */}
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kdvHaricListeFiyati")}
                      placeholder={t("kdvHaricListeFiyati")}
                      value={Number(inventory.excludingVatListPrice).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            excludingVatListPrice: text,
                          };
                        });
                      }}
                    />
                    <DisabledNumberInput
                      disabled
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kdvDahilListeFiyati")}
                      value={inventory.withVatListPrice}
                      placeholder={t("kdvDahilListeFiyati")}
                    />
                    <NumberInput
                      suffix={currencyConvert(inventory.currency)}
                      title={t("kiraBedeli")}
                      placeholder={t("kiraBedeli")}
                      value={Number(inventory.rentPrice).toFixed(2)}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            rentPrice: text,
                          };
                        });
                      }}
                    />
                  </>
                ) : null}
                {selectedMenu === "kiralık" ||
                selectedMenu === "satılıkveyakiralık" ? (
                  userType === "Kurumsal" ? (
                    <>
                      <NumberInput
                        suffix={currencyConvert(inventory.currency)}
                        title={t("aidat")}
                        placeholder={t("aidat")}
                        value={Number(inventory.dues).toFixed(2)}
                        onChange={(text) => {
                          setInventory((prev) => {
                            return {
                              ...prev,
                              dues: text,
                            };
                          });
                        }}
                      />
                      <NumberInputM2
                        title={t("toplamMetrekareAidat")}
                        placeholder={t("toplamMetrekareAidat")}
                        onChange={(text) => {
                          setInventory((prev) => {
                            return {
                              ...prev,
                              duesTotalM2: text,
                            };
                          });
                        }}
                      />
                      <TextInput
                        title={t("kirasizDonem")}
                        placeholder={t("kirasizDonem")}
                        onChange={(text) =>
                          setInventory((prev) => {
                            return { ...prev, rentFreePeriod: text };
                          })
                        }
                      />
                    </>
                  ) : null
                ) : null}
                {selectedMenu.length ? (
                  <DropInput2
                    // data={
                    //   selectedMenu === "satılık"
                    //     ? ownerContactNamesSalses
                    //       ? ownerContactNamesSalses.map((item) => {
                    //           return {
                    //             value: item._id,
                    //             name: item.name,
                    //             surname: item.surname,
                    //           };
                    //         })
                    //       : null
                    //     : selectedMenu === "kiralık"
                    //     ? ownerContactNamesRent
                    //       ? ownerContactNamesRent.map((item) => {
                    //           return {
                    //             value: item._id,
                    //             name: item.name,
                    //             surname: item.surname,
                    //           };
                    //         })
                    //       : null
                    //     : selectedMenu === "satılıkveyakiralık"
                    //     ? ownerContactNames
                    //       ? ownerContactNames.map((item) => {
                    //           return {
                    //             value: item._id,
                    //             name: item.name,
                    //             surname: item.surname,
                    //           };
                    //         })
                    //       : null
                    //     : null
                    // }
                    data={inventoryowners}
                    title={t("malSahibi")}
                    placeholder={t("malSahibi")}
                    setDropValue={(text) => {
                      setInventory((prev) => {
                        return { ...prev, inventoryOwnerId: text };
                      });
                    }}
                  />
                ) : null}
                <DocumentInput3
                  id={"independentSectionPlan"}
                  setDocumentFile={setIndependentSectionPlan}
                  documentFile={independentSectionPlan}
                  title={t("bagimsizBolumPlani")}
                  title2={t("bagimsizBolumPlaniYukleyiniz")}
                />
                <DocumentInput3
                  id={"situationPlan"}
                  setDocumentFile={setSituationPlan}
                  documentFile={situationPlan}
                  title={t("vaziyetPlani")}
                  title2={t("vaziyetPlaniYukleyiniz")}
                />
                <DocumentInput3
                  id={"floorPlan"}
                  setDocumentFile={setFloorPlan}
                  documentFile={floorPlan}
                  title={t("katPlani")}
                  title2={t("katPlaniYukleyiniz")}
                />
                <DocumentInput3
                  id={"locusPlan"}
                  setDocumentFile={setLocusPlan}
                  documentFile={locusPlan}
                  title={t("mahalListesi")}
                  title2={t("mahalListesiYukleyiniz")}
                />
                <DocumentInputIMG
                  title={t("projeGorseli")}
                  title2={t("projeGorseliYukleyiniz")}
                  documentFile={projectImage}
                  setDocumentFile={setProjectImage}
                  id="projectImage"
                />
                <Textarea
                  title={t("not")}
                  placeholder={t("not")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, note: text };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup ? (
        <Popup
          errorText={t("unitePopupKayitMalSahibi")}
          title={t("unitePopupKayitTitle")}
          closeClick={() => {
            setPopup(false);
          }}
        />
      ) : null}
      {projectPopup ? (
        <Popup
          errorText={t("unitePopupKayitProje")}
          title={t("unitePopupKayitTitle")}
          closeClick={() => {
            setProjectPopup(false);
          }}
        />
      ) : null}
      {selectedCorporateStatusPopup ? (
        <Popup
          errorText={t("unitePopupKayitUrunTipi")}
          title={t("unitePopupKayitTitle")}
          closeClick={() => {
            setSelectedCorporateStatusPopup(false);
          }}
        />
      ) : null}
      {inventoryCategoryPopup ? (
        <Popup
          errorText={t("unitePopupKayiturunStatusu")}
          title={t("unitePopupKayitTitle")}
          closeClick={() => {
            setInventoryCategoryPopup(false);
          }}
        />
      ) : null}

      {uniteSunumSekliPopup && (
        <Popup
          errorText={t("unitePopupKayituniteSunumSekli")}
          title={t("unitePopupKayitTitle")}
          closeClick={() => {
            setUniteSunumSekliPopup(false);
          }}
        />
      )}
    </>
  );
}

export default CreateInventory;
