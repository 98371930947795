import React , {useRef} from "react";
import { UserIcon } from "../../assets/icons";
import style from "./header.module.scss";
import { useState } from "react";
import ProfilePopup from "./ProfilePopup";
function Profile() {
  const [popup, setPopup] = useState(false);
  const _onClick = () => {
    setPopup(true);
  };

  const profileMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (profileMenu.current && popup && !profileMenu.current.contains(e.target)) {
      setPopup(false);
    }
  };
  document.addEventListener('mousedown',closeOpenMenus)

  return (
    <>
      {popup ? <ProfilePopup setPopup={setPopup}  menuRef={profileMenu} /> : null}
      {popup && <div className={style.shadowBox}></div>}
      <button onClick={_onClick} className={style.cursor}>
        <UserIcon />
      </button>
    </>
  );
}

export default Profile;
