import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const checkResetEmail = async (body) => {
  try {
    const request = await axios.post(`${BaseUrl}/api/user/check-reset-email`, body);
    return request.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return { error: 'Network error. Please try again later.' };
    } else {
      return { error: 'An unexpected error occurred. Please try again later.' };
    }
  }
};

export const checkActivationCode = async (body) => {
  try {
    const request = await axios.post(`${BaseUrl}/api/user/check-activation-code`, body);
    return request.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return { error: 'Network error. Please try again later.' };
    } else {
      return { error: 'An unexpected error occurred. Please try again later.' };
    }
  }
};

export const setPassword = async (body) => {
  try {
    const request = await axios.post(`${BaseUrl}/api/user/set-password`, body);
    return request.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return { error: 'Network error. Please try again later.' };
    } else {
      return { error: 'An unexpected error occurred. Please try again later.' };
    }
  }
};

export const loginUser = async (body) => {
  const request = await axios.post(`${BaseUrl}/api/user/login`, body);
  return request;
};

export const userCheck = async (jwt, id) => {
  const request = await axios.post(`${BaseUrl}/api/user/check-user`, {
    data: {
      id: id,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};

export const getAllUsers = async (jwt) => {
  const request = await axios.get(`${BaseUrl}/api/user/get-users`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};

export const getAllUsersForLog = async (jwt) => {
  const request = await axios.get(`${BaseUrl}/api/user/get-users-for-log`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};

export const createNewUser = async (jwt, data) => {
  const request = await axios.post(
    `${BaseUrl}/api/user/register`,
    {
      data: data,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const deleteAUser = async (jwt, id) => {
  const request = await axios.delete(`${BaseUrl}/api/user/delete-user`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    data: {
      id: id,
    },
  });
  return request;
};

export const changeuserActiveStatus = async (jwt, id) => {
  const request = await axios.post(
    `${BaseUrl}/api/user/user-active`,
    {
      data: id,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const getAUser = async (jwt, id) => {
  const request = await axios.post(
    `${BaseUrl}/api/user/get-user`,
    {
      data: id,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const editAUser = async (jwt, id, data) => {
  const request = await axios.put(
    `${BaseUrl}/api/user/edit-user`,
    {
      data: {
        id: id,
        data: data,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const getUsersProjectPersonels = async (jwt, id) => {
  const request = await axios.post(
    `${BaseUrl}/api/user/get-project-personels`,
    {
      data: id,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const getUserInfoForProfile = async (jwt, id) => {
  const request = await axios.post(
    `${BaseUrl}/api/user/get-my-profile`,
    {
      data: id,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};
