import axios from "axios";
import { BaseUrl } from "../ApiConfig";


export const getLog = async (jwt, userType, params) => {

  const request = await axios.post(
    `${BaseUrl}/api/log/getLog`,
    params,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
  return request;
}