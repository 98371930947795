import React, { useState, useEffect, useMemo } from "react";
import { SubHeader, SideFilter } from "../../components";
import style from "./bidlist.module.scss";
import { useSelector } from "react-redux";
import { getAllBids, deleteBid } from "../../lib/bidApi";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import Moment from "moment";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { ViewIcon, EditIcon /*, DeleteIcon */ } from "../../assets/icons/index";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";
import BidListPopup from "./BidListPopup";

import { useTranslation } from "react-i18next";

const BidList = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const user = useSelector((state) => state.user.userData);
  const { meetingShapeTR, meetingShapeEN } = useSelector(
    (state) => state.system.meetingShape
  );
  const { bidStageTR, bidStageEN } = useSelector(
    (state) => state.system.bidStage
  );
  const { failedStageNoteTR, failedStageNoteEN } = useSelector(
    (state) => state.system.failedStageNote
  );

  const [targetActivity, setTargetActivity] = useState({
    projeName: "",
    ownerInventory: "",
    inventoryName: "",
    id: "",
    contactId: "",
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllBids(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data);
        setIsLoading(false);
        //console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const paymentTypeConverter = (paymentType) => {
    if (paymentType === "Tamamı Peşin") {
      return t("tamamiPesin");
    } else if (paymentType === "Kredili") {
      return t("kredili");
    } else if (paymentType === "Kredili + Vadeli") {
      return t("krediliVadeli");
    } else if (paymentType === "Vadeli") {
      return t("vadeli");
    }
  };

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return "₺";
    } else if (value === "USD") {
      return "$";
    } else if (value === "EUR") {
      return "€";
    } else if (value === "GBP") {
      return "£";
    } else if (value === "RUB") {
      return "₽";
    } else {
      return "₺";
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("olusturanPersonel"),
        accessor: "ownerBid",
      },
      {
        Header: t("musteriAdi"),
        accessor: "queryContactName",
      },
      {
        Header: t("projeAdi"),
        accessor: "projeName",
      },
      {
        Header: t("urunTipi"),
        accessor: "inventoryName",
      },
      {
        Header: t("malSahibi"),
        accessor: "ownerInventory",
      },
      {
        Header: t("gorusmeKanali"),
        id: "meetingShape",
        accessor: (row) =>
          i18n.language === "tr"
            ? meetingShapeTR.find((item) => item.value === row.meetingShape)
                ?.name
            : i18n.language === "en"
            ? meetingShapeEN.find((item) => item.value === row.meetingShape)
                ?.name
            : null,
      },
      {
        Header: t("aktiviteIsmi"),
        accessor: "bidName",
      },
      {
        Header: t("aktiviteTipi"),
        accessor: "bidShape",
      },
      {
        Header: t("aktiviteAsamasi"),
        id: "bidStage",
        accessor: (d) =>
          d.bidStage === "Sunum"
            ? t("sunum")
            : i18n.language === "tr"
            ? bidStageTR.find((item) => item.value === d.bidStage)?.name
            : i18n.language === "en"
            ? bidStageEN.find((item) => item.value === d.bidStage)?.name
            : null,
      },
      {
        Header: t("kdvHaric"),
        accessor: (d) => {
          return d.bidAmount === null
            ? 0 + currencyConvert(d?.currency)
            : d?.bidAmount?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      {
        Header: t("kiralamaYil"),
        accessor: "rentalPeriod",
      },
      {
        Header: t("basarisizKapanisNedeni"),
        id: "failedStageNote",
        accessor: (d) => {
          return d.failedStageNote
            ? i18n.language === "tr"
              ? failedStageNoteTR.find(
                  (item) => item.value === d.failedStageNote
                )?.name
              : i18n.language === "en"
              ? failedStageNoteEN.find(
                  (item) => item.value === d.failedStageNote
                )?.name
              : "-"
            : "-";
        },
      },
      {
        Header: t("kdvVadelendirilicakTutar"),
        id: "bidAmountWithVat",
        accessor: (d) => {
          return d.bidAmountWithVat === null
            ? 0 + currencyConvert(d?.currency)
            : d?.bidAmountWithVat?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      {
        Header: t("indirimOrani"),
        accessor: "discountRate",
      },
      // {
      //   Header: t("altKategorisi"),
      //   accessor: "estimateDate",
      // },
      {
        Header: t("birimKiraBedeli"),
        id: "unitRentalPrice",
        accessor: (d) => {
          return d.unitRentalPrice === null
            ? 0 + currencyConvert(d?.currency)
            : d?.unitRentalPrice?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      {
        Header: t("kiraArtisi"),
        accessor: "rentIncrease",
      },
      {
        Header: t("guvenceBedeliAy"),
        accessor: "securityDeposit",
      },
      {
        Header: t("otopark"),
        accessor: "carPark",
      },
      {
        Header: t("isletmeGideri"),
        accessor: "operatingExpenses",
      },
      {
        Header: t("kapanisTarihi"),
        id: "closingDate",
        accessor: (d) => {
          return Moment(d.closingDate, "YYYY-MM-DD")
            .local()
            .format("DD-MM-YYYY");
        },
      },
      {
        Header: t("odemeTipi"),
        id: "paymentType",
        accessor: (d) =>
          d.paymentType ? paymentTypeConverter(d.paymentType) : "-",
      },
      {
        Header: t("kapora"),
        id: "advancePayment",
        accessor: (d) => {
          return d.advancePayment === null
            ? 0 + currencyConvert(d?.currency)
            : d?.advancePayment?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      {
        Header: t("olusturulmaTarihi"),
        id: "createdAt",
        accessor: (d) => {
          return Moment(d.createdAt).local().format("DD.MM.YYYY");
        },
        show: true,
      },
      {
        Header: t("bankaKredisi"),
        id: "bankCredit",
        accessor: (d) => {
          return d.bankCredit === null
            ? 0 + currencyConvert(d?.currency)
            : d?.bankCredit?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      {
        Header: t("senetTutari"),
        id: "billAmount",
        accessor: (d) => {
          return d.billAmount === null
            ? 0 + currencyConvert(d?.currency)
            : d?.billAmount?.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + currencyConvert(d?.currency);
        },
      },
      // {
      //   Header: t("kdvHaric")"Faiz Miktarı",
      //   accessor: "interestAmount",
      // },
      {
        Header: t("kullanciTipi"),
        accessor: "userType",
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <ReactTooltip
              id="view"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("aktiviteyiDetayliGor")}
            </ReactTooltip>
            <ReactTooltip
              id="edit"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("aktiviteyiDuzenle")}
            </ReactTooltip>
            <ReactTooltip
              id="delete"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("aktiviteyiSil")}
            </ReactTooltip>
            {(user.department !== "hr" ||
              user.department !== "fd" ||
              user.department !== "pd") && (
              <Link
                target="_blank"
                data-tip
                data-for="view"
                className={style.icon}
                to={`/bid-detail/${row.original._id}/${
                  row.original.contactId ? row.original.contactId._id : ""
                }`}
              >
                <ViewIcon />
              </Link>
            )}
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "sd" ||
              user.department === "rd" ||
              user.department === "sm") && (
              <Link
                target="_blank"
                data-tip
                data-for="edit"
                className={style.icon}
                to={`/bid-list-edit/${row.original._id}/${
                  row.original.contactId ? row.original.contactId._id : ""
                }`}
              >
                <EditIcon />
              </Link>
            )}
            {/*
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "sd" ||
              user.department === "rd" ||
              user.department === "sm") && (
              <button
                data-tip
                data-for="delete"
                className={style.icon}
                onClick={() => {
                  setTargetActivity({
                    id: row.original._id,
                    contactId: row.original.contactId._id,
                    inventoryName: row.original.inventoryName,
                    projeName: row.original.projeName,
                    ownerInventory: row.original.ownerInventory,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <DeleteIcon />
              </button>              
            )}
            */}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      i18n.language,
      meetingShapeTR,
      meetingShapeEN,
      bidStageTR,
      bidStageEN,
      failedStageNoteTR,
      failedStageNoteEN,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: [
          "bidName",
          "billLength",
          "rentalPeriod",
          "failedStageNote",
          "bidAmountWithVat",
          "discountRate",
          "inventoryId",
          "bidNote",
          "estimateDate",
          "contractStartDate",
          "unitRentalPrice",
          "rentIncrease",
          "securityDeposit",
          "carPark",
          "operatingExpenses",
          "paymentPlan",
          "closingDate",
          "paymentType",
          "decoration",
          "advancePayment",
          "advancePaymentDate",
          "downPaymnet",
          "downPaymnetDate",
          "termDownPaymnet",
          "termDownPaymnetDate",
          "bankCredit",
          "bankCreditDate",
          "bankToBeUsed",
          "billAmount",
          "otherExpenses",
          "interestAmount",
          "deliveryTime",
          "userType",
          "contactId",
          "createdAt",
          "createAtReal",
          "bidShape",
          "inventoryName",
          "ownerInventory",
        ],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageSize: pageSize,
    }));
  }, [pageSize]);

  const successClick = () => {
    deleteBid(user.token, targetActivity.id, targetActivity.contactId)
      .then((res) => {
        //console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    window.location.reload();
    //TODO reload atmasi lazim dataya
    setTargetActivity({
      id: "",
      contactId: "",
      inventoryName: "",
      projeName: "",
      ownerInventory: "",
    });
    setIsPopupOpen(false);
  };

  const popUpClose = () => {
    setTargetActivity({
      id: "",
      contactId: "",
      inventoryName: "",
      projeName: "",
      ownerInventory: "",
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && (
        <BidListPopup
          closeClick={popUpClose}
          successClick={successClick}
          targetActivity={targetActivity}
        />
      )}
      <div className={style.bidList}>
        <SubHeader
          getToggleHideAllColumnsPropsForBids={getToggleHideAllColumnsProps}
          bidMenu
          allBidsColumns={allColumns}
          bidData={data}
        />

        <div className={style.content}>
          <SideFilter
            setBidData={setData}
            setHasFilterData={setHasFilterData}
            getPagination={pagination}
            setPagination={setPagination}
            setNewMethod={setNewMethod}
            setNewBody={setNewBody}
          />
          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default BidList;
