import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export const TotalUser = ({ userInfo }) => {
  const { t } = useTranslation();

  const sortedPersonnelDistribution = [...userInfo.personnelDistribution].sort(
    (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  );
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {t("userCount")}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="textPrimary" variant="h4">
                {userInfo.userCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: "#daa335",
                height: 56,
                width: 56,
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            pt: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mr: 1,
            }}
            fontSize={14}
          >
            {sortedPersonnelDistribution.map((item, index) => (
              <Tooltip key={index} title={t(item.name)}>
                <span>
                  <b>{t(item.name)}</b>:{item.count} {}
                </span>
              </Tooltip>
            ))}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
