import { TextInput, DropInput, DisabledInput } from './Inputs';
import style from './forms.module.scss';
const CreditForm = () => {
  return (
    <form className={style.creditForm}>
      <div>
        <h1>Kredi Hesaplama</h1>
        <div>
          <TextInput title={'Kredi Tutarı'} />
          <DropInput title={'Ay'} />
          <TextInput title={'Aylık Faiz'} />
          <TextInput title={'Vergi %BSMV'} />
          <TextInput title={'Fon %KKDF	'} />
          <TextInput title={'Yıllık Faiz %	'} />
          <TextInput title={'Vergili Faiz %	'} />
          <DropInput title={'Kredi Kullandıran Bank'} />
        </div>
      </div>
      <div>
        <div>
          <DisabledInput title={'Toplam Geri Ödeme'} />
          <DisabledInput title={'Toplam Faiz %'} />
        </div>
      </div>
    </form>
  );
};

export default CreditForm;
