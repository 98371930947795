import style from "./sidefilter.module.scss";
import Input from "./Input";
import InputBetween from "./InputBetween";
import DropDown from "./DropDown";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DateInput from "./DateInput";
import DateBetweenInput from "./DateBetweenInput";
import { BaseUrl } from "../../ApiConfig";
import { useTranslation } from "react-i18next";
import { toCamelCaseKey, replaceTurkishChars } from "../../utils/general";

const SideFilter = ({
  setLeadData,
  setContactData,
  setKvkkData,
  setInventoryData,
  setBidData,
  setApprovalData,
  setNoteData,
  setProjectData,
  setUserData,
  setHasFilterData,
  getPagination,
  setPagination,
  setNewMethod,
  setNewBody,
}) => {
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user.userData);
  const { contactSourceTR, contactSourceEN } = useSelector(
    (state) => state.system.contactSource
  );
  const { meetingShapeTR, meetingShapeEN } = useSelector(
    (state) => state.system.meetingShape
  );
  const { bankCreditTR, bankCreditEN } = useSelector(
    (state) => state.system.bankCredit
  );
  const { bidStageTR, bidStageEN } = useSelector(
    (state) => state.system.bidStage
  );
  const { failedStageNoteTR, failedStageNoteEN } = useSelector(
    (state) => state.system.failedStageNote
  );
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [queryProjectName, setQueryProjectName] = useState("");
  const [owner, setOwner] = useState("");
  const [createAtReal, setCreateAtReal] = useState("");
  const [createAtRealStart, setCreateAtRealStart] = useState("");
  const [createAtRealEnd, setCreateAtRealEnd] = useState("");
  const [phone, setPhone] = useState("");
  const [gsmPhone, setgsmPhone] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [channel, setChannel] = useState("");
  const [city, setCity] = useState("");
  // const [budget, setBudget] = useState("");
  const [firstBudget, setFirstBudget] = useState("");
  const [secondBudget, setSecondBudget] = useState("");
  const [currency, setCurrency] = useState("");
  const [category, setCategory] = useState("");
  const [contactSource, setContactSource] = useState("");
  const [gender, setGender] = useState("");
  const [shape, setShape] = useState("");
  const [address, setAddress] = useState("");
  // const [date, setDate] = useState("");
  ///kvkk
  // const [createdAt, setCreatedAt] = useState("");
  ///

  const [type, setType] = useState("");
  const [age, setAge] = useState("");
  const [gsm] = useState("");
  const [country, setCountry] = useState("");
  const [tckn, setTckn] = useState("");
  const [taxNo, setTaxNo] = useState("");
  const [corporateTitle, setCorporateTitle] = useState("");

  const [statusForm, setStatusForm] = useState("");
  const [dataProcessing, setDataProcessing] = useState("");
  const [url, setUrl] = useState();
  const [body, setBody] = useState();
  const [bidsFilterBody, setBidsFilterBody] = useState({
    queryContactName: "",
    ownerBid: "",
    bidName: "",
    bidShape: "",
    queryProjeName: "",
    queryInventoryName: "",
    queryOwnerName: "",
    // bidAmount: null,
    discountRate: "",
    bankToBeUsed: "",
    meetingShape: "",
    // deliveryTime: "",
    bidStage: "",
    failedStageNote: "",
    // createdAt: "",
    estimateDate: "",
    closingDate: "",
    createAtRealStart: "",
    createAtRealEnd: "",
    deliveryTime: "",
  });
  const [approvalFilterBody, setApprovalFilterBody] = useState({
    queryContactName: "",
    ownerBid: "",
    queryProjeName: "",
    queryInventoryName: "",
    queryOwnerName: "",
    createAtReal: "",
  });
  const [inventoryFilter, setInventoryFilter] = useState({
    queryProjectName: "",
    inventoryName: "",
    inventoryCategory: "",
    status: "",
    circleType: "",
    corporateStatus: "",
    floor: "",
    city: "",
    firstBrutM2: "",
    secondBrutM2: "",
    firstNetM2: "",
    secondNetM2: "",
    netM2: "",
    gardenM2: "",
    poolM2: "",
    terraceM2: "",
    balconyM2: "",
    queryOwnerInventoryName: "",
    firstWithVatListPrice: "",
    secondWithVatListPrice: "",
    currency: "",
    createAtRealStart: "",
    createAtRealEnd: "",
    Landscape: "",
    Direction: "",
  });
  const [noteFilter, setNoteFilter] = useState({
    title: "",
    queryBidName: "",
    queryProjectName: "",
    queryInventoryName: "",
    queryPhone: "",
    queryGsm: "",
    queryEmail: "",
    queryTckn: "",
    queryContactName: "",
    noteOwner: "",
    // createAtReal: '',
    meetingShape: "",
    meetingDate: "",
  });

  const [userFilter, setUserFilter] = useState({
    _id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    lastOnline: "",
    isActive: "",
    department: "",
    createdAt: "",
    assignedProject: null,
  });

  const [projectFilter, setProjectFilter] = useState({
    projectName: "",
    city: "",
    country: "",
    createdAt: "",
    address: "",
    projectOwner: "",
    createAtRealStart: "",
    createAtRealEnd: "",
  });

  const [inventoryDirectionDatas, setInventoryDirectionDatas] = useState([]);
  const [language, setLanguage] = useState(i18n.language);

  const statusSelDatas = [
    { name: t("satisaAcikStok"), value: "Satışa Açık (Stok)" },
    /*
    {
      name: t("satisaAcikOpsiyonlu"),
      value: "Satışa Açık (Opsiyonlu)",
    },
    {
      name: t("satisaAcikKaporali"),
      value: "Satışa Açık (Kaparolu)",
    },
    */
    { name: t("satisaKapaliKaparoli"), value: "Satış (Kaparo)" },
    { name: t("satisaKapaliSozlesmeli"), value: "Satış (Sözleşme)" },
    {
      name: t("satisaKapaliGorusmeAsamasi"),
      value: "Satış (Görüşme Aşamasında)",
    },
    { name: t("satisaKapaliOpsiyon"), value: "Satış (Opsiyon)" },
    { name: t("satisaKapali"), value: "Satışa Kapalı" },
    { name: t("barter"), value: "Barter" },
    { name: t("kirayaUygun"), value: "Kiraya uygun" },
    {
      name: t("kaporali"),
      value: "Kaporalı",
    },
    /*
    {
      name: t("opsiyonlu"),
      value: "Opsiyonlu",
    },
    */
    { name: t("kirayaUygunDegil"), value: "Kiraya uygun değil" },
  ];

  useEffect(() => {
    if (setLeadData) {
      setUrl(
        `${BaseUrl}/api/lead/queryLead?pageNumber=${
          getPagination.queryPageIndex + 1
        }&pageSize=${getPagination.queryPageSize}`
      );
      setBody({
        queryProjectName,
        name,
        surname,
        channel,
        phone,
        email,
        owner,
        company,
        createAtReal,
        userType: user.userType,
      });
    }
    if (setContactData) {
      setUrl(
        `${BaseUrl}/api/contact/queryContact?pageNumber=${
          getPagination.queryPageIndex + 1
        }&pageSize=${getPagination.queryPageSize}`
      );
      setBody({
        name,
        surname,
        queryProjectName,
        company,
        channel,
        phone: gsmPhone,
        email,
        owner,
        city,
        firstBudget,
        secondBudget,
        currency,
        category,
        contactSource,
        gender,
        shape,
        address,
        age,
        gsm: gsmPhone,
        type,
        country,
        tckn,
        taxNo,
        corporateTitle,
        createAtRealStart,
        createAtRealEnd,
        userType: user.userType,
      });
    }
    if (setKvkkData) {
      setUrl(`${BaseUrl}/api/kvkk/queryKvkk`);
      setBody({
        name,
        surname,
        channel,
        dataProcessing,
        statusForm,
        createAtRealStart,
        createAtRealEnd,
        userType: user.userType,
      });
    }
    if (setInventoryData) {
      if (inventoryDirectionDatas.length === 0 || language !== i18n.language) {
        setInventoryDirectionDatas(["true"]);
        setLanguage(i18n.language);
        const url = `${BaseUrl}/api/project/getInventoryDirection`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((res) => {
            const itemList = [];
            for (const item of res.data.direction) {
              itemList.push({
                name: t(toCamelCaseKey(replaceTurkishChars(item))),
                value: item,
              });
            }
            setInventoryDirectionDatas(itemList);
          })
          .catch((err) => {
            console.log(err.response);
            window.location.reload();
          });
      }
    }
    // eslint-disable-next-line
  }, [
    name,
    surname,
    queryProjectName,
    channel,
    dataProcessing,
    statusForm,
    address,
    age,
    firstBudget,
    secondBudget,
    currency,
    category,
    contactSource,
    city,
    company,
    country,
    email,
    gender,
    gsm,
    owner,
    phone,
    shape,
    taxNo,
    tckn,
    type,
    inventoryDirectionDatas,
    setInventoryData,
    setKvkkData,
    setContactData,
    setLeadData,
    corporateTitle,
    createAtReal,
    createAtRealStart,
    createAtRealEnd,
    // createdAt,
    gsmPhone,
    user.userType,
    getPagination,
    setPagination,
    setNewMethod,
    setNewBody,
    i18n.language,
  ]);

  const filterData = () => {
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (setLeadData) {
          // setLeadData(res.data.lead);
          setNewMethod("post");
          setNewBody(body);
          const lead = res.data.lead;
          setLeadData(
            lead.map((d) => {
              return {
                select: false,
                _id: d._id,
                name: d.name,
                phone: d.phone,
                email: d.email,
                surname: d.surname,
                company: d.company,
                channel: d.channel,
                owner: d.owner,
                ownerNameSurname: d.ownerNameSurname,
                associatedProject: d.associatedProject,
                consentType: d.consentType,
                note: d.note,
                createAtReal: d.creationDate,
              };
            })
          );
          setPagination((prev) => ({
            ...prev,
            totalCount: res.data.pageCount,
          }));
        } else if (setContactData) {
          setNewMethod("post");
          setNewBody(body);
          setContactData(res.data.contact);
          setPagination((prev) => ({
            ...prev,
            totalCount: res.data.pageCount,
          }));
        } else if (setKvkkData) {
          setKvkkData(res.data.kvkk);
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.reload();
      });
  };
  const filterDataBids = () => {
    const userType = user.userType;
    let url = `${BaseUrl}/api/bid/queryBid/${userType}?pageNumber=${
      getPagination.queryPageIndex + 1
    }&pageSize=${getPagination.queryPageSize}`;
    axios
      .post(url, bidsFilterBody, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNewMethod("post");
        setNewBody(bidsFilterBody);
        setBidData(res.data.bid);
        setPagination((prev) => ({
          ...prev,
          totalCount: res.data.pageCount,
        }));
      })
      .catch((err) => {
        console.log(err.response);
        window.location.reload();
      });
  };

  const filterDataApprovals = () => {
    const userType = user.userType;
    let url = `${BaseUrl}/api/approval/queryApproval/${userType}?pageNumber=${
      getPagination.queryPageIndex + 1
    }&pageSize=${getPagination.queryPageSize}`;
    axios
      .post(url, approvalFilterBody, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNewMethod("post");
        setNewBody(approvalFilterBody);
        setApprovalData(res.data.bid);
        setPagination((prev) => ({
          ...prev,
          totalCount: res.data.pageCount,
        }));
      })
      .catch((err) => {
        console.log(err.response);
        window.location.reload();
      });
  };

  const filterUserHandler = () => {
    axios
      .post(
        `${BaseUrl}/api/user/query-user`,
        {
          data: userFilter,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterProjectHandler = () => {
    axios
      .post(
        `${BaseUrl}/api/project/query-project`,
        {
          data: projectFilter,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setProjectData(res.data.project);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterDataInventory = () => {
    const url = `${BaseUrl}/api/project/queryInventory?pageNumber=${
      getPagination.queryPageIndex + 1
    }&pageSize=${getPagination.queryPageSize}`;
    axios
      .post(url, inventoryFilter, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNewMethod("post");
        setNewBody(inventoryFilter);
        setInventoryData(res.data.inventory);
        setPagination((prev) => ({
          ...prev,
          totalCount: res.data.pageCount,
        }));
      })
      .catch((err) => {
        console.log(err.response);
        window.location.reload();
      });
  };
  const filterDataNote = () => {
    const userType = user.userType;
    let url = `${BaseUrl}/api/note/queryNote/${userType}?pageNumber=${
      getPagination.queryPageIndex + 1
    }&pageSize=${getPagination.queryPageSize}`;
    axios
      .post(url, noteFilter, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNewMethod("post");
        setNewBody(noteFilter);
        setNoteData(res.data.note);
        setPagination((prev) => ({
          ...prev,
          totalCount: res.data.pageCount,
        }));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const clearData = () => {
    setHasFilterData(false);
    window.location.reload();
  };
  const filterDateValueConvert = (date) => {
    const newDate = new Date(date);
    return (
      newDate.getDate() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getFullYear()
    );
  };
  return (
    <>
      <div className={style.sideFilter}>
        <h2>{t("filtrele")}</h2>
        <div className={style.inputWrapper}>
          {setContactData ? (
            <>
              <Input title={t("isim")} value={name} setChange={setName} />
              <Input
                title={t("soyisim")}
                value={surname}
                setChange={setSurname}
              />
              <Input
                title={t("projeAdi")}
                value={queryProjectName}
                setChange={setQueryProjectName}
              />
              <Input title={t("yas")} value={age} setChange={setAge} />
              {/* <Input title="Cinsiyet" value={gender} setChange={setGender} /> */}
              <DropDown
                data={[
                  {
                    value: "Erkek",
                    name: t("erkek"),
                  },
                  {
                    value: "Kadın",
                    name: t("kadin"),
                  },
                ]}
                title={t("cinsiyet")}
                setChange={setGender}
              />
              {/* <Input title="İş Telefonu" value={gsm} setChange={setGsm} />
              <Input title="Cep Telefonu" value={phone} setChange={setPhone} /> */}
              <Input
                title={t("gsm")}
                value={gsmPhone}
                setChange={setgsmPhone}
              />
              <Input
                title={t("email")}
                value={email}
                setChange={setEmail}
                maxLength={"50"}
              />
              <Input title={t("tcno")} value={tckn} setChange={setTckn} />
              <Input
                title={t("sirket")}
                value={company}
                setChange={setCompany}
              />
              <Input
                title={t("kurumsalUnvan")}
                value={corporateTitle}
                setChange={setCorporateTitle}
              />

              <Input
                title={t("adres")}
                value={address}
                setChange={setAddress}
              />
              <Input title={t("ilce")} value={city} setChange={setCity} />
              <Input title={t("il")} value={country} setChange={setCountry} />
              <Input title={t("vergiNo")} value={taxNo} setChange={setTaxNo} />
              {/* Kontak Tipi */}
              <DropDown
                data={[
                  {
                    value: "Bireysel",
                    name: t("bireysel"),
                  },
                  {
                    value: "Kurumsal",
                    name: t("kurumsal"),
                  },
                ]}
                title={t("kontakTipi")}
                setChange={setType}
              />
              <DropDown
                data={[
                  { name: t("musteri"), value: "Müşteri" },
                  { name: t("isOrtagi"), value: "İş Ortağı" },
                  { name: t("tedarikci"), value: "Tedarikçi" },
                  { name: t("resmiGorevli"), value: "Resmi Görevli" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                title={t("kategorisi")}
                setChange={setCategory}
              />
              <DropDown
                data={
                  i18n.language === "tr"
                    ? contactSourceTR
                    : i18n.language === "en"
                    ? contactSourceEN
                    : null
                }
                title={t("kontakKaynagi")}
                setChange={setContactSource}
              />
              {contactSource && (
                <Input
                  title={
                    i18n.language === "tr"
                      ? contactSourceTR.find(
                          (item) => item.value === contactSource
                        )?.name
                      : i18n.language === "en"
                      ? contactSourceEN.find(
                          (item) => item.value === contactSource
                        )?.name
                      : null
                  }
                  value={shape}
                  setChange={setShape}
                />
              )}
              <InputBetween
                title={t("butce")}
                firstValue={firstBudget}
                secondValue={secondBudget}
                currencyValue={currency}
                setFirstChange={(e) => {
                  setFirstBudget(e.target.value);
                }}
                setSecondChange={(e) => {
                  setSecondBudget(e.target.value);
                }}
                setCurrencyChange={(e) => {
                  setCurrency(e.target.value);
                }}
                currencyShow={true}
              />
              <DropDown
                data={[
                  {
                    value: "Manüel",
                    name: t("manuel"),
                  },
                  {
                    value: "Digital",
                    name: t("dijital"),
                  },
                  {
                    value: "Marketing Automation",
                    name: t("marketingAutomation"),
                  },
                  {
                    value: "Web Automation",
                    name: t("webAutomation"),
                  },
                ]}
                title={t("kanal")}
                setChange={setChannel}
              />
              <Input
                title={t("olusturanPersonel")}
                value={owner}
                setChange={setOwner}
              />

              <DateBetweenInput
                title={t("guncellemeTarihi")}
                setChangeStart={(e) => {
                  setCreateAtRealStart(new Date(e.target.value).toISOString());
                }}
                setChangeEnd={(e) => {
                  setCreateAtRealEnd(new Date(e.target.value).toISOString());
                }}
                setData={null}
                data={null}
                setManuelStartDate={setCreateAtRealStart}
                setManuelEndDate={setCreateAtRealEnd}
              />
              {/* <DateInput title="Kayıt Tarihi" /> */}

              {/* <Input title="Kayıt Tarihi" value={date} setChange={setDate} /> */}
            </>
          ) : setKvkkData ? (
            <>
              <Input title={t("isim")} value={name} setChange={setName} />
              <Input
                title={t("soyisim")}
                value={surname}
                setChange={setSurname}
              />

              <DropDown
                data={[
                  {
                    value: "İlişki",
                    name: "İlişki",
                  },
                  {
                    value: "Kontak",
                    name: "Kontak",
                  },
                ]}
                title={"Statü"}
                setChange={setStatusForm}
              />
              <DropDown
                data={[
                  {
                    value: "Manüel",
                    name: "Manüel",
                  },
                  {
                    value: "Dijital",
                    name: "Dijital",
                  },
                ]}
                title={"Açık Rıza Tipi"}
                setChange={setChannel}
              />
              <DropDown
                data={[
                  {
                    value: "Veri Sahibinin Alenileştirmesi",
                    name: "Veri Sahibinin Alenileştirmesi",
                  },
                  {
                    value: "Bir İş Akdi Kurulumu veya İfası",
                    name: "Bir İş Akdi Kurulumu veya İfası",
                  },
                  {
                    value: "Veri Sorumlusu Hukuki Yükümlülükleri",
                    name: "Veri Sorumlusu Hukuki Yükümlülükleri",
                  },
                  { value: "Açık Rıza Alınması", name: "Açık Rıza Alınması" },
                  { value: "KAPSAM DIŞI", name: "KAPSAM DIŞI" },
                ]}
                title={"Veri Gerekçesi"}
                setChange={setDataProcessing}
              />
              <DateBetweenInput
                title={t("olusturulmaTarihi")}
                setChangeStart={(e) => {
                  const inputValue = e.target.value;
                  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

                  if (dateFormatRegex.test(inputValue)) {
                    const inputDate = new Date(inputValue);
                    if (!isNaN(inputDate.getTime())) {
                      // Check if it's a valid date
                      setCreateAtRealStart(inputDate.toISOString());
                    } else {
                      console.error("Invalid date input:", inputValue);
                    }
                  } else {
                    console.error("Invalid date format:", inputValue);
                  }

                  // setCreateAtRealStart(new Date(e.target.value).toISOString());
                }}
                setChangeEnd={(e) => {
                  const inputValue = e.target.value;
                  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

                  if (dateFormatRegex.test(inputValue)) {
                    const inputDate = new Date(inputValue);
                    if (!isNaN(inputDate.getTime())) {
                      // Check if it's a valid date
                      setCreateAtRealEnd(inputDate.toISOString());
                    } else {
                      console.error("Invalid date input:", inputValue);
                    }
                  } else {
                    console.error("Invalid date format:", inputValue);
                  }
                  // setCreateAtRealEnd(new Date(e.target.value).toISOString());
                }}
                setData={null}
                data={null}
                setManuelStartDate={setCreateAtRealStart}
                setManuelEndDate={setCreateAtRealEnd}
              />
            </>
          ) : setInventoryData ? (
            <>
              <DropDown
                data={[
                  {
                    value: "ofis",
                    name: t("ofis"),
                  },
                  {
                    value: "atolye",
                    name: t("atolye"),
                  },
                  {
                    value: "dukkan",
                    name: t("dukkan"),
                  },
                  {
                    value: "konut",
                    name: t("konut"),
                  },
                  {
                    value: "villa",
                    name: t("villa"),
                  },
                ]}
                title={t("urunTipi")}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    corporateStatus: text,
                  });
                }}
              />
              <Input
                title={t("projeAdi")}
                value={inventoryFilter.queryProjectName}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    queryProjectName: text,
                  });
                }}
              />
              <Input
                title={t("uniteIsmi")}
                value={inventoryFilter.inventoryName}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    inventoryName: text,
                  });
                }}
              />
              <DropDown
                data={[
                  {
                    value: "Satılık",
                    name: t("satilik"),
                  },
                  {
                    value: "Kiralık",
                    name: t("kiralik"),
                  },
                  {
                    value: "Satılık veya Kiralık",
                    name: t("satilikVeyaKiralik"),
                  },
                ]}
                title={t("urunStatusu")}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    inventoryCategory: text,
                  });
                }}
              />
              <DropDown
                data={statusSelDatas}
                title={t("uniteSunumSekli")}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    status: text,
                  });
                }}
              />
              <Input
                title="Daire Tipi"
                value={inventoryFilter.circleType}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    circleType: text,
                  });
                }}
              />
              {/* 
              <Input
                title={`Kat`}
                value={inventoryFilter.floor}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    floor: text,
                  });
                }}
              />
              */}
              <Input
                title={t("ilce")}
                value={inventoryFilter.city}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    city: text,
                  });
                }}
              />
              {/* <Input
                title={t("uniteSunumSekli")}
                value={inventoryFilter.city}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    city: text,
                  });
                }}
              /> */}
              {/*
              <Input
                title="Bahçe Metrekare"
                value={inventoryFilter.gardenM2}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    gardenM2: text,
                  });
                }}
              />
              <Input
                title="Havuz Metrekare"
                value={inventoryFilter.poolM2}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    poolM2: text,
                  });
                }}
              />
              <Input
                title="Teras Metrekare"
                value={inventoryFilter.terraceM2}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    terraceM2: text,
                  });
                }}
              />
              <Input
                title="Balkon Metrekare"
                value={inventoryFilter.balconyM2}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    balconyM2: text,
                  });
                }}
              />
              <Input
                title="Metrekare Birim Satış Bedeli"
                // value={inventoryFilter.salesPriceM2}
                // setChange={(text) => {
                //   setInventoryFilter({
                //     ...bidsFilterBody,
                //     salesPriceM2: text,
                //   });
                // }}
              />
              <Input
                title="Metrekare Birim Kira Bedeli"
                // value={inventoryFilter.salesRentM2}
                // setChange={(text) => {
                //   setInventoryFilter({
                //     ...bidsFilterBody,
                //     salesRentM2: text,
                //   });
                // }}
              />
              <Input
                title="KDV Hariç Liste Fiyatı"
                // value={inventoryFilter.excludingVatListPrice}
                // setChange={(text) => {
                //   setInventoryFilter({
                //     ...bidsFilterBody,
                //     excludingVatListPrice: text,
                //   });
                // }}
              />
              <Input
                title="Kira Bedeli"
                //  value={inventoryFilter.rentPrice}
                //   setChange={(text) => {
                //     setInventoryFilter({
                //       ...bidsFilterBody,
                //       rentPrice: text,
                //     });
                //   }}
              />
              */}
              <InputBetween
                title={t("netMetrekare")}
                firstValue={inventoryFilter.firstNetM2}
                secondValue={inventoryFilter.secondNetM2}
                currencyShow={false}
                data={inventoryFilter}
                setData={setInventoryFilter}
                nullData={{
                  inventoryFilter: ["firstNetM2", "secondNetM2e"],
                }}
                setFirstChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    firstNetM2: e.target.value,
                  });
                }}
                setSecondChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    secondNetM2: e.target.value,
                  });
                }}
              />
              <InputBetween
                title={t("brutMetrekare")}
                firstValue={inventoryFilter.firstBrutM2}
                secondValue={inventoryFilter.secondBrutM2}
                currencyShow={false}
                data={inventoryFilter}
                setData={setInventoryFilter}
                nullData={{
                  inventoryFilter: ["firstBrutM2", "secondBrutM2e"],
                }}
                setFirstChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    firstBrutM2: e.target.value,
                  });
                }}
                setSecondChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    secondBrutM2: e.target.value,
                  });
                }}
              />
              <Input
                title={t("manzara")}
                value={inventoryFilter.Landscape}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    Landscape: text,
                  });
                }}
              />
              <DropDown
                data={inventoryDirectionDatas}
                title={t("yon")}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    Direction: text,
                  });
                }}
              />
              <Input
                title={t("malSahibi")}
                value={inventoryFilter.queryOwnerInventoryName}
                setChange={(text) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    queryOwnerInventoryName: text,
                  });
                }}
              />
              <InputBetween
                title={t("kdvDahilSatisFiyati")}
                firstValue={inventoryFilter.firstWithVatListPrice}
                secondValue={inventoryFilter.secondWithVatListPrice}
                currencyValue={inventoryFilter.currency}
                currencyShow={true}
                data={inventoryFilter}
                setData={setInventoryFilter}
                nullData={{
                  inventoryFilter: [
                    "firstWithVatListPrice",
                    "secondWithVatListPrice",
                    "currency",
                  ],
                }}
                setFirstChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    firstWithVatListPrice: e.target.value,
                  });
                }}
                setSecondChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    secondWithVatListPrice: e.target.value,
                  });
                }}
                setCurrencyChange={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    currency: e.target.value,
                  });
                }}
              />
              <DateBetweenInput
                title={t("olusturulmaTarihi")}
                setChangeStart={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    createAtRealStart: new Date(e.target.value).toISOString(),
                  });
                }}
                setChangeEnd={(e) => {
                  setInventoryFilter({
                    ...inventoryFilter,
                    createAtRealEnd: new Date(e.target.value).toISOString(),
                  });
                }}
                data={inventoryFilter}
                setData={setInventoryFilter}
              />
            </>
          ) : setBidData ? (
            <>
              <Input
                title={t("musteriAdi")}
                value={bidsFilterBody.queryContactName}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    queryContactName: text,
                  });
                }}
              />
              <Input
                title={t("olusturanPersonel")}
                value={bidsFilterBody.ownerBid}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    ownerBid: text,
                  });
                }}
              />
              <Input
                title={t("aktiviteIsmi")}
                value={bidsFilterBody.bidName}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    bidName: text,
                  });
                }}
              />
              <DropDown
                data={
                  i18n.language === "tr"
                    ? meetingShapeTR
                    : i18n.language === "en"
                    ? meetingShapeEN
                    : null
                }
                title={t("gorusmeKanali")}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    meetingShape: text,
                  });
                }}
              />
              <DropDown
                data={[
                  {
                    value: "Satış",
                    name: t("satisDepertmani"),
                  },
                  {
                    value: "Kiralama",
                    name: t("kiralamaDepartmani"),
                  },
                ]}
                title={t("aktiviteTipi")}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    bidShape: text,
                  });
                }}
              />
              <Input
                title={t("projeAdi")}
                value={bidsFilterBody.queryProjeName}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    queryProjeName: text,
                  });
                }}
              />
              <Input
                title={t("urunTipi")}
                value={bidsFilterBody.queryInventoryName}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    queryInventoryName: text,
                  });
                }}
              />
              <Input
                title={t("malSahibi")}
                value={bidsFilterBody.queryOwnerName}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    queryOwnerName: text,
                  });
                }}
              />
              <Input title={t("kdvHaric")} />
              <Input
                title={t("indirimOrani")}
                value={bidsFilterBody.discountRate}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    discountRate: text,
                  });
                }}
              />
              <DropDown
                data={
                  i18n.language === "tr"
                    ? bankCreditTR
                    : i18n.language === "en"
                    ? bankCreditEN
                    : null
                }
                title={t("krediKullanilanBanka")}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    bankToBeUsed: text,
                  });
                }}
              />

              <DateInput
                title={t("teslimTarihi")}
                setChange={(e) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    deliveryTime: e.target.value,
                  });
                }}
              />
              <DropDown
                data={
                  i18n.language === "tr"
                    ? [{ value: "Sunum", name: t("sunum") }, ...bidStageTR]
                    : i18n.language === "en"
                    ? [{ value: "Sunum", name: t("sunum") }, ...bidStageEN]
                    : null
                }
                title={t("aktiviteAsamasi")}
                setChange={(text) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    bidStage: text,
                  });
                }}
              />
              {bidsFilterBody.bidStage === "Başarısız Kapanış" && (
                <>
                  <DropDown
                    data={
                      i18n.language === "tr"
                        ? failedStageNoteTR
                        : i18n.language === "en"
                        ? failedStageNoteEN
                        : null
                    }
                    title={t("basarisizKapanisNedeni")}
                    setChange={(text) => {
                      setBidsFilterBody({
                        ...bidsFilterBody,
                        failedStageNote: text,
                      });
                    }}
                  />
                  <DateInput
                    title={t("kapanisTarihi")}
                    setChange={(e) => {
                      setBidsFilterBody({
                        ...bidsFilterBody,
                        closingDate: new Date(e.target.value).toISOString(),
                      });
                    }}
                  />
                </>
              )}
              <DateBetweenInput
                title={t("aktiviteTarihi")}
                setChangeStart={(e) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    createAtRealStart: new Date(e.target.value).toISOString(),
                  });
                }}
                setChangeEnd={(e) => {
                  setBidsFilterBody({
                    ...bidsFilterBody,
                    createAtRealEnd: new Date(e.target.value).toISOString(),
                  });
                }}
                data={bidsFilterBody}
                setData={setBidsFilterBody}
              />
            </>
          ) : setApprovalData ? (
            <>
              <Input
                title={t("musteriAdi")}
                value={approvalFilterBody.queryContactName}
                setChange={(text) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    queryContactName: text,
                  });
                }}
              />
              <Input
                title={t("olusturanPersonel")}
                value={approvalFilterBody.ownerBid}
                setChange={(text) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    ownerBid: text,
                  });
                }}
              />
              <Input
                title={t("projeAdi")}
                value={approvalFilterBody.queryProjeName}
                setChange={(text) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    queryProjeName: text,
                  });
                }}
              />
              <Input
                title={t("urunTipi")}
                value={approvalFilterBody.queryInventoryName}
                setChange={(text) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    queryInventoryName: text,
                  });
                }}
              />
              <Input
                title={t("malSahibi")}
                value={approvalFilterBody.queryOwnerName}
                setChange={(text) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    queryOwnerName: text,
                  });
                }}
              />
              <DateInput
                title={t("olusturulmaTarihi")}
                setChange={(e) => {
                  setApprovalFilterBody({
                    ...approvalFilterBody,
                    createAtReal: new Date(e.target.value).toISOString(),
                  });
                }}
              />
            </>
          ) : setNoteData ? (
            <>
              <Input
                title={t("kontak")}
                value={noteFilter.queryContactName}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryContactName: text,
                  });
                }}
              />
              <Input
                title={t("telefon")}
                value={noteFilter.queryPhone}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryPhone: text,
                    queryGsm: text,
                  });
                }}
              />
              <Input
                title={t("email")}
                maxLength={"50"}
                value={noteFilter.queryEmail}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryEmail: text,
                  });
                }}
              />
              <Input
                title={t("tcno")}
                value={noteFilter.queryTckn}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryTckn: text,
                  });
                }}
              />

              <Input
                title={t("olusturanPersonel")}
                value={noteFilter.noteOwner}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    noteOwner: text,
                  });
                }}
              />
              <Input
                title={t("iliskiliAktivite")}
                value={noteFilter.queryBidName}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryBidName: text,
                  });
                }}
              />
              {/* <DropDown
                data={[
                  {
                    value: "Yüzyüze Görüşme",
                    name: "Yüzyüze Görüşme",
                  },
                  {
                    value: "Gelen Telefon",
                    name: "Gelen Telefon",
                  },
                  {
                    value: "Giden Telefon",
                    name: "Giden Telefon",
                  },
                  {
                    value: "Müşteriye Ziyaret",
                    name: "Müşteriye Ziyaret",
                  },
                  {
                    value: "WhatsApp",
                    name: "WhatsApp",
                  },
                  {
                    value: "Chat",
                    name: "Chat",
                  },
                  {
                    value: "E-Mail",
                    name: "E-Mail",
                  },
                  {
                    value: "Instagram",
                    name: "Instagram",
                  },
                  {
                    value: "Facebook",
                    name: "Facebook",
                  },
                ]}
                title={"Görüşme Kanalı"}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    meetingShape: text,
                  });
                }}
              /> */}
              <Input
                title={t("iliskiliProje")}
                value={noteFilter.queryProjectName}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryProjectName: text,
                  });
                }}
              />
              <Input
                title={t("urunTipi")}
                value={noteFilter.queryInventoryName}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    queryInventoryName: text,
                  });
                }}
              />

              <Input
                title={t("baslikNot")}
                value={noteFilter.title}
                setChange={(text) => {
                  setNoteFilter({
                    ...noteFilter,
                    title: text,
                  });
                }}
              />
              <DateInput
                title={t("notTarih")}
                setChange={(e) => {
                  setNoteFilter({
                    ...noteFilter,
                    meetingDate: new Date(e.target.value).toISOString(),
                  });
                }}
              />
            </>
          ) : setProjectData ? (
            <>
              <Input
                title={t("projeAdi")}
                value={projectFilter.projectName}
                setChange={(text) => {
                  setProjectFilter({
                    ...projectFilter,
                    projectName: text,
                  });
                }}
              />

              <Input
                title={t("ulke")}
                value={projectFilter.country}
                setChange={(text) => {
                  setProjectFilter({
                    ...projectFilter,
                    country: text,
                  });
                }}
              />

              <Input
                title={t("il")}
                value={projectFilter.city}
                setChange={(text) => {
                  setProjectFilter({
                    ...projectFilter,
                    city: text,
                  });
                }}
              />

              <Input
                title={t("adres")}
                value={projectFilter.address}
                setChange={(text) => {
                  setProjectFilter({
                    ...projectFilter,
                    address: text,
                  });
                }}
              />
              <DateBetweenInput
                title={t("olusturulmaTarihi")}
                setChangeStart={(e) => {
                  setProjectFilter({
                    ...projectFilter,
                    createAtRealStart: new Date(e.target.value).toISOString(),
                  });
                }}
                setChangeEnd={(e) => {
                  setProjectFilter({
                    ...projectFilter,
                    createAtRealEnd: new Date(e.target.value).toISOString(),
                  });
                }}
                data={projectFilter}
                setData={setProjectFilter}
              />
            </>
          ) : setUserData ? (
            <>
              <Input
                title="ID"
                value={userFilter._id}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    _id: text,
                  });
                }}
              />
              <Input
                title="Isim"
                value={userFilter.name}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    name: text,
                  });
                }}
              />
              <Input
                title="Soyisim"
                value={userFilter.surname}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    surname: text,
                  });
                }}
              />
              <Input
                title="E-mail"
                value={userFilter.email}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    email: text,
                  });
                }}
              />
              <Input
                title="Telefon"
                value={userFilter.phone}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    phone: text,
                  });
                }}
              />
              <DateInput
                title="Son Giriş Tarihi"
                setChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    lastOnline: filterDateValueConvert(e.target.value),
                  });
                }}
              />
              <DropDown
                data={[
                  {
                    value: true,
                    name: "Aktif",
                  },
                  {
                    value: false,
                    name: "Susturulmuş",
                  },
                ]}
                title={"Hesap Durumu"}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    isActive: text,
                  });
                }}
              />
              <DateInput
                title="Oluşturulma Tarihi"
                setChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    createdAt: filterDateValueConvert(e.target.value),
                  });
                }}
              />
              <DropDown
                data={[
                  { name: "Yönetim Kurlu Başkanı", value: "ceo" },
                  { name: "Genel Müdür", value: "gm" },
                  { name: "Pazarlama Koordinatörü", value: "mc" },
                  { name: "Dijital Pazarlama Uzmanı", value: "dms" },
                  { name: "Bölge Müdürü", value: "rd" },
                  { name: "Satış Müdürü", value: "sm" },
                  { name: "Satış Direktörü", value: "sd" },
                  { name: "Satış Uzmanı", value: "ss" },
                  { name: "Satış Asistanı", value: "sa" },
                  { name: "İnsan Kaynakları", value: "hr" },
                  { name: "Finans Departmanı", value: "fd" },
                  { name: "Proje Geliştirme", value: "pd" },
                ]}
                title={"Departman"}
                setChange={(text) => {
                  setUserFilter({
                    ...userFilter,
                    department: text,
                  });
                }}
              />
            </>
          ) : (
            <>
              <Input
                title={t("projeAdi")}
                value={queryProjectName}
                setChange={setQueryProjectName}
              />
              <Input title={t("isim")} value={name} setChange={setName} />
              <Input
                title={t("soyisim")}
                value={surname}
                setChange={setSurname}
              />
              <Input
                title={t("email")}
                value={email}
                setChange={setEmail}
                maxLength={"50"}
              />
              <Input
                title={t("sirket")}
                value={company}
                setChange={setCompany}
              />
              <Input title={t("telefon")} value={phone} setChange={setPhone} />
              {/* <DropDown
                data={[
                  {
                    value: "Manüel",
                    name: "Manüel",
                  },
                  {
                    value: "Dijital",
                    name: "Dijital",
                  },
                ]}
                title={"İlişki Kanalı"}
                setChange={(text) => {
                  setChannel(text);
                }}
              /> */}
              <DropDown
                data={[
                  {
                    value: "Bireysel",
                    name: t("bireysel"),
                  },
                  {
                    value: "Kurumsal",
                    name: t("kurumsal"),
                  },
                ]}
                title={t("kullanciTipi")}
                setChange={(text) => {
                  setOwner(text);
                }}
              />
              <DateInput
                title={t("olusturulmaTarihi")}
                setChange={(e) => {
                  setCreateAtReal(new Date(e.target.value).toISOString());
                }}
              />
            </>
          )}
        </div>
        <div className={style.buttonWrapper}>
          <button
            onClick={() => {
              setHasFilterData(false);
              if (setBidData) {
                filterDataBids();
              } else if (setApprovalData) {
                filterDataApprovals();
              } else if (setInventoryData) {
                filterDataInventory();
              } else if (setNoteData) {
                filterDataNote();
              } else if (setUserData) {
                filterUserHandler();
              } else if (setProjectData) {
                filterProjectHandler();
              } else {
                filterData();
              }
              // setBidData
              // ? filterDataBids
              // : setInventoryData
              // ? filterDataInventory
              // : setNoteData
              // ? filterDataNote
              // : setUserData
              // ? filterUserHandler
              // : setProjectData
              // ? filterProjectHandler
              // : filterData
            }}
          >
            {t("filtreyiUygula")}
          </button>
          <button onClick={clearData}>{t("temizle")}</button>
        </div>
      </div>
    </>
  );
};

export default SideFilter;
