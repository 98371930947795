import style from './leaddetail.module.scss';
import { SubHeader, SideNav, LeadCard } from '../../components';
import axios from 'axios';
import { useParams,useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BaseUrl } from '../../ApiConfig';
import { useSelector } from 'react-redux';

const LeadDetail = () => {
  const user = useSelector((state) => state.user.userData);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/lead/findone/${id}`,{
        headers: {
          "Authorization": `Bearer ${user.token}`
        }
      })
      .then(res => {
        setData(res.data.lead);
      })
      .catch(err => {
        console.log(err);
        navigate("/lead-list", { replace: true });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div className={style.leadDetail}>
      {data ? (
        <div >
          <SubHeader
            leadDetail
            owner={data.owner}
            name={data.name}
            surname={data.surname}
          />
        
          <div className={style.content}>
            <SideNav />
            <div className={style.info}>
              <LeadCard data={data} />
            </div>
          </div>         
 
        </div>

      ) : null}
    </div>    
  );
};

export default LeadDetail;
