import style from "./ProjectListPopUp.module.scss";
import { useTranslation } from "react-i18next";

const UserListPopUp = ({ closeClick, successClick, fullName, type, count }) => {
  const { t } = useTranslation();
  /*
  const deleteText =
    "isimli projeyi Silmek istediğinize emin misiniz? Seçtiğiniz projeye kayıtlı 2 tane Aktivite bulunmaktadır. Projenin silinmesi aktivitelerdinde silinmesine neden olacaktır ve bu işlemi gerçekleştirildikten sonra geri alamayacaksınız.";
  */
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {type === "delete" ? t("projeSilmePopUpTitle") : ""}
          </div>
          <hr />
          <div className={style.textcontainer}>
            <strong>{fullName}</strong> {t("projeSilmePopUpText1")}
            <strong> {count} {t("projeSilmePopUpText2")} </strong> {t("projeSilmePopUpText3")}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {type === "error" ? t("tamam") : t("vazgec")}
            </button>
            <button onClick={successClick} className={style.successModalPopup}>
              {type === "delete" ? t("sil") : " "}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default UserListPopUp;
