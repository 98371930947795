import React, { useState, useEffect, useRef } from "react";
import style from "./ProjectSelection.module.scss";
import { DownIcon, UpIcon } from "../../../assets/icons/index";
import { useTranslation } from "react-i18next";

function ProjectSelection({
  allProjects,
  checkedCheckboxes,
  setCheckedCheckboxes,
  title,
  placeholder,
  props,
}) {
  const { t } = useTranslation();
  const [select, setSelect] = useState(false);
  const [searchProjects, setSearchProjects] = useState();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchProjects(allProjects);
  }, [allProjects]);

  const projectMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      projectMenu.current &&
      select &&
      !projectMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleCheckboxChange = (data) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox === data
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter((checkedCheckbox) => checkedCheckbox !== data)
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(data));
    }
  };

  const searchHandler = (e) => {
    setSearchValue(e.target.value);
    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      setSearchProjects(allProjects);
      return;
    }
    const filteredProjects = allProjects?.filter((project) => {
      return project?.projectName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchProjects(filteredProjects);
  };

  return (
    <div className={style.checkboxContact} ref={projectMenu}>
      <div className={style.checkboxTitle}>{title}</div>
      <button
        {...props}
        className={style.button}
        onClick={() => {
          setSelect(!select);
        }}
      >
        {placeholder}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <input
            className={style.selectInput}
            placeholder={t("projeAra")}
            type="search"
            value={searchValue}
            onChange={searchHandler}
          />
          {allProjects?.length === 0 && <div>Henüz hiç proje eklenmemiş.</div>}
          {searchProjects?.length === 0 && (
            <div>Aradığınız sonuç bulunamadı.</div>
          )}
          <div className={style.items}>
            {searchProjects?.map((column) => (
              <div key={column._id}>
                <label>
                  <input
                    key={column._id}
                    type="checkbox"
                    value={column._id}
                    checked={checkedCheckboxes.some(
                      (checkedCheckbox) => checkedCheckbox === column._id
                    )}
                    onChange={() => handleCheckboxChange(column._id)}
                  />
                  {column.projectName}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProjectSelection;
