import React, { useState } from "react";
import style from "./AdminSystem.module.scss";
import { SubHeader, SideNav } from "../../components";
import DropDownModal from "./DropDownModal";
import { ClickableButtonInput } from "../../components/Forms/Inputs";
import { getDropDownItems } from "../../lib/dropdowns";
import { useSelector } from "react-redux";
function AdminSystem() {
  const { token } = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getDropDataHandler = (type) => {
    setData([]);
    getDropDownItems(token, {
      types: [type],
    })
      .then((res) => {
        setData(res.data);
        setModalType(type);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={style.adminSytemDetail}>
      {showModal && (
        <DropDownModal
          setShowModal={setShowModal}
          data={data}
          modalType={modalType}
        />
      )}
      <div>
        <SubHeader adminSystem form />

        <div className={style.content}>
          <SideNav adminSystem />
          <div className={style.info}>
            <div className={style.systemCard}>
              <div className={style.left}>
                <ClickableButtonInput
                  title="Kontak Kaynağı"
                  onClick={() => getDropDataHandler("contactSource")}
                  placeholder="Kontak Kaynağı Dropdown"
                />
                <ClickableButtonInput
                  title="Kredi Kullanıcak Banka"
                  onClick={() => getDropDataHandler("bankCredit")}
                  placeholder="Kredi Kullanıcak Banka Dropdown"
                />
                <ClickableButtonInput
                  title="Başarısız Kapanış"
                  onClick={() => getDropDataHandler("failedStageNote")}
                  placeholder="Başarısız Kapanış Dropdown"
                />
              </div>
              <div className={style.right}>
                <ClickableButtonInput
                  title="Görüşme Kanalı"
                  onClick={() => getDropDataHandler("meetingShape")}
                  placeholder="Görüşme Kanalı Dropdown"
                />
                <ClickableButtonInput
                  title="Aktivite Aşaması"
                  onClick={() => getDropDataHandler("bidStage")}
                  placeholder="Aktivite Aşaması Dropdown"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSystem;
