import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllNotes = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = 'get',
  data = {}
) => {
  let request;
  if (method === 'get') {
    request = await axios.get(
      `${BaseUrl}/api/note/getNote/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === 'post') {

    request = await axios.post(
      `${BaseUrl}/api/note/queryNote/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }
  const payload = {
    data: request.data.note,
    pageCount: request.data.pageCount,
  };
  return payload;
};
