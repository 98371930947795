export const extractIdsFromObject = (obj, targetKey = "_id") => {
  const ids = [];
  for (const key in obj) {
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      targetKey in obj[key]
    ) {
      ids.push(obj[key][targetKey]);
    }
  }
  return ids;
};

export const toCamelCaseKey = (str) => {
  return str
    .toLowerCase() // Tüm metni küçük harfe çevir
    .split(" ") // Boşluk karakterine göre ayır
    .map((word, index) => {
      if (index === 0) {
        return word; // İlk kelimeyi olduğu gibi küçük harf bırak
      }
      return word.charAt(0).toUpperCase() + word.slice(1); // İlk harfi büyük, geri kalanı küçük yap
    })
    .join(""); // Kelimeleri birleştir
};

export const replaceTurkishChars = (str) => {
  const charMap = {
    ç: "c",
    ğ: "g",
    ı: "i",
    ö: "o",
    ş: "s",
    ü: "u",
    Ç: "C",
    Ğ: "G",
    İ: "I",
    Ö: "O",
    Ş: "S",
    Ü: "U",
  };

  return str
    .split("")
    .map((char) => charMap[char] || char)
    .join("");
};

export const generateRandomColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`; // Hexadecimal renk kodu üret
    colors.push(color);
  }
  return colors;
};
