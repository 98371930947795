import style from "./subheader.module.scss";
import { BackIcon } from "../../assets/icons";
import { useNavigate } from "react-router";

const UserCard = ({ name, surname }) => {
  const navigate = useNavigate();
  return (
    <div className={style.leadCard}>
      <button
        className={style.backButton}
        onClick={() => {
          navigate("/admin/kullaci-listesi", { replace: true });
        }}
      >
        <BackIcon />
      </button>
      <div className={style.avatar}>{(name[0] + surname[0]).toUpperCase()}</div>
      <h2>
        {name} {surname}
      </h2>
    </div>
  );
};

const UserDetailMenu = ({userDetailData }) => {
  return (
    <div className={style.leadDetailMenu}>
      <div className={style.left}>
        <UserCard name={userDetailData.name} surname={userDetailData.surname} />
      </div>
      <div className={style.right}>
        {/* <NewButton title={"E-Mail Gönder"} /> */}
      </div>
    </div>
  );
};

export default UserDetailMenu;
