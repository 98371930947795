/* eslint-disable react-hooks/exhaustive-deps */
// import { useState, useEffect } from "react";
import { useState } from "react";
// import { ProcessButton5, NewButton } from "./Buttons";
import { NewButton } from "./Buttons";
import style from "./subheader.module.scss";
import axios from "axios";
import Popup from "../LeadElements/Popup";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
//import { selectKvkk } from "../../redux/actions";
import { BaseUrl } from "../../ApiConfig";
// import ExcelExportPopup from "./ExcelExportPopup.jsx";

import {useTranslation} from "react-i18next";

const LeadMenu = ({ leadData, getToggleHideAllColumnsProps, allColumns }) => {

  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  // const [selectValue, setSelectValue] = useState();
  const [popup, setPopup] = useState(false);
  const [kvkPopup, setKvkPopup] = useState(false);
  // const [excelData, setExcelData] = useState([]);
  //  const [excelPopup, setExcelPopup] = useState(false);
  // const [headers, setHeaders] = useState([]);

  const deleteCustomerByIds = () => {
    let arrayids = [];
    leadData.forEach((d) => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    axios
      .delete(`${BaseUrl}/api/lead/delete/${arrayids}`)
      .then((data) => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => alert(err.response.data));
  };
  /*
  const pushContact = () => {
    const userType = user.userType;

    let arrayids = [];
    leadData.forEach((d) => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    axios
      .post(`${BaseUrl}/api/contact/addcontact/${arrayids}/${userType}`)
      .then((response) => {
        if (response.data.success === true) {
          navigate("/contact-list", { replace: true });
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Kvk bulunamadı") {
          setKvkPopup(true);
        }
      });
  };
  */
  // useEffect(() => {
  //   if (selectValue === "delete") {
  //     setPopup(true);
  //   }
  //   if (selectValue === "kalifiye") {
  //     pushContact();
  //   }
  //   if (selectValue === "kvkk") {
  //     dispatch(
  //       selectKvkk(
  //         leadData.filter((item) => item.select).map((item) => item.kvkkId)
  //       )
  //     );
  //   }
  // }, [selectValue]);
  /*
  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      "İsim",
      "Soyisim",
      "Cep Telefonu",
      "E-Posta",
      "Not",
      "Firma",
      "Kvkk E-Posta",
      "Oluşturulma Tarihi",
      "İlişki Sahibi",
      "İlişki Kullancı Tipi",
    ]);
    const newData = data.map((item) => {
      return {
        İsim: item.name,
        Soyisim: item.surname,
        "Cep Telefonu": item.phone,
        "E-Posta": item.email,
        Not: item.note,
        Firma: item.company,
        "Kvkk E-Posta": item.channel,
        "Oluşturulma Tarihi": item.createdAt,
        "İlişki Sahibi": item.owner,
        "İlişki Kullancı Tipi": item.userType,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };
  */
  return (
    <>
      {popup ? (
        <Popup closeClick={setPopup} successClick={deleteCustomerByIds} />
      ) : null}

      {/* {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="40px 10px"
          data={excelData}
          fileName="İlişkiler"
          text="Filtrelediğiniz ilişkilere ait Excel başlıklarını seçiniz:"
        />
      ) : null} */}

      {kvkPopup ? (
        <Popup
          closeClick={setKvkPopup}
          isKvkExistTitle={kvkPopup}
          //successClick={deleteCustomerByIds}
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("projeler")}
          </div>
        </div>
        <div className={style.right}>
        {/* {(user.department === "admin" ||
            user.department === "ceo"  ) && (
          <NewButton
            title={t("topluVeriYukle")}
            // onClick={() => window.location.replace("/create-lead")}
          />)} */}

          {/* <NewButton
            title={"Veriyi Dışa Aktar"}
            icon={"exel"}
            onClick={() => {
              excelExportAndDownload(leadData);
            }}
          /> */}
          
          {(user.department === "admin" ||
            user.department === "ceo"  ) && (
          <NewButton
            title={t("yeniProjeEkleButton")}
            onClick={() => {
              navigate("/project-create", { replace: true });
              //window.location.replace('/project-create')
            }}
          />)}
          {/* {(user.department === "admin" ||
            user.department === "ceo" ||
            user.department === "gm" ||
            user.department === "sd" ||
            user.department === "rd" ||
            user.department === "sm"
             ) && (
          <ProcessButton5
            title={t("cokluIslemler")}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
          />)} */}
        </div>
      </div>
    </>
  );
};

export default LeadMenu;
