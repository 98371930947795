import style from "./LeadPopup.module.scss";
import { useTranslation } from "react-i18next";
const LeadPopup = ({ closeClick, errorText, title, type, successClick }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {title ? title : t("yeniIliskiKayit")}
          </div>
          <hr />
          <div className={style.textcontainer}>{errorText}</div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {type === "delete" ? t("vazgec") : t("tamam")}
            </button>
            {type === "delete" && (
              <button
                onClick={successClick}
                className={style.successModalPopup}
              >
                {t("sil")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default LeadPopup;
