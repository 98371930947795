/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { NewButton, ProcessButton5 } from "./Buttons";
import style from "./subheader.module.scss";
import Popup from "../LeadElements/Popup";
import { DownIcon, UpIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
//import { inventoryChecked } from "../../redux/actions";
import { useNavigate } from "react-router";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";
import { deleteAInventory } from "../../lib/InventoryApi";
import TopluVeriYuklePopUp from "./TopluVeriYuklePopUp";

import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();
  const checkboxMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      checkboxMenu.current &&
      select &&
      !checkboxMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={() => setSelect(!select)}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{" "}
            {t("hepsiniSec")}
          </div>
          {allColumns.map((column) => (
            <div key={column.id}>
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                {column.Header}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const InventoryMenu = ({
  method,
  setNewMethod,
  selectedInventory,
  inventoryListData,
  getToggleHideAllColumnsPropsFromInventory,
  allInventoryColumns,
  inventoryRows,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [selectValue, setSelectValue] = useState();
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();

  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const [topluVeriYuklePopup, setTopluVeriYuklePopup] = useState(false);

  const [count, setCount] = useState(0);
  const [type, setType] = useState("");
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState("");

  const deleteInventoryByIds = () => {
    let arrayids = [];
    inventoryRows.forEach((element) => {
      if (element.isSelected) {
        arrayids.push(element.original._id);
      }
    });

    deleteAInventory(localStorage.getItem("AuthToken"), arrayids)
      .then((res) => {
        setType("");
        setPage("");
        setIsError(false);
        setPopup(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
      });

    //TODO refresh page problem after updating data look in to it later on
  };

  // const changeStatusInventory = () => {
  //   let arrayids = [];
  //   inventoryListData.forEach(d => {
  //     if (d.select) {
  //       arrayids.push(d.id);
  //     }
  //   });
  // axios
  //   .post(`https://aremas-api-new.herokuapp.com/api/project/changeStatus`, {
  //     ids: arrayids,
  //   })
  //   .then(data => {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //   })
  //   .catch(err => alert(err.response.data));
  // };

  useEffect(() => {
    if (selectValue === "delete") {
      setPopup(true);
      setType("delete");
      setPage("inventory");
      const isElelemtSelected = inventoryRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(inventoryRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }
      setSelectValue("default");
    }
  }, [selectValue, inventoryRows]);

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("projeID"),
      t("uniteID"),
      t("malSahibiID"),
      t("projeAdi"),
      t("uniteIsmi"),
      t("uniteSunumSekli"),
      t("urunStatusu"),
      t("urunTipi"),
      t("manzaraKirilmalari"),
      t("manzara"),
      t("yon"),
      t("daireTipi"),
      t("kullanciTipi"),
      t("teslimSekli"),
      t("binaAdi"),
      t("binaAcilisTarihi"),
      t("websitesi"),
      t("ulasim"),
      t("sosyalOlanaklar"),
      t("gelistirici"),
      t("insaatAlani"),
      t("verimlilik"),
      t("iklimlendirme"),
      t("asansorSayisi"),
      t("zeminUzeriToplamKatSayisi"),
      t("toplamOfisAlani"),
      t("katSecenekleri"),
      t("toplamKatSayisi"),
      t("toplamDukkanAlani"),
      t("dukkanSecenekleri"),
      t("mekanikAyrinti"),
      t("dukkanBrutAlani"),
      t("dukkanAcikAlanBrutAlani"),
      t("dukkanHemzeminDepoAlani"),
      t("dukkanAltKatDepoAlani"),
      t("dukkanSaftAlani"),
      t("atolyeBrutAlan123"),
      t("atolyeBrutAlan45"),
      t("atolyeBrutAlan67"),
      t("atolyeBrutAlan89"),
      t("atolyeSaftAlani"),
      t("tavanYuksekligi"),
      t("odaSayisi"),
      t("balkoMetrekare"),
      t("ada"),
      t("parsel"),
      t("pafta"),
      t("blokNo"),
      t("disKapiNo"),
      t("kat"),
      t("bagimsizBolumNo"),
      t("adres"),
      t("il"),
      t("ulke"),
      t("cepheGenisligim"),
      t("brutTavanYuksekligi"),
      t("hemzeminDepoAlani"),
      t("altKatDepoAlaniBrutAlani"),
      t("depoSaftAlani"),
      t("acikYuklemeAlani"),
      t("tahsisliCalismaAlani"),
      t("idariOfisAlani"),
      t("sahanlikAlani"),
      t("ofisBrutAlani"),
      t("tirGirebilmeDurumu"),
      t("kreynAltyapisi"),
      t("tahsisliCatiMekanikAlani"),
      t("toplamSatilabilirAlan"),
      t("satisBrutMetrekare"),
      t("daireBrutMetrekare"),
      t("katBrutMetrekare"),
      t("netMetrekare"),
      t("bahceMetrekare"),
      t("havuzMetrekare"),
      t("terasMetrekare"),
      t("otoparkHakki"),
      t("vergiDurumu"),
      t("dovizBirimi"),
      t("metrekareBirimSatisFiyati"),
      t("metrekareBirimKiraBedeli"),
      t("metrekareBirimSatisBedeli"),
      t("kdvOrani"),
      t("kdvHaricListeFiyati"),
      t("kdvDahilSatisFiyati"),
      t("kdvDahilListeFiyati"),
      t("kiraBedeli"),
      t("aidat"),
      t("toplamMetrekareAidat"),
      t("kirasizDonem"),
      t("malSahibi"),
      t("not"),
    ]);

    const newData = data.map((item) => {
      return {
        [t("projeID")]: item.ownerProject?._id || "",
        [t("uniteID")]: item._id || "",
        [t("malSahibiID")]: item.inventoryOwnerId?._id || "",
        [t("projeAdi")]: item.queryProjectName,
        [t("uniteIsmi")]: item.inventoryName,
        [t("uniteSunumSekli")]: item.status,
        [t("urunStatusu")]: item.inventoryCategory,
        [t("urunTipi")]: item.corporateStatus,
        [t("manzaraKirilmalari")]: item.LandscapeRefractions,
        [t("manzara")]: item.Landscape,
        [t("yon")]: item.Direction,
        [t("daireTipi")]: item.circleType,
        [t("kullanciTipi")]: item.inventoryOwnerId?.userType || "Kurumsal",
        [t("teslimSekli")]: item.deliveryShape,
        [t("binaAdi")]: item.buildingName,
        [t("binaAcilisTarihi")]: item.buildingOpeningDate,
        [t("websitesi")]: item.website,
        [t("ulasim")]: item.transportation,
        [t("sosyalOlanaklar")]: item.socialOpportunities,
        [t("gelistirici")]: item.developer,
        [t("insaatAlani")]: item.constructionZone,
        [t("verimlilik")]: item.productivity,
        [t("iklimlendirme")]: item.airConditioning,
        [t("asansorSayisi")]: item.numberOfElevators,
        [t("zeminUzeriToplamKatSayisi")]: item.floorAboveGroundTotalLength,
        [t("toplamOfisAlani")]: item.totalOfficeSpace,
        [t("katSecenekleri")]: item.floorOptions,
        [t("toplamKatSayisi")]: item.floorTotalLength,
        [t("toplamDukkanAlani")]: item.totalStoreSpace,
        [t("dukkanSecenekleri")]: item.storeOptions,
        [t("mekanikAyrinti")]: item.mechanicalDetail,
        [t("dukkanBrutAlani")]: item.shopGrossArea,
        [t("dukkanAcikAlanBrutAlani")]: item.shopOpenAreaGrossArea,
        [t("dukkanHemzeminDepoAlani")]: item.shopLevelStorageArea,
        [t("dukkanAltKatDepoAlani")]: item.shopDownstairsStorageArea,
        [t("dukkanSaftAlani")]: item.shopShaftArea,
        [t("atolyeBrutAlan123")]: item.workshopGrossArea123,
        [t("atolyeBrutAlan45")]: item.workshopGrossArea45,
        [t("atolyeBrutAlan67")]: item.workshopGrossArea67,
        [t("atolyeBrutAlan89")]: item.workshopGrossArea89,
        [t("atolyeSaftAlani")]: item.workshopShaftArea,
        [t("tavanYuksekligi")]: item.ceilingHeight,
        [t("odaSayisi")]: item.numberRooms,
        [t("balkoMetrekare")]: item.balconyM2,
        [t("ada")]: item.island,
        [t("parsel")]: item.parsel,
        [t("pafta")]: item.pafta,
        [t("blokNo")]: item.blockNo,
        [t("disKapiNo")]: item.exteriorDoorNo,
        [t("kat")]: item.floor,
        [t("bagimsizBolumNo")]: item.independentNo,
        [t("adres")]: item.address,
        [t("il")]: item.city,
        [t("ulke")]: item.country,
        [t("cepheGenisligim")]: item.facadeWidth,
        [t("brutTavanYuksekligi")]: item.grossCeilingHeight,
        [t("hemzeminDepoAlani")]: item.atLevelWarehouse,
        [t("altKatDepoAlaniBrutAlani")]: item.grossAreaBasementWarehouse,
        [t("depoSaftAlani")]: item.warehouseShaftArea,
        [t("acikYuklemeAlani")]: item.openLoadingArea,
        [t("tahsisliCalismaAlani")]: item.dedicatedWorkspace,
        [t("idariOfisAlani")]: item.administrativeOfficeArea,
        [t("sahanlikAlani")]: item.shelfArea,
        [t("ofisBrutAlani")]: item.officeGrossArea,
        [t("tirGirebilmeDurumu")]: item.truckEntryStatus,
        [t("kreynAltyapisi")]: item.craneInfrastructure,
        [t("tahsisliCatiMekanikAlani")]: item.assignedRoofMechanicalSpace,
        [t("toplamSatilabilirAlan")]: item.totalSalableArea,
        [t("satisBrutMetrekare")]: item.brutM2,
        [t("daireBrutMetrekare")]: item.flatBrutM2,
        [t("katBrutMetrekare")]: item.floorBrutM2,
        [t("netMetrekare")]: item.netM2,
        [t("bahceMetrekare")]: item.gardenM2,
        [t("havuzMetrekare")]: item.poolM2,
        [t("terasMetrekare")]: item.terraceM2,
        [t("otoparkHakki")]: item.parkingRight,
        [t("vergiDurumu")]: item.taxStatus,
        [t("dovizBirimi")]: item.currency,
        [t("metrekareBirimSatisFiyati")]: item.salesPriceM2,
        [t("metrekareBirimKiraBedeli")]: item.salesRentM2,
        [t("metrekareBirimSatisBedeli")]: item.salesPriceM2,
        [t("kdvOrani")]: item.vatRate,
        [t("kdvHaricListeFiyati")]: item.excludingVatListPrice,
        [t("kdvDahilSatisFiyati")]: item.withVatListPrice,
        [t("kdvDahilListeFiyati")]: item.withVatListPrice,
        [t("kiraBedeli")]: item.rentPrice,
        [t("aidat")]: item.dues,
        [t("toplamMetrekareAidat")]: item.duesTotalM2,
        [t("kirasizDonem")]: item.rentFreePeriod,
        [t("malSahibi")]:
          item.inventoryOwnerId?.name + " " + item.inventoryOwnerId?.surname ||
          "",
        [t("not")]: item.note,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  const closeModalHandler = () => {
    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
  };

  const closeTopluVeri = () => {
    setTopluVeriYuklePopup(false);
  };

  const [autoClick, setAutoClick] = useState(false);

  useEffect(() => {
    if (autoClick) {
      if (method === "excel") {
        excelExportAndDownload(inventoryListData);
        setNewMethod("post");
        setAutoClick(false);
      }
    }
  }, [inventoryListData]);

  return (
    <>
      {popup ? (
        <Popup
          closeClick={closeModalHandler}
          count={count}
          type={type}
          page={page}
          isError={isError}
          successClick={deleteInventoryByIds}
        />
      ) : null}

      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="40px 10px"
          data={excelData}
          fileName={`${t("üniteler")}`}
          text={t("excelExportUnite")}
        />
      ) : null}

      {topluVeriYuklePopup && (
        <TopluVeriYuklePopUp
          closeClick={closeTopluVeri}
          text="Toplu Veri Yukle"
        />
      )}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("üniteler")}
          </div>
        </div>
        <div className={style.right}>
          <CheckBox
            getToggleHideAllColumnsProps={
              getToggleHideAllColumnsPropsFromInventory
            }
            allColumns={allInventoryColumns}
          />
          {(user.department === "admin" || user.department === "ceo") && (
            // <NewButton
            //   title={t("topluVeriYukle")}
            //   // onClick={()=>{}}
            // />
            <NewButton
              onClick={() => setTopluVeriYuklePopup(true)}
              title={t("iceVeriYukle")}
            />
          )}
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("veriyiDisaAktar")}
              icon={"exel"}
              onClick={() => {
                setNewMethod("excel");
                setAutoClick(true);
              }}
            />
          )}
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("yeniUniteEkle")}
              onClick={() => {
                navigate("/inventory-create", { replace: true });
                //window.location.replace}('/inventory-create')
              }}
            />
          )}
          {/* <StandartButton1 title={"Sil"} click={() => setPopup(true)} /> */}
          {(user.department === "admin" || user.department === "ceo") && (
            <ProcessButton5
              title={t("cokluIslemler")}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
            />
          )}
          {/* <StandartButton1
            inventoryChangeStatus
            title={"Statü Değiştir"}
            click={changeStatusInventory}
          /> */}
        </div>
      </div>
    </>
  );
};

export default InventoryMenu;
