import React from 'react';
import style from './elements.module.scss';
import { DocumentIcon2 } from '../../assets/icons';
// import { BaseUrl } from '../../ApiConfig';
import { Link } from "react-router-dom"
function ContactUploadFileItem({ document, setDocuments }) {
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  let contactId = window.location.pathname.split('/')[2];
  return (
    <div>
      {document
        ? document.map(item => {
            let name =
              item.fullFileName.split('_')[0] +
              '_' +
              item.fullFileName.split('_')[2];
            let date = new Date(item.createAt);
            let dateNew =
              date.getDate() +
              '-' +
              (date.getMonth() + 1) +
              '-' +
              date.getFullYear();
            //console.log(item);
            return (
              <div className={style.uploadItem} key={item.id}>
                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    onChange={e => {
                      let value = e.target.checked;
                      setDocuments(
                        document.map(sd => {
                          if (sd.id === item.id) {
                            sd.select = value;
                          }
                          return sd;
                        })
                      );
                    }}
                    checked={item.select}
                  />
                </div>
                <div style={{ flex: 5, display: 'flex', alignItems: 'center' }}>
                  <DocumentIcon2 />
                  <Link
                    to={`/public/uploads/${
                      item.fullFileName ? item.fullFileName : ''
                    }`}
                    target='_blank'
                    className={style.fileName}>
                    {name}
                  </Link>
                </div>
                <div style={{ flex: 2 }}>{item.ownerFile}</div>
                <div style={{ flex: 2 }}>
                  {item.isGeneral === false ? (
                    <Link
                      style={{ color: '#5dade2' }}
                      to={`/bid-detail/${
                        item.bids ? item.bids._id : ''
                      }/${contactId}`}>
                      {item.bids ? item.bids.bidName : ''}
                    </Link>
                  ) : item.isGeneral === true ? (
                    'Genel'
                  ) : null}
                </div>
                <div style={{ flex: 2 }}>{dateNew}</div>
                <div style={{ flex: 2 }}>{formatBytes(item.size)}</div>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default ContactUploadFileItem;
