import { NewButton, ProcessButton2 } from "./Buttons";
import style from "./subheader.module.scss";
import axios from "axios";
import Popup from "../LeadElements/Popup";
import { useNavigate } from "react-router";
import { useState, useEffect, useRef } from "react";
import { DownIcon, UpIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
//import { contactChecked, selectKvkk } from "../../redux/actions";
import { BaseUrl } from "../../ApiConfig";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";

import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();
  const checkboxMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      checkboxMenu.current &&
      select &&
      !checkboxMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={() => setSelect(!select)}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            {t("hepsiniSec")}
          </div>
          {allColumns.map((column) => (
            <div key={column.id}>
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />
                {column.Header}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const ContactMenu = ({
  contacData,
  selectedContact,
  contactRows,
  getToggleHideAllColumnsPropsForContacts,
  ContactAllColumns,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [popup, setPopup] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const navigate = useNavigate();

  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const [count, setCount] = useState(0);
  const [type, setType] = useState("");
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState("");

  const deleteCustomerByIds = () => {
    let arrayids = [];

    contactRows.forEach((element) => {
      if (element.isSelected) {
        arrayids.push(element.original._id);
      }
    });

    axios
      .delete(`${BaseUrl}/api/contact/delete/${arrayids}`)
      .then((data) => {
        window.location.reload();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        //TODO refresh page problem after updating data look in to it later on
      })
      .catch((err) => alert(err));

    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
  };

  useEffect(() => {
    if (selectValue === "delete") {
      setPopup(true);
      setType("delete");
      setPage("contact");
      const isElelemtSelected = contactRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(contactRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }
      setSelectValue("default");
    }
  }, [selectValue, contactRows]);

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);

    setHeaders([
      t("kontakDurumu"),
      t("isim"),
      t("soyisim"),
      t("yas"),
      t("cinsiyet"),
      t("telefon"),
      t("gsm"),
      t("email"),
      t("tcno"),
      t("sirket"),
      t("kurumsalUnvan"),
      t("adres"),
      t("il"),
      t("ilce"),
      t("ulke"),
      t("vergiNo"),
      t("kontakKaynagi"),
      t("acilDurumKisisi"),
      t("acilDurumKisisiTelefon"),
      t("kontakTipi"),
      t("kategorisi"),
      t("altKategorisi"),
      t("butce"),
      t("kanal"),
      t("olusturanPersonel"),
      t("veriIslemeGerekcesi"),
    ]);

    const newData = data.map((item) => {
      return {
        [t("kontakDurumu")]: item.contactStatus,
        [t("isim")]: item.name,
        [t("soyisim")]: item.surname,
        [t("yas")]: item.age,
        [t("cinsiyet")]: item.gender,
        [t("telefon")]: item.phone,
        [t("gsm")]: item.gsm,
        [t("email")]: item.email,
        [t("tcno")]: item.tckn,
        [t("sirket")]: item.company,
        [t("kurumsalUnvan")]: item.corporateTitle,
        [t("adres")]: item.address,
        [t("il")]: item.city,
        [t("ilce")]: item.country,
        [t("ulke")]: item.otherCountry,
        [t("vergiNo")]: item.taxNo,
        [t("kontakKaynagi")]: item.contactSource,
        [t("acilDurumKisisi")]: item.emergencyName,
        [t("acilDurumKisisiTelefon")]: item.emergencyPhone,
        [t("kontakTipi")]: item.type,
        [t("kategorisi")]: item.category,
        [t("altKategorisi")]: item.shape,
        [t("butce")]: item.budget,
        [t("currency")]: item.currency,
        [t("olusturanPersonel")]: item.owner,
        [t("veriIslemeGerekcesi")]: item.dataProcessing,
      };
    });

    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  const closeModalHandler = () => {
    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
  };

  return (
    <>
      {popup ? (
        <Popup
          closeClick={closeModalHandler}
          count={count}
          type={type}
          page={page}
          isError={isError}
          successClick={deleteCustomerByIds}
        />
      ) : null}

      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="130px 100px"
          data={excelData}
          fileName="Kontaklar"
          text={t("excelExportKontak")}
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("kontaklar")}
          </div>
        </div>
        <div className={style.right}>
          <CheckBox
            getToggleHideAllColumnsProps={
              getToggleHideAllColumnsPropsForContacts
            }
            allColumns={ContactAllColumns}
          />
          {/* {(user.department === "admin" || user.department === "ceo") && (
            <NewButton title={t("topluVeriYukle")} />
          )} */}
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("veriyiDisaAktar")}
              icon={"exel"}
              onClick={() => {
                excelExportAndDownload(contacData);
              }}
            />
          )}
          {(user.department === "admin" ||
            user.department === "ceo" ||
            user.department === "gm" ||
            user.department === "rd" ||
            user.department === "sd" ||
            user.department === "sm" ||
            user.department === "ss" ||
            user.department === "sa") && (
            <NewButton
              title={t("yeniKontakEkle")}
              onClick={() => {
                navigate("/create-contact", { replace: true });
                //window.location.replace('/create-contact')
              }}
            />
          )}
          {(user.department === "admin" ||
            user.department === "ceo" ||
            user.department === "gm" ||
            user.department === "rd" ||
            user.department === "sd" ||
            user.department === "sm") && (
            <ProcessButton2
              selectValue={selectValue}
              setSelectValue={setSelectValue}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactMenu;
