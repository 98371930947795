import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllProjects = async (jwt) => {
  const request = await axios.get(`${BaseUrl}/api/project/getAll/`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  const payload = {
    data: request.data.project,
  };
  return payload;
};

export const getAProject = async (jwt, id) => {
  const request = await axios.post(
    `${BaseUrl}/api/project/get-project`,
    {
      data: id,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const deleteAProject = async (jwt, id) => {
  //axios delete request with body  and headeer
  const request = await axios.delete(`${BaseUrl}/api/project/delete`, {
    data: {
      ids: id,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};

export const updateAProject = async (jwt, id, project) => {
  const request = await axios.put(
    `${BaseUrl}/api/project/update-project/${id}`,
    {
      data: {
        project,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};
