import style from "./contactForm.module.scss";
// import jwt_decode from "jwt-decode";
import Popup from "../../containers/CreateLead/LeadPopup";
import { SubHeader } from "../../components";
import { useSelector } from "react-redux";
import {
  GSMInput,
  NumberInput,
  TextInput,
  UserInput,
  DropInput,
  Textarea,
  DocumentInput,
  DropInput2,
} from "../../components/Forms/Inputs";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { BaseUrl } from "../../ApiConfig";
import { getAllProjects } from "../../lib/projectApi";
import ProjectSelection from "../../components/ui/projectSelection/ProjectSelection";

import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user.userData);
  const { contactSourceTR, contactSourceEN } = useSelector(
    (state) => state.system.contactSource
  );

  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const [contactSource, setContactSource] = useState("");
  const [contactSourceText, setContactSourceText] = useState("");
  const navigate = useNavigate();
  const [owner, setOwner] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [shape, setShape] = useState("");
  const [budget, setBudget] = useState("");
  const [currency, setCurrency] = useState("");
  const [gender, setGender] = useState("");
  const [gsm, setGsm] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [otherCountry, setOtherCountry] = useState("");
  const [age, setAge] = useState("");
  const [dataProcessing, setDropValue] = useState();
  const [tckn, setTckn] = useState("");
  const [taxNo, setTaxNo] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [emergencyName, setEmergencyName] = useState("");
  const [corporateTitle, setCorporateTitle] = useState("");
  const [documentContactKvkk, setDocumentContactKvkk] = useState();
  const [numberOfRooms, setNumberOfRooms] = useState("");
  const [view, setView] = useState("");
  const [popup, setPopup] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  const [inputCheck, setInputCheck] = useState(false);
  const [wClick, setwClick] = useState("");
  const [nameChecker, setNameChecker] = useState(false);
  const [kvkFileCheck, setKvkFileCheck] = useState(false);
  // const [budgetCheck, setBudgetCheck] = useState(false);
  // const [currencyCheck, setCurrencyCheck] = useState(false);
  const [contactSourceCheck, setContactSourceCheck] = useState(false);
  const [contactTypeCheck, setContactTypeCheck] = useState(false);
  const [contactCategoryCheck, setContactCategoryCheck] = useState(false);
  const [contactStatus, setContactStatus] = useState("aktif");
  const [projectCheck, setProjectCheck] = useState(false);

  const _onClick = () => {
    let formData = new FormData();
    formData.append("documentContactKvkk", documentContactKvkk);
    formData.append("owner", owner);
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("company", company);
    formData.append("type", type);
    formData.append("category", category);
    formData.append("shape", shape);
    formData.append("budget", budget);
    formData.append("currency", currency);
    formData.append("gender", gender);
    formData.append("gsm", gsm);
    formData.append("address", address);
    formData.append("contactSource", contactSource);
    formData.append("contactSourceText", contactSourceText);
    formData.append("city", city);
    formData.append("country", country);
    formData.append("otherCountry", otherCountry);
    formData.append("age", age);
    formData.append("dataProcessing", dataProcessing);
    formData.append("tckn", tckn);
    formData.append("taxNo", taxNo);
    formData.append("numberOfRooms", numberOfRooms);
    formData.append("view", view);
    formData.append("emergencyPhone", emergencyPhone);
    formData.append("emergencyName", emergencyName);
    formData.append("corporateTitle", corporateTitle);
    formData.append("contactStatus", contactStatus);
    formData.append("associatedProject", checkedCheckboxes);
    const userType = user.userType;

    axios({
      method: "post",
      url: `${BaseUrl}/api/contact/add/${userType}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        if (wClick === "1") {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (wClick === "2") {
          setTimeout(() => {
            navigate("/contact-list", { replace: true });
          }, 2000);
        } else {
          setTimeout(() => {
            navigate("/contact-list", { replace: true });
          }, 1000);
        }
      })
      .catch((err) => {
        //console.log(err.response);
        if (
          err?.response?.data?.message ===
          "Email, gsm veya TcNo kayıtlı başka bir kullanıcı bulunmakta."
        ) {
          setPopup(true);
        }
        if (err?.response?.data?.message === "Geçerli bir email giriniz.") {
          setEmailPopup(true);
        }
      });
  };

  useEffect(() => {
    // const token = user.token;
    // const decoded = jwt_decode(token);
    setOwner(user.name + " " + user.surname);
  }, [user.name, user.surname, user.token, user.userType]);
  useEffect(() => {
    if (!popup && !inputCheck) {
    }
  });

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        //console.log(res.data);
        setAllProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);
  const dropData = [
    { value: "1", name: t("veriSahibi") },
    { value: "2", name: t("birIsAkdi") },
    { value: "3", name: t("veriSorumluluk") },
    { value: "4", name: t("acikRizaAlinmasi") },
    { value: "5", name: t("kapsamDisi") },
  ];

  const submitForm = () => {
    if (checkedCheckboxes.length < 1) {
      return setProjectCheck(true);
    }
    if (name.length <= 0 || surname.length <= 0) {
      return setNameChecker(true); //name and surname checker
    }
    if (
      email.length <= 0 &&
      phone.length <= 0 &&
      gsm.length <= 0 &&
      contactStatus === "aktif"
    ) {
      return setInputCheck(true); //email and phone checker
    }
    if (
      ((phone.length <= 12 && phone.length > 0) ||
        (gsm.length <= 12 && gsm.length > 0)) &&
      contactStatus === "aktif"
    ) {
      return setInputCheck(true);
    }
    if (email && email.indexOf("@") === -1 && contactStatus === "aktif") {
      return setEmailPopup(true); //email checker
    }
    if (!contactSource) {
      return setContactSourceCheck(true); //contact source checker
    }
    if (!type) {
      return setContactTypeCheck(true); //contact type checker
    }
    if (!category) {
      return setContactCategoryCheck(true);
    }
    /*
    if (!budget) {
      return setBudgetCheck(true);
    }
    if (!currency){
      return setCurrencyCheck(true);
    }
    */
    if (
      (dataProcessing === "1" || dataProcessing === "4") &&
      (documentContactKvkk === "" ||
        documentContactKvkk === undefined ||
        documentContactKvkk === null)
    ) {
      return setKvkFileCheck(true);
    }

    return _onClick(); // success
  };

  return (
    <>
      <SubHeader
        createContact
        setwClick={setwClick}
        form
        onClick={submitForm}
        path="/contact-list"
        newButtonName={t("kaydet")}
        // buttonName={"Kaydet ve Yeni Ekle"}
      />
      <div className={style.editContactContent}>
        <div className={style.editContact}>
          <div className={style.leftContactEdit}>
            <DropInput2
              data={[
                { name: t("aktif"), value: "aktif" },
                { name: t("pasif"), value: "pasif" },
              ]}
              setDropValue={(text) => {
                console.log(text);
                setContactStatus(text);
              }}
              placeholder={t("kontakDurumu")}
              title={t("kontakDurumu")}
              isDefault={contactStatus}
            />
            <TextInput
              value={name}
              onChange={(text) => setName(text)}
              title={t("isim")}
              placeholder={t("isim")}
            />
            <TextInput
              value={surname}
              onChange={(text) => setSurname(text)}
              placeholder={t("soyisim")}
              title={t("soyisim")}
            />
            <ProjectSelection
              title={t("ilgilendigiProje")}
              placeholder={
                (checkedCheckboxes.length > 0
                  ? `(${checkedCheckboxes.length}) `
                  : "") + t("projeSeciniz")
              }
              allProjects={allProjects}
              setCheckedCheckboxes={setCheckedCheckboxes}
              checkedCheckboxes={checkedCheckboxes}
            />
            <TextInput
              value={age}
              onChange={(text) => setAge(text)}
              placeholder={t("yas")}
              title={t("yas")}
            />
            <DropInput
              data={[
                { name: t("erkek"), value: "Erkek" },
                { name: t("kadin"), value: "Kadın" },
              ]}
              setDropValue={(text) => setGender(text)}
              placeholder={t("cinsiyet")}
              title={t("cinsiyet")}
              styleType={2}
            />
            <GSMInput
              value={gsm}
              onChange={(Number) => setGsm(Number)}
              title={t("gsm")}
              placeholder={t("gsm")}
              disabled={contactStatus === "aktif" ? false : true}
            />
            <GSMInput
              value={phone}
              onChange={(Number) => setPhone(Number)}
              title={t("telefon")}
              placeholder={t("telefon")}
              disabled={contactStatus === "aktif" ? false : true}
            />
            <TextInput
              value={email}
              onChange={(text) => setEmail(text)}
              title={t("email")}
              placeholder={t("email")}
              maxLength={"50"}
              disabled={contactStatus === "aktif" ? false : true}
            />
            <TextInput
              value={tckn}
              onChange={(text) => setTckn(text)}
              title={t("tcno")}
              placeholder={t("tcno")}
            />
            <TextInput
              // value={tckn}
              onChange={(text) => {}}
              title={t("pasaportNumarasi")}
              placeholder={t("pasaportNumarasi")}
            />
            <TextInput
              value={company}
              onChange={(text) => setCompany(text)}
              title={t("sirket")}
              placeholder={t("sirket")}
            />
            <TextInput
              value={corporateTitle}
              onChange={(text) => setCorporateTitle(text)}
              title={t("kurumsalUnvan")}
              placeholder={t("kurumsalUnvan")}
            />
            <Textarea
              title={t("adres")}
              value={address}
              placeholder={t("adres")}
              onChange={(text) => setAddress(text)}
            />
            <NumberInput
              decimalScale={0}
              title={t("odaSayisi")}
              value={numberOfRooms}
              placeholder={t("odaSayisi")}
              onChange={(text) => setNumberOfRooms(text)}
            />
            <TextInput
              value={view}
              onChange={(text) => setView(text)}
              title={t("manzara")}
              placeholder={t("manzara")}
            />
          </div>
          <div className={style.rightContactEdit}>
            <TextInput
              value={country}
              onChange={(text) => setCountry(text)}
              title={t("il")}
              placeholder={t("il")}
            />
            <TextInput
              value={city}
              onChange={(text) => setCity(text)}
              title={t("ilce")}
              placeholder={t("ilce")}
            />
            {/* {userType ? (
              userType === "Yurt Dışı" ? ( */}
            <TextInput
              value={otherCountry}
              onChange={(text) => setOtherCountry(text)}
              title={t("ulke")}
              placeholder={t("ulke")}
            />
            {/* ) : null
            ) : null} */}
            <TextInput
              value={taxNo}
              onChange={(text) => setTaxNo(text)}
              title={t("vergiNo")}
              placeholder={t("vergiNo")}
            />
            {/* i18n.language */}
            {/* contactSourceData */}

            <DropInput
              data={
                i18n.language === "tr"
                  ? contactSourceTR
                  : i18n.language === "en"
                  ? contactSourceEN
                  : null
              }
              setDropValue={(text) => {
                setContactSource(text);
              }}
              placeholder={t("kontakKaynagi")}
              title={t("kontakKaynagi")}
              styleType={2}
            />

            {contactSource.length ? (
              <TextInput
                // value={lead.emergencyName}
                onChange={(text) => {
                  setContactSourceText(text);
                }}
                title={
                  i18n.language === "tr"
                    ? contactSourceTR.find(
                        (item) => item.value === contactSource
                      )?.name
                    : i18n.language === "en"
                    ? contactSourceEN.find(
                        (item) => item.value === contactSource
                      )?.name
                    : null
                }
              />
            ) : null}
            <TextInput
              value={emergencyName}
              onChange={(text) => setEmergencyName(text)}
              title={t("acilDurumKisisi")}
              placeholder={t("acilDurumKisisi")}
            />
            <GSMInput
              value={emergencyPhone}
              onChange={(text) => setEmergencyPhone(text)}
              title={t("acilDurumKisisiTelefon")}
              placeholder={t("acilDurumKisisiTelefon")}
            />
            <DropInput
              data={[
                { name: t("bireysel"), value: "Bireysel" },
                { name: t("kurumsal"), value: "Kurumsal" },
              ]}
              setDropValue={(text) => setType(text)}
              placeholder={t("kontakTipi")}
              title={t("kontakTipi")}
              styleType={2}
            />
            <DropInput
              data={[
                { name: t("musteri"), value: "Müşteri" },
                { name: t("isOrtagi"), value: "İş Ortağı" },
                { name: t("tedarikci"), value: "Tedarikçi" },
                { name: t("resmiGorevli"), value: "Resmi Görevli" },
                { name: t("diger"), value: "Diğer" },
              ]}
              setDropValue={(text) => setCategory(text)}
              placeholder={t("kategorisi")}
              title={t("kategorisi")}
              styleType={2}
            />
            {category === "Müşteri" ? (
              <DropInput
                data={[
                  { name: t("alici"), value: "Alıcı" },
                  { name: t("satici"), value: "Satıcı" },
                  { name: t("potensiyelAlici"), value: "Potansiyel Alıcı" },
                  { name: t("kiraci"), value: "Kiracı" },
                  { name: t("kiralayan"), value: "Kiralayan" },
                  { name: t("potansiyalKiraci"), value: "Potansiyel Kiracı" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                setDropValue={(text) => setShape(text)}
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
                styleType={2}
              />
            ) : category === "İş Ortağı" ? (
              <DropInput
                data={[
                  { name: t("acente"), value: "Acente" },
                  { name: t("projeOfisi"), value: "Proje Ofisi" },
                  { name: t("mimarlikOfisi"), value: "Mimarlık Ofisi" },
                  { name: t("muteahhit"), value: "Müteahhit" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                setDropValue={(text) => setShape(text)}
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
                styleType={2}
              />
            ) : category === "Resmi Görevli" ? (
              <DropInput
                data={[
                  {
                    name: t("tapuDairesiYetkilisi"),
                    value: "Tapu Dairesi Yetkilisi",
                  },
                  { name: t("belediyeYetkilisi"), value: "Belediye Yetkilisi" },
                  { name: t("kontrolor"), value: "Kontrolör" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                setDropValue={(text) => setShape(text)}
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
                styleType={2}
              />
            ) : (
              <DropInput
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
                styleType={2}
              />
            )}
            <DropInput
              data={[
                { name: "500K", value: 500000 },
                { name: "750K", value: 750000 },
                { name: "1M", value: 1000000 },
                { name: "1.25M", value: 1250000 },
                { name: "1.5M", value: 1500000 },
                { name: "1.75M", value: 1750000 },
                { name: "2M", value: 2000000 },
                { name: "2.25M", value: 2250000 },
                { name: "2.5M", value: 2500000 },
                { name: "2.75M", value: 2750000 },
                { name: "3M", value: 3000000 },
                { name: "3.25M", value: 3250000 },
                { name: "3.5M", value: 3500000 },
                { name: "3.75M", value: 3750000 },
                { name: "4M", value: 4000000 },
                { name: "4.25M", value: 4250000 },
                { name: "4.5M", value: 4500000 },
                { name: "4.75M", value: 4750000 },
                { name: "5M", value: 5000000 },
                { name: "6M", value: 6000000 },
                { name: "7M", value: 7000000 },
                { name: "8M", value: 8000000 },
                { name: "9M", value: 9000000 },
                { name: "10M", value: 10000000 },
                { name: "20M", value: 20000000 },
                { name: "30M", value: 30000000 },
                { name: "40M", value: 40000000 },
                { name: "50M", value: 50000000 },
              ]}
              setDropValue={(text) => setBudget(text)}
              placeholder={t("butce")}
              title={t("butce")}
              styleType={2}
            />

            <DropInput
              data={[
                { name: "TRY", value: "TRY" },
                { name: "USD", value: "USD" },
                { name: "EUR", value: "EUR" },
                { name: "GBP", value: "GBP" },
              ]}
              setDropValue={(text) => setCurrency(text)}
              placeholder={t("currency")}
              title={t("currency")}
              styleType={2}
            />

            <TextInput
              disabled
              // value={channel}
              title={t("kanal")}
              placeholder={t("manüel")}
              // onChange={(text) => setChannel(text)}
            />
            <UserInput
              value={owner}
              onChange={(text) => setOwner(text)}
              title={t("olusturanPersonel")}
              placeholder={owner}
            />
            <div style={{ marginLeft: "-1.8rem" }}>
              <DropInput
                placeholder={t("veriIslemeGerekcesi")}
                title={t("veriIslemeGerekcesiTitle")}
                setDropValue={setDropValue}
                dropValue={dataProcessing}
                data={dropData}
              />
            </div>
            {dataProcessing === "1" || dataProcessing === "4" ? (
              <div style={{ marginLeft: "-1.8rem" }}>
                <DocumentInput
                  setDocumentFile={setDocumentContactKvkk}
                  documentFile={documentContactKvkk}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {projectCheck ? (
        <Popup
          errorText={t("kontakIlgilendigiProje")}
          title={t("yeniKontakPopupTitle")}
          closeClick={() => setProjectCheck(false)}
        />
      ) : null}
      {popup ? (
        <Popup
          errorText={t("yeniKontakPopupEmailGsmExisting")}
          title={t("yeniKontakPopupTitle")}
          closeClick={() => setPopup(false)}
        />
      ) : null}

      {emailPopup ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupEmail")}
          closeClick={() => setEmailPopup(false)}
        />
      ) : null}

      {inputCheck ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupEmailPhoneGSM")}
          closeClick={() => setInputCheck(false)}
        />
      ) : null}

      {nameChecker ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupNameSurname")}
          closeClick={() => setNameChecker(false)}
        />
      ) : null}

      {contactSourceCheck ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupKontakKaynagi")}
          closeClick={() => setContactSourceCheck(false)}
        />
      ) : null}

      {contactTypeCheck ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupKontakType")}
          closeClick={() => setContactTypeCheck(false)}
        />
      ) : null}

      {contactCategoryCheck ? (
        <Popup
          title={t("yeniKontakPopupTitle")}
          errorText={t("yeniKontakPopupKontakCategory")}
          closeClick={() => setContactCategoryCheck(false)}
        />
      ) : null}

      {kvkFileCheck ? (
        <Popup
          errorText={t("yeniKontakPopupKVK")}
          title={t("yeniKontakPopupTitle")}
          closeClick={() => setKvkFileCheck(false)}
        />
      ) : null}

      {/*
      {budgetCheck ? (
        <Popup
          errorText={t("kontakBudget")}
          title={t("kontakBudget")}
          closeClick={() => setBudgetCheck(false)}
        />
      ) : null}

      {currencyCheck ? (
        <Popup
          errorText={t("kontakCurrency")}
          title={t("kontakCurrency")}
          closeClick={() => setCurrencyCheck(false)}
        />
      ) : null}
      */}
    </>
  );
};

export default ContactForm;
