import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllInventory = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = "get",
  data = {}
) => {
  let request;
  if (method === "get") {
    request = await axios.get(
      `${BaseUrl}/api/project/getInventory?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === "post") {
    request = await axios.post(
      `${BaseUrl}/api/project/queryInventory?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === "excel") {
    request = await axios.post(
      `${BaseUrl}/api/project/queryInventory?pageNumber=1&pageSize=1000000`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }
  const payload = {
    data: request.data.inventory,
    pageCount: request.data.pageCount,
  };
  return payload;
};

export const deleteAInventory = async (jwt, inventoryId) => {
  const request = await axios.delete(`${BaseUrl}/api/project/deleteInventory`, {
    data: {
      ids: inventoryId,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};
