import style from './contactForm.module.scss';
import { useTranslation } from "react-i18next";
const Popup = ({ closeClick, text }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <h1>{text}</h1>
          <label>
            <button onClick={closeClick} className={style.successButtonPopup}>
              {t("tamam")}
            </button>
          </label>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default Popup;
