import style from "./login.module.scss";
import { useEffect, useState } from "react";
import logo from "../../assets/img/logo2.png";
import loginlogo from "../../assets/img/loginlogo.png";
import bannerLogo from "../../assets/img/loginbanner.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUserThunk } from "../../redux/slice/userSlice";
import Loader from "../../components/ui/Loader/Loader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [error, setError] = useState();
  const [success, ,] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const {
    successfulLogin,
    loginSuccessMessage,
    loginErrorMessage,
    isLoginLoading,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!successfulLogin) {
      setError(loginErrorMessage);
    }
  }, [successfulLogin, loginErrorMessage, loginSuccessMessage]);

  const _onSubmit = async (e) => {
    e.preventDefault();
    const body = { email, password };
    if (body.email.length === 0 && body.password.length === 0) {
      setError("Bütün alanları doldurunuz.");
    } else {
      dispatch(loginUserThunk(body));
    }
  };

  return (
    <div className={style.innerContainer}>
      <Loader isLoading={isLoginLoading} />
      <div className={style.formcontent}>
        <img src={logo} alt="" style={{ height: "60px" }} />
        <form className={style.forms} onSubmit={_onSubmit}>
          <h1>Giriş Yap</h1>
          <p>
            Aremas sistemine bu panel üzerinden giriş <br /> yapabilirsiniz.
          </p>
          <label>
            <h1>E-Mail</h1>
            <input
              type="email"
              name="email"
              autoComplete="on"
              placeholder="E-Mail giriniz"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label>
            <h1>Şifre</h1>
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Şifrenizi giriniz"
              autoComplete="on"
              onChange={(e) => setPass(e.target.value)}
            />
          </label>

          <div className={style.checkbox}>
            <label>
              <input
                type="checkbox"
                name="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <h1>Beni Hatırla</h1>
            </label>
            <Link to="/reset-password">Şifreni mi unuttun?</Link>
          </div>
          {error ? <h3 className={style.error}>{error}</h3> : null}
          {success ? <h4 className={style.success}>{success}</h4> : null}

          <button type="submit">Giriş Yap</button>
        </form>
        <h5>{new Date().getFullYear()}© Aremas A.Ş </h5>
      </div>
      <div
        className={style.banner}
        style={{ background: `url(${bannerLogo})` }}
      >
        <img src={loginlogo} alt="" />
      </div>
    </div>
  );
};

export default Login;
