import style from "./notedetail.module.scss";
import { SubHeader, SideNav, NoteLog } from "../../components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BaseUrl } from "../../ApiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NoteDetail = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/note/findOne/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setData(res.data.note);
      })
      .catch((err) => {
        console.log(err);
        navigate("/note-list", { replace: true });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={style.leadDetail}>
      {data ? (
        <div>
          <SubHeader noteDetail noteData={data} />

          <div className={style.content}>
            <SideNav noteDetail="1" />
            <div className={style.info}>
              <div className={style.leadCard}>
                <div className={style.column}>
                  {user.department === "admin" && <div className={style.card}>
                    <h2>ID</h2>
                    <p>{data._id ? data._id : ""} </p>
                  </div>}
                  <div className={style.card}>
                    <h2>{t("kontak")}</h2>
                    <h1>
                      <Link
                        to={`/contact-detail/${
                          data.contactId ? data.contactId._id : ""
                        }`}
                        style={{ color: "#5DADE2" }}
                      >
                        {data.contactId ? data.contactId.name : ""}{" "}
                        {data.contactId ? data.contactId.surname : ""}
                      </Link>
                    </h1>
                  </div>
                  <div className={style.card}>
                    <h2>{t("olusturanPersonel")}</h2>
                    <h1>{data.noteOwner}</h1>
                  </div>

                  <div className={style.card}>
                    <h2>{t("iliskiliAktivite")}</h2>
                    {data.isGeneral === false ? (
                      <h1>
                        <Link
                          to={`/bid-detail/${data.bids ? data.bids._id : ""}/${
                            data.contactId ? data.contactId._id : ""
                          }`}
                          style={{ color: "#5DADE2" }}
                        >
                          {data.bids ? data.bids.bidName : ""}
                        </Link>
                      </h1>
                    ) : (
                      <h1>Genel</h1>
                    )}
                  </div>
                  {/* <div className={style.card}>
                    <h2>Görüşme Kanalı</h2>
                    <h1>{data.meetingShape}</h1>
                  </div> */}
                  <div className={style.card}>
                    <h2>{t("iliskiliProje")}</h2>
                    {data.isGeneral === true ? (
                      ""
                    ) : (
                      <h1>{data.bids ? data.bids.projeName : ""}</h1>
                    )}
                  </div>
                  <div className={style.card}>
                    <h2>{t("urunTipi")}</h2>
                    {data.isGeneral === true ? (
                      ""
                    ) : (
                      <h1>
                        <Link
                          to={`/inventory-detail/${
                            data.bids ? data.bids.inventoryId._id : ""
                          }`}
                          style={{ color: "#5DADE2" }}
                        >
                          {data.bids ? data.bids.inventoryName : ""}
                        </Link>
                      </h1>
                    )}
                  </div>
                  <div className={style.card}>
                    <h2>{t("baslik")}</h2>
                    <h1>{data.title ? data.title : ""}</h1>
                  </div>

                  <div className={style.card}>
                    <h2>{t("olusturulmaTarihi")}</h2>
                    <h1>{data.meetingDate}</h1>
                  </div>
                </div>
                <div className={style.column}>
                  <div style={{ alignItems: "start", height:"auto" }} className={style.card}>
                    <h2>{t("not")}</h2>
                    <h1>{data.note ? data.note : ""}</h1>
                  </div>
                </div>
                
              </div>
              <NoteLog 
                noteId={id}
                user={user}
              />
              <div style={{ marginBottom: '5em' }}></div>
            </div>

          </div>

        </div>

      ) : null}

    </div>
  );
};

export default NoteDetail;
