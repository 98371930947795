import React from "react";
import style from "./bidedit.module.scss";
import { useParams } from "react-router";
import { SubHeader } from "../../components";
import { useSelector } from "react-redux";
import {
  TextInput,
  DateInput,
  DocumentInput2,
  DropInput2,
  DateAndInput,
  DisabledNumberInput2,
  DisabledNumberInput,
  NumberInput,
  DropInputJSONProject,
  DropInput3,
  DateAndInpuDownPayment,
  NumberOfBillsInout,
  UserInput,
} from "../../components/Forms/Inputs";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiConfig";

import { useTranslation } from "react-i18next";

function BidEdit({ selectBidType, bid, setBid }) {
  const { t, i18n } = useTranslation();
  const { bankCreditTR, bankCreditEN } = useSelector(
    (state) => state.system.bankCredit
  );
  const { bidStageTR, bidStageEN } = useSelector(
    (state) => state.system.bidStage
  );
  const { failedStageNoteTR, failedStageNoteEN } = useSelector(
    (state) => state.system.failedStageNote
  );

  const { meetingShapeTR, meetingShapeEN } = useSelector(
    (state) => state.system.meetingShape
  );

  const navigate = useNavigate();
  const [inventoryId, setInventoryId] = useState();
  const user = useSelector((state) => state.user.userData);
  const { id, contactId } = useParams();
  const [userType, setUserType] = useState("");
  const [projects, setProjects] = useState();
  const [inventory, setInventory] = useState();
  const [selectedVatRate, setSelectedVatRate] = useState();
  const [selectedListPrice, setSelectedListPrice] = useState();
  const [selectedInventoryOwner, setSelectedInventoryOwner] = useState();
  const [selectedInventoryCurrency, setSelectedInventoryCurrency] = useState();
  const [selectedListRentPrice, setSelectedListRentPrice] = useState();
  const [fin, setFin] = useState(0);
  const [paymentPlanFile, setPaymentPlanFile] = useState();
  const [isSuccesFullClosing, setIsSuccesFullClosing] = useState();
  const [successfullClosingContactId, setSuccessfullClosingContactId] =
    useState();
  const [inventoryController, setInventoryController] = useState();
  useEffect(() => {
    if (
      isSuccesFullClosing === true &&
      successfullClosingContactId === contactId
    ) {
      setInventoryController(false);
    }
    if (
      isSuccesFullClosing === true &&
      successfullClosingContactId !== contactId
    ) {
      setInventoryController(true);
    }
  }, [successfullClosingContactId, isSuccesFullClosing, contactId]);
  const [bids, setBids] = useState({
    bidName: "",
    bidShape: `${selectBidType}`,
    rentalPeriod: "",
    failedStageNote: "",
    paymentPlanFileName: "",
    //inventory
    currency: "",
    bidAmount: null,
    bidAmountWithVat: 0,
    discountRate: 0,
    projeName: "",
    inventoryName: "",
    ownerInventory: `${selectedInventoryOwner}`,
    //----
    bidStage: "",
    estimateDate: "",
    contractStartDate: "",
    unitRentalPrice: "",
    rentIncrease: "",
    securityDeposit: "",

    carPark: "",
    operatingExpenses: "",
    paymentPlan: "",
    closingDate: "",
    paymentType: "",
    decoration: "",
    advancePayment: "", //kapora
    advancePaymentDate: "",
    downPaymnet: "", //peşinat
    downPaymnetDate: "",
    termDownPaymnet: "",
    termDownPaymnetWithVat: 0,
    termDownPaymnetDate: "",
    bankCredit: "",
    bankCreditDate: "",
    bankToBeUsed: "", //kullanılacak banka
    billAmount: "", //senet tutaru
    billLength: "", //senet adedi
    otherExpenses: "", // teslime ilişkin diğer masraflar
    interestAmount: "", //faiz miktarı yıllık faiz oranı
    deliveryTime: "", //teslim tarihi
    userType: user.userType,
    ownerBid: user.name,
    contactId: "",
  });
  const [fin2, setFin2] = useState(0);
  /*
  const current = new Date();
  const _date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  */
  const [numberOfBils, setNumberOfBilss] = useState({
    name: "React",
    total: 0,
    totalInput: "",
    show: false,
  });

  useEffect(() => {
    var date = new Date();
    let fullDate =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    setBids({ ...bids, closingDate: fullDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids.bidStage]);
  const bidType = bids.bidShape;
  useEffect(() => {
    if (bids.bidShape === "Kiralama") {
      const kdv = () => {
        let rate = selectedListRentPrice
          ? selectedListRentPrice - bids.bidAmount
          : bids.inventoryId.rentPrice - bids.bidAmount;
        let total =
          (rate /
            (selectedListRentPrice
              ? selectedListRentPrice
              : bids.inventoryId.rentPrice)) *
          100;
        return setBids({
          ...bids,
          discountRate: total ? total.toFixed(0) : 0,
        });
      };
      kdv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids.bidAmount]);
  const kdv = () => {
    let rate1 = selectedVatRate ? selectedVatRate : 0;
    let rate = selectedListPrice - bids.bidAmount;
    let total = (rate / selectedListPrice) * 100;
    let kdvNumber = Number(bids.bidAmount) * (rate1 / 100);
    let total1 = Number(bids.bidAmount) + kdvNumber;
    return setBids({
      ...bids,
      bidAmountWithVat: parseFloat(total1),
      discountRate: total ? total.toFixed(0) : 0,
    });
  };
  useEffect(() => {
    if (fin2 > 0) {
      kdv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids.bidAmount]);

  useEffect(() => {
    setUserType(user.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/bid/findOne/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setBids(res.data.bid);
        setSelectedVatRate(res.data.bid.inventoryId.vatRate);
        setSelectedListPrice(res.data.bid.inventoryId.excludingVatListPrice);
        setIsSuccesFullClosing(res.data.bid.inventoryId.isSuccessfullClosing);
        setSuccessfullClosingContactId(
          res.data.bid.inventoryId.successfullClosingContactId
        );
        //console.log(res.data.bid.inventoryId);
      })
      .catch((err) => {
        console.log(err);
        return navigate("/bid-list", { replace: true });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    setBids((prev) => {
      return { ...prev, inventoryId: inventoryId };
    });
  }, [inventoryId]);
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getAllOffer`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setProjects(res.data.project);
      })
      .catch((error) => console.log(error.response));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, fin]);
  useEffect(() => {
    //tam dayaklık bölüm
    if (bids.advancePayment === undefined) {
      setBids({ ...bids, advancePayment: "" });
    } else if (bids.downPaymnet === undefined) {
      setBids({ ...bids, downPaymnet: "" });
    } else if (bids.termDownPaymnet === undefined) {
      setBids({ ...bids, termDownPaymnet: "" });
    } else if (bids.bankCredit === undefined) {
      setBids({ ...bids, bankCredit: "" });
    } else if (bids.billAmount === undefined) {
      setBids({ ...bids, billAmount: "" });
    } else if (bids.bankCredit === undefined) {
      setBids({ ...bids, bankCredit: "" });
    } else if (bids.otherExpenses === undefined) {
      setBids({ ...bids, otherExpenses: "" });
    } else if (bids.bidAmount === undefined) {
      setBids({ ...bids, bidAmount: null });
    }
  }, [bids]);
  const onSubmit = () => {
    let formData = new FormData();
    formData.append("documentPaymentPlan", paymentPlanFile);
    formData.append("body", JSON.stringify(bids));
    axios
      .put(
        `${BaseUrl}/api/bid/edit/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          navigate(`/contact-detail/${contactId}`, { replace: true });
        }, 2000);
        //console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };


  const currencyConvert = (value) => {
    if (value === "TRY") {
      return " ₺";
    } else if (value === "USD") {
      return " $";
    } else if (value === "EUR") {
      return " €";
    } else if (value === "GBP") {
      return " £";
    } else if (value === "RUB") {
      return " ₽";
    } else {
      return " ₺";
    }
  };
  const currentcyNameConverter = (value) => {
    if (value === "TRY") {
      return t("try");
    } else if (value === "USD") {
      return t("usd");
    } else if (value === "EUR") {
      return t("eur");
    } else if (value === "GBP") {
      return t("gbp");
    } else if (value === "RUB") {
      return t("rub");
    } else {
      return t("try");
    }
  };

  return (
    <div>
      <SubHeader
        editBid
        form
        path={`/contact-detail/${contactId}`}
        onClick={onSubmit}
        newButtonName={t("degistir")}
      />
      <div className={style.editBidMain}>
        <div className={style.editBidContent}>
          <div className={style.bidForm}>
            <div className={style.leftBidForm}>
              <TextInput
                disabled
                title={t("musteriAdi")}
                value={bids.contactId?`${bids.contactId.name} ${bids.contactId.surname}`:""}
                placeholder={t("musteriAdi")}
                onChange={(text) =>
                  setBids((prev) => {
                    return { ...prev, contactId: text };
                  })
                }
              />
              <DropInput2
                disabled
                data={[
                  { name: t("satisDepertmani"), value: "Satış" },
                  { name: t("kiralamaDepartmani"), value: "Kiralama" },
                ]}
                isDefault={
                  bids.bidShape === "" ? "asda" : bids.bidShape
                }
                value={bids.bidShape}
                title={t("aktiviteTipi")}
                placeholder={t("aktiviteTipiSeciniz")}
                styleType={2}
              />
              <TextInput
                //disabled
                title={t("aktiviteIsmi")}
                value={bids.bidName}
                placeholder={t("aktiviteIsmi")}
                onChange={(text) =>
                  setBids((prev) => {
                    return { ...prev, bidName: text };
                  })
                }
              />
              <UserInput
                value={bids.ownerBid}
                onChange={(text) => {}}
                title={t("olusturanPersonel")}
                placeholder={bids.ownerBid}
              />

              <DropInput2
                title={t("gorusmeKanali")}
                placeholder={t("gorusmeKanali")}
                value={bids.meetingShape}
                data={
                  i18n.language === "tr"
                    ? meetingShapeTR
                    : i18n.language === "en"
                    ? meetingShapeEN
                    : null
                }
                isDefault={bids.meetingShape === "" ? "asda" : bids.meetingShape}
                setDropValue={(text) =>
                  setBids((prev) => {
                    return { ...prev, meetingShape: text };
                  })
                  }
              />


              <DropInput3
                title={t("iliskiliProje")}
                click={setFin}
                fin={fin}
                placeholder={
                  bids.projeName.length
                    ? bids.projeName
                    : "İlişkili Proje Seçiniz"
                }
                setBid={setBids}
                disabled={inventoryController}
                data={
                  projects
                    ? projects.map((item, index) => {
                        return {
                          name: item.projectName,
                          value: {
                            inventory: item.inventory,
                            projectName: item.projectName,
                          },
                        };
                      })
                    : null
                }
                // disabled={ }
                setDropValue={(text) => {
                  let rentInventory = JSON.parse(text).inventory.filter(
                    (item) => {
                      if (bidType === "Kiralama") {
                        return (
                          item.inventoryCategory === "kiralık" ||
                          item.inventoryCategory === "satılıkveyakiralık"
                        );
                      } else if (bidType === "Satış") {
                        return (
                          item.inventoryCategory === "satılık" ||
                          item.inventoryCategory === "satılıkveyakiralık"
                        );
                      }
                      return [];
                    }
                  );
                  setInventory(rentInventory);
                  setBids({ ...bids, projeName: JSON.parse(text).projectName });
                }}
              />
              <DropInputJSONProject
                disabled={inventoryController}
                placeholder={
                  bids.inventoryName.length
                    ? bids.inventoryName
                    : "İlişkili Ürün Tipi Seçiniz"
                }
                data={
                  inventory
                    ? inventory.map((item) => {
                        return {
                          value: {
                            vatRate: item.vatRate,
                            inventoryOwner:
                              item.inventoryOwnerId.name +
                              " " +
                              item.inventoryOwnerId.surname,
                            excludingVatListPrice: item.excludingVatListPrice,
                            inventoryName: item.inventoryName,
                            id: item._id,
                            rentPrice: item.rentPrice,
                            isSuccesFullClosing: item.isSuccessfullClosing,
                          },
                          name: item.inventoryName,
                        };
                      })
                    : null
                }
                title={t("urunTipi")}
                setDropValue={(text) => {
                  setSelectedListRentPrice(JSON.parse(text).rentPrice);
                  setSelectedVatRate(JSON.parse(text).vatRate);
                  setSelectedInventoryOwner(JSON.parse(text).inventoryOwner);
                  setSelectedListPrice(JSON.parse(text).excludingVatListPrice);
                  const inventoryCurrency = JSON.parse(text).currency;
                  setSelectedInventoryCurrency(inventoryCurrency);

                  setBids({
                    ...bids,
                    inventoryName: JSON.parse(text).inventoryName,
                  });
                  // setIsSuccesFullClosing(JSON.parse(text).isSuccesFullClosing)
                  setInventoryId(JSON.parse(text).id);
                }}
              />
              {(selectedInventoryOwner ?? bids.ownerInventory) ? (
                <TextInput
                  disabled
                  title={t("malSahibi")}
                  value={selectedInventoryOwner ?? bids.ownerInventory}
                />
              ) : null}

              {selectedListPrice ? (
                <>
                  <TextInput
                    disabled
                    title={t("uniteDovizBirimi")}
                    placeholder={currentcyNameConverter(
                      selectedInventoryCurrency
                    )}
                  />
                  <DisabledNumberInput
                    suffix={currencyConvert(selectedInventoryCurrency)}
                    title={t("kdvHaricListeFiyat")}
                    value={selectedListPrice}
                  />
                </>
              ) : null}

              {bidType === "Satış" ? (
                <>

                  <DropInput2
                    disabled
                    isDefault={
                      bids.currency === "" ? "asda" : bids.currency
                    }
                    value={bids.currency}
                    data={[
                      { name: t("try"), value: "TRY" },
                      { name: t("usd"), value: "USD" },
                      { name: t("eur"), value: "EUR" },
                      { name: t("gbp"), value: "GBP" },
                      { name: t("rub"), value: "RUB" },
                    ]}
                    title={t("aktiviteDovizBirimi")}
                    placeholder={t("aktiviteDovizBirimi")}
                    setDropValue={(text) => {
                      setBids((prev) => {
                        return { ...prev, currency: text };
                      });
                    }}
                  />

                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    title={t("kdvHaricSatisFiyat")}
                    placeholder={t("kdvHaricSatisFiyat")}
                    value={bids.bidAmount}
                    onChange={(text) => {
                      setBids((prev) => {
                        return {
                          ...prev,
                          bidAmount: text,
                        };
                      });
                      setFin2(fin + 1);
                    }}
                  />
                  <DisabledNumberInput
                    title={t("kdv")}
                    placeholder={t("kdv")}
                    value={bids.bidAmountWithVat - bids.bidAmount}
                    disabled
                  />
                  <TextInput
                    disabled
                    title={t("kdvOrani")}
                    placeholder={selectedVatRate && "%" + selectedVatRate}
                  />
                  <DisabledNumberInput
                    suffix={currencyConvert(bids.currency)}
                    title={t("kdvDahilSatisTutari")}
                    placeholder={t("kdvDahilSatisTutari")}
                    value={bids.bidAmountWithVat}
                    disabled
                  />
                  <DisabledNumberInput2
                    placeholder={bids.discountRate}
                    disabled
                    title={t("indirimOrani")}
                    value={bids.discountRate ? bids.discountRate : 0}
                  />

                  <DateAndInpuDownPayment
                    disabled
                    textValue={bids.advancePayment}
                    dateValue={bids.advancePaymentDate}
                    textOnChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, advancePayment: text };
                      })
                    }
                    dateOnchange={(text) =>
                      setBids((prev) => {
                        return { ...prev, advancePaymentDate: text };
                      })
                    }
                    title={t("kapora")}
                    textPlaceHolder={t("kapora")}
                  />
                  {/* <h1>{_date}</h1> */}
                  <DateAndInput
                    textValue={bids.downPaymnet}
                    dateValue={bids.downPaymnetDate}
                    textOnChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, downPaymnet: text };
                      })
                    }
                    dateOnchange={(text) =>
                      setBids((prev) => {
                        return { ...prev, downPaymnetDate: text };
                      })
                    }
                    title={t("pesinat")}
                    textPlaceHolder={t("pesinat")}
                  />
                  <DateAndInput
                    textValue={bids.termDownPaymnet}
                    dateValue={bids.termDownPaymnetDate}
                    textOnChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, termDownPaymnet: text };
                      })
                    }
                    dateOnchange={(text) =>
                      setBids((prev) => {
                        return { ...prev, termDownPaymnetDate: text };
                      })
                    }
                    title={t("vadelendirilecekTutar")}
                    textPlaceHolder={t("vadelendirilecekTutar")}
                  />
                  {/*
                  <DisabledNumberInput
                    title={t("kdvDahilSatisTutari")}
                    textPlaceHolder={t("kdvDahilSatisTutari")}
                    value={
                      (Number(bids.termDownPaymnet) * 8) / 100 +
                      Number(bids.termDownPaymnet)
                    }
                    disabled
                  />
                   */}
                  <DateAndInput
                    textValue={bids.bankCredit}
                    dateValue={bids.bankCreditDate}
                    textOnChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, bankCredit: text };
                      })
                    }
                    dateOnchange={(text) =>
                      setBids((prev) => {
                        return { ...prev, bankCreditDate: text };
                      })
                    }
                    title={t("bankaKredisi")}
                    textPlaceHolder={t("bankaKredisi")}
                  />
                </>
              ) : bidType === "Kiralama" ? (
                <div>
                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    title={t("aktiviteTutari")}
                    placeholder={t("aktiviteTutari")}
                    value={bids.bidAmount}
                    onChange={(text) => {
                      setBids((prev) => {
                        return {
                          ...prev,
                          bidAmount: text,
                        };
                      });
                      // setFin2(fin + 1);
                    }}
                  />
                  <DisabledNumberInput2
                    disabled
                    title={t("indirimOrani")}
                    value={bids.discountRate ? bids.discountRate : 0}
                  />
                  <TextInput
                    value={bids.rentalPeriod}
                    title={t("kiralamaYil")}
                    placeholder={t("kiralamaYil")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, rentalPeriod: text };
                      })
                    }
                  />

                  <DateInput
                    value={bids.contractStartDate}
                    title={t("sozlesmeBaslangicTarihi")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, contractStartDate: text };
                      })
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className={style.rightBidForm}>
              {bidType === "Satış" ? (
                <>
                  <DropInput2
                    title={t("krediKullanilanBanka")}
                    placeholder={t("krediKullanilanBanka")}
                    setDropValue={(text) =>
                      setBids((prev) => {
                        return { ...prev, bankToBeUsed: text };
                      })
                    }
                    isDefault={
                      bids.bankToBeUsed === "" ? "asda" : bids.bankToBeUsed
                    }
                    data={
                      i18n.language === "tr"
                        ? bankCreditTR
                        : i18n.language === "en"
                        ? bankCreditEN
                        : null
                    }
                  />
                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    title={t("senetTutari")}
                    placeholder={t("senetTutari")}
                    value={bids.billAmount}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, billAmount: text };
                      })
                    }
                  />

                  <NumberOfBillsInout
                    state={numberOfBils}
                    setstate={(text) => {
                      setBids((prev) => {
                        return { ...prev, billLength: text };
                      });
                      setNumberOfBilss(text);
                    }}
                  />
                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    title={t("teslimeIliskinDigerMasraf")}
                    placeholder={t("teslimeIliskinDigerMasraf")}
                    value={bids.otherExpenses}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, otherExpenses: text };
                      })
                    }
                  />

                  <TextInput
                    value={bids.interestAmount}
                    title={t("faizMiktariYillikOran")}
                    placeholder={t("faizMiktariYillikOran")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, interestAmount: text };
                      })
                    }
                  />
                  <DateInput
                    value={bids.deliveryTime}
                    title={t("teslimTarihi")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, deliveryTime: text };
                      })
                    }
                  />
                  <DropInput2
                    value={bids.paymentType}
                    data={[
                      { value: "Tamamı Peşin", name: t("tamamiPesin") },
                      { value: "Kredili", name: t("kredili") },
                      { value: "Kredili + Vadeli", name: t("krediliVadeli") },
                      { value: "Vadeli", name: t("vadeli") },
                    ]}
                    isDefault={
                      bids.paymentType === "" ? "asda" : bids.paymentType
                    }
                    title={t("odemeTipi")}
                    placeholder={t("odemeTipi")}
                    setDropValue={(text) =>
                      setBids((prev) => {
                        return { ...prev, paymentType: text };
                      })
                    }

                    // onChange={text =>
                    //   setBids(prev => {
                    //     return { ...prev, paymentType: text };
                    //   })
                    // }
                  />

                  {/* <DropInput2
                      title={'Aktivite Aşaması'}
                      placeholder={'Bir Aktivite Aşaması Seçiniz'}
                      data={[
                        { value: 'Sunum', name: 'Sunum' },
                        { value: 'Bilgilendirme', name: 'Bilgilendirme' },
                        { value: 'Teklif', name: 'Teklif' },
                        { value: 'Müzakere', name: 'Müzakere' },
                        { value: 'Opsiyon', name: 'Opsiyon' },
                        { value: 'Kaparo', name: 'Kaparo' },
                        { value: 'Sözleşme', name: 'Sözleşme' },
                        {
                          value: 'Başarısız Kapanış',
                          name: 'Başarısız Kapanış',
                        },
                      ]}
                      setDropValue={text =>
                        setBid(prev => {
                          return { ...prev, bidStage: text };
                        })
                      }
                    /> */}

                  <DocumentInput2
                    setDocumentFile={setPaymentPlanFile}
                    documentFile={paymentPlanFile}
                    placeholder={bids.paymentPlanFileName}
                  />
                </>
              ) : null}
              {userType === "Kurumsal" && bidType === "Kiralama" ? (
                <>
                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    value={bids.unitRentalPrice}
                    title={t("birimKiraBedeli")}
                    placeholder={t("birimKiraBedeli")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, unitRentalPrice: text };
                      })
                    }
                  />
                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    value={bids.rentIncrease}
                    title={t("kiraArtisi")}
                    placeholder={t("kiraArtisi")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, rentIncrease: text };
                      })
                    }
                  />

                  <TextInput
                    value={bids.securityDeposit}
                    title={t("guvenceBedeliAy")}
                    placeholder={t("guvenceBedeliAy")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, securityDeposit: text };
                      })
                    }
                  />
                  <DropInput2
                    title={t("otopark")}
                    placeholder={t("otopark")}
                    data={[
                      { name: t("var"), value: "Var" },
                      { name: t("yok"), value: "Yok" },
                    ]}
                    isDefault={bids.carPark === "" ? "asda" : bids.carPark}
                    setDropValue={(text) =>
                      setBids((prev) => {
                        return { ...prev, carPark: text };
                      })
                    }
                  />
                  <DropInput2
                    title={t("dekorasyon")}
                    placeholder={t("dekorasyon")}
                    data={[
                      { name: t("var"), value: "Var" },
                      { name: t("yok"), value: "Yok" },
                    ]}
                    isDefault={
                      bids.decoration === "" ? "asda" : bids.decoration
                    }
                    setDropValue={(text) =>
                      setBids((prev) => {
                        return { ...prev, decoration: text };
                      })
                    }
                  />

                  <NumberInput
                    suffix={currencyConvert(bids.currency)}
                    value={bids.operatingExpenses}
                    title={t("isletmeGideri")}
                    placeholder={t("isletmeGideri")}
                    onChange={(text) =>
                      setBids((prev) => {
                        return { ...prev, operatingExpenses: text };
                      })
                    }
                  />
                </>
              ) : null}
              <DropInput2
                disabled={inventoryController}
                title={t("aktiviteAsamasi")}
                placeholder={t("aktiviteAsamasiSeciniz")}
                data={
                  i18n.language === "tr"
                    ? bidStageTR
                    : i18n.language === "en"
                    ? bidStageEN
                    : null
                }
                isDefault={bids.bidStage === "" ? "asda" : bids.bidStage}
                setDropValue={(text) =>
                  setBids((prev) => {
                    return { ...prev, bidStage: text };
                  })
                }
              />
              {bids.bidStage === "Başarılı Kapanış" ||
              bids.bidStage === "Başarısız Kapanış" ? (
                <div>
                  <TextInput
                    disabled
                    title={t("kapanisTarihi")}
                    placeholder={bids.closingDate}
                  />
                  {bids.bidStage === "Başarısız Kapanış" ? (
                    <DropInput2
                      title={t("basarisizKapanisNedeni")}
                      placeholder={t("basarisizKapanisNedeni")}
                      data={
                        i18n.language === "tr"
                          ? failedStageNoteTR
                          : i18n.language === "en"
                          ? failedStageNoteEN
                          : null
                      }
                      isDefault={
                        bids.failedStageNote === ""
                          ? "asda"
                          : bids.failedStageNote
                      }
                      setDropValue={(text) =>
                        setBids((prev) => {
                          return { ...prev, failedStageNote: text };
                        })
                      }
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidEdit;
