import style from './creditmodule.module.scss';
import { SubHeader, CreditForm } from '../../components';

const CreditModule = () => {
  return (
    <div className={style.creditModule}>
      <SubHeader form />
      <div className={style.content}>
        <CreditForm />
      </div>
    </div>
  );
};

export default CreditModule;
