import React, { useState, useEffect } from "react";
import style from "./elements.module.scss";
import ContactNoteItem from "./ContactNoteItem";
import axios from "axios";
import Popup from "../../containers/CreateLead/LeadPopup";
import { BaseUrl } from "../../ApiConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ContactNote({ setNotes, notes, contactId, fin, setFin, bids }) {
  const { t } = useTranslation();
  const d = new Date();
  const staticMeetDate =
    d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
  const user = useSelector((state) => state.user.userData);
  const [popup, setPopup] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [fakeInput, setFakeInput] = useState(false);
  const [changeButton, setChangeButton] = useState(false);
  const [errorHandleText, setErrorHandleText] = useState("");
  const [errorHandlePopUp, setErrorHandlePopUp] = useState(false);

  const [note, setNote] = useState({
    title: "",
    note: "",
    bids: "",
    // meetingShape: "",
    connectedProject: "",
    meetingDate: staticMeetDate,
    noteOwner: "",
    contactId: "",
    userType: "",
    queryBidName: "",
    queryContactName: "",
    queryInventoryName: "",
    queryProjectName: "",
  });

  useEffect(() => {
    const contactId = window.location.pathname.split("/")[2];
    const userType = user.userType;
    setNote((prev) => {
      return { ...prev, userType: userType, contactId: contactId };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note.note, note.bids, note.title, fin]);

  useEffect(() => {
    let owner = user.name + " " + user.surname;
    setNote({ ...note, noteOwner: owner });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note.noteOwner]);
  const _onSubmit = () => {
    const userType = user.userType;
    setNote({ ...note, userType: userType });
    axios
      .post(`${BaseUrl}/api/note/add/${contactId}`, note, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNote({
          ...note,
          note: "",
          title: "",
          meetingDate: staticMeetDate,
          // meetingShape: "",
        });

        setFin(fin + 1);
      })
      .catch((error) => {
        setFin(fin + 1);
        setNote({
          ...note,
          note: "",
          title: "",
          meetingDate: staticMeetDate,
          // meetingShape: "",
        });
      });
  };
  const _onDelete = () => {
    axios
      .delete(`${BaseUrl}/api/note/delete/${selectedId}/${contactId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setPopup(false);
        setFin(fin + 1);
      })

      .catch((error) => {
        console.log(error.response);
      });
  };
  const _onEdit = () => {
    const userType = user.userType;
    if (note.note.trim().length < 5) {
      setErrorHandleText("");
      setErrorHandleText(t("notCharacterSizeLimit"));
      setErrorHandlePopUp(true);
      return;
    }
    setNote({ ...note, userType: userType });
    axios
      .put(`${BaseUrl}/api/note/edit/${selectedId}`, note, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setNote({ ...note, note: "", title: "" });
        setFin(fin + 1);
        setChangeButton(false);
      })

      .catch((error) => {
        setFin(fin + 1);
        setNote({ ...note, note: "", title: "" });
        setChangeButton(false);

        console.log(error.response);
      });
  };

  /*
  const filterDateValueConvert = (date) => {
    const newDate = new Date(date);
    return (
      newDate.getDate() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getFullYear()
    );
  };
  */

  return (
    <>
      {popup ? (
        <Popup
          successClick={_onDelete}
          setPopup={setPopup}
          errorText="Bu notu silmek istediğinizden emin misiniz?"
          title="NOTU SİL"
          type="delete"
          closeClick={() => setPopup(false)}
        />
      ) : null}
      <section id="detail-note">
        <div className={style.contactNote}>
          <div className={style.notes}>Notlar</div>
          {fakeInput ? (
            <>
              <div className={style.addNote}>
                <label>
                  <input
                    placeholder={t("baslikNot")}
                    type="text"
                    onChange={(e) =>
                      setNote((prevNote) => ({
                        ...prevNote,
                        title: e.target.value,
                        meetingDate: staticMeetDate,
                      }))
                    }
                    value={note.title}
                  />
                </label>
                <label>
                  <textarea
                    placeholder={t("notEkle")}
                    maxLength="500"
                    onChange={(e) =>
                      setNote((prevNote) => ({
                        ...prevNote,
                        note: e.target.value,
                        meetingDate: staticMeetDate,
                      }))
                    }
                    value={note.note}
                  />
                </label>

                <div className={style.action}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <h2>{t("iliskiliAktivite")}</h2>
                      <select
                        value={note.bids === ""}
                        onChange={(e) => {
                          const data = e.target.value.split("-");
                          setNote((prevNote) => ({
                            ...prevNote,
                            bids: data[0],
                            connectedProject: data[1],
                          }));
                        }}
                      >
                        <option value="" disabled>
                          {t("notBirAktiviteSeciniz")}
                        </option>
                        {bids
                          ? bids.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  value={
                                    item.id +
                                    "-" +
                                    item.inventoryId.ownerProject
                                  }
                                >
                                  {item.bidName}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    {/* <div style={{ display: "flex", marginTop: "1rem" }}>
                      <h2>Görüşme Kanalı</h2>
                      <select
                        placeholder="Görüşme Kanalı"
                        onChange={(e) =>
                          setNote({ ...note, meetingShape: e.target.value })
                        }
                      >
                        <option value="" disabled selected>
                          Görüşme Kanalı
                        </option>
                        <option value="Yüzyüze Görüşme">Yüzyüze Görüşme</option>
                        <option value="Gelen Telefon">Gelen Telefon</option>
                        <option value="Giden Telefon">Giden Telefon</option>
                        <option value="Müşteriye Ziyaret">
                          Müşteriye Ziyaret
                        </option>
                        <option value="WhatsApp">WhatsApp</option>
                        <option value="Chat">Chat</option>
                        <option value="E-Mail">E-Mail</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Facebook">Facebook</option>
                      </select>
                    </div> */}
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                      <h2>{t("notTarih")}</h2>
                      <input type="text" disabled value={staticMeetDate} />
                    </div>
                  </div>
                  <div>
                    <button
                      className={style.close}
                      onClick={() => {
                        setFakeInput(false);
                        setNote({ ...note, title: "", note: "", bids: "" });
                        setChangeButton(false);
                      }}
                    >
                      {t("notIptalButton")}
                    </button>
                    {changeButton ? (
                      <button onClick={_onEdit} className={style.save}>
                        {t("notDegistir")}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (note.note.length) {
                            _onSubmit();
                          }
                        }}
                        className={note.note.length ? style.save : style.close}
                      >
                        {t("notEkleButton")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.fakeNotes}
                onClick={() => setFakeInput(true)}
              >
                <label>
                  <input
                    style={{ color: "grey" }}
                    defaultValue={t("notEkle")}
                    type="text"
                    placeholder={t("notEkle")}
                  />
                </label>
              </div>
            </>
          )}
          <ContactNoteItem
            setFakeInput={setFakeInput}
            setNotes={setNotes}
            notes={notes}
            setPopup={setPopup}
            setSelectedId={setSelectedId}
            bids={bids}
            setNote={setNote}
            setChangeButton={setChangeButton}
          />
          {errorHandlePopUp ? (
            <Popup
              title={t("notDuzenle")}
              errorText={errorHandleText}
              closeClick={() => setErrorHandlePopUp(false)}
            />
          ) : null}
        </div>
      </section>
    </>
  );
}

export default ContactNote;
