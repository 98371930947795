import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import systemSlice from "./slice/systemSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    system: systemSlice,
  },
});


