import style from "./elements.module.scss";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ContactCard = ({ data }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const { contactSourceTR, contactSourceEN } = useSelector(
    (state) => state.system.contactSource
  );
  const templatePhone =
    data?.phone?.length === 12
      ? /(\d{2})(\d{3})(\d{3})(\d{4})/
      : /(\d{3})(\d{3})(\d{3})(\d{4})/;
  const templateGSM =
    data?.gsm?.length === 12
      ? /(\d{2})(\d{3})(\d{3})(\d{4})/
      : /(\d{3})(\d{3})(\d{3})(\d{4})/;
  return (
    <section id="detail-card">
      <div className={style.contactCard}>
        {data ? (
          <>
            <div className={style.column}>
              {user.department === "admin" && (
                <div className={style.cards}>
                  <h2>ID</h2>
                  <p>{data._id ? data._id : ""} </p>
                </div>
              )}
              <div className={style.cards}>
                <h2>{t("isimSoyisim")}</h2>
                <h1>
                  {data.name ? data.name : ""}{" "}
                  {data.surname ? data.surname : ""}
                </h1>
              </div>
              <div className={style.cards}>
                <h2>{t("yas")}</h2>
                <h1>{data.age ? data.age : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("cinsiyet")}</h2>
                <h1>
                  {data.gender
                    ? data.gender === "Erkek"
                      ? t("erkek")
                      : t("kadin")
                    : ""}
                </h1>
              </div>
              <div className={style.cards}>
                <h2>{t("gsm")}</h2>
                <h1>
                  {data.gsm
                    ? data.gsm?.toString().replace(templateGSM, "+$1($2) $3-$4")
                    : ""}
                </h1>
              </div>
              <div className={style.cards}>
                <h2>{t("telefon")}</h2>
                <h1>
                  {data.phone
                    ? data.phone
                        ?.toString()
                        .replace(templatePhone, "+$1($2) $3-$4")
                    : ""}
                </h1>
              </div>

              <div className={style.cards}>
                <h2>{t("email")}</h2>
                <h1>{data.email ? data.email : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("tcno")}</h2>
                <h1>{data.tckn ? data.tckn : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("vergiNo")}</h2>
                <h1>{data.taxNo ? data.taxNo : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("sirket")}</h2>
                <h1>{data.company ? data.company : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("kurumsalUnvan")}</h2>
                <h1>{data.corporateTitle ? data.corporateTitle : ""}</h1>
              </div>

              <div className={style.cards}>
                <h2>{t("adres")}</h2>
                <h1>{data.address ? data.address : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("odaSayisi")}</h2>
                <h1>{data.numberOfRooms ? data.numberOfRooms : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("manzara")}</h2>
                <h1>{data.view ? data.view : ""}</h1>
              </div>
              {data.associatedProject.length > 0 && (
                <div
                  className={style.cards}
                  style={
                    {
                      // maxWidth: "250px",
                      // textAlign: "left",
                      // whiteSpace: "normal",
                    }
                  }
                >
                  <h2>{t("iliskilendirilenProje")}</h2>
                  <p
                    style={{
                      lineHeight: "1.6",
                    }}
                  >
                    {data.associatedProject.map((item, index) => {
                      if (user.assignedProject.includes(item._id)) {
                        return (
                          <Link
                            key={item._id}
                            to={`/project-detail/${item._id}`}
                          >
                            {index + 1}- {item.projectName}
                            {"  "}
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </p>
                </div>
              )}
            </div>

            <div className={style.column}>
              <div className={style.cards}>
                <h2>{t("il")}</h2>
                <h1>{data.city ? data.city : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("ilce")}</h2>
                <h1>{data.country ? data.country : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("ulke")}</h2>
                <h1>{data.otherCountry ? data.otherCountry : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("acilDurumKisisi")}</h2>
                <h1>{data.emergencyName ? data.emergencyName : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("acilDurumKisisiTelefon")}</h2>
                <h1>{data.emergencyPhone ? data.emergencyPhone : ""}</h1>
              </div>

              <div className={style.cards}>
                <h2>{t("kullanciTipi")}</h2>
                <h1>
                  {data.type
                    ? data.type === "Bireysel"
                      ? t("bireysel")
                      : data.type === "Kurumsal"
                      ? t("kurumsal")
                      : ""
                    : null}
                </h1>
              </div>
              <div className={style.cards} style={{ marginLeft: "15px" }}>
                <h2 style={{ width: "120px" }}>{t("kategorisi")}</h2>
                <h1>
                  {data.category
                    ? data.category === "Müşteri"
                      ? t("musteri")
                      : data.category === "İş Ortağı"
                      ? t("isOrtagi")
                      : data.category === "Tedarikçi"
                      ? t("tedarikci")
                      : data.category === "Resmi Görevli"
                      ? t("resmiGorevli")
                      : data.category === "Diğer"
                      ? t("diger")
                      : null
                    : ""}
                </h1>
              </div>
              <div className={style.cards}>
                <h2>{t("altKategorisi")}</h2>
                <h1>
                  {data.shape
                    ? data.shape === "Alıcı"
                      ? t("alici")
                      : data.shape === "Satıcı"
                      ? t("satici")
                      : data.shape === "Potansiyel Alıcı"
                      ? t("potensiyelAlici")
                      : data.shape === "Kiracı"
                      ? t("kiraci")
                      : data.shape === "Kiralayan"
                      ? t("kiralayan")
                      : data.shape === "Potansiyel Kiracı"
                      ? t("potansiyalKiraci")
                      : data.shape === "Diğer"
                      ? t("diger")
                      : null
                    : ""}
                </h1>
              </div>
              <div className={style.cards}>
                <h2>{t("kontakKaynagi")}</h2>
                <h1>
                  {i18n.language === "tr"
                    ? contactSourceTR.find(
                        (item) => item.value === data.contactSource
                      )?.name
                    : i18n.language === "en"
                    ? contactSourceEN.find(
                        (item) => item.value === data.contactSource
                      )?.name
                    : null}
                </h1>
              </div>
              {data.contactSource && data.contactSourceText.length > 0 ? (
                <div className={style.cards}>
                  <h2>
                    {i18n.language === "tr"
                      ? contactSourceTR.find(
                          (item) => item.value === data.contactSource
                        )?.name
                      : i18n.language === "en"
                      ? contactSourceEN.find(
                          (item) => item.value === data.contactSource
                        )?.name
                      : null}
                  </h2>
                  <h1>
                    {data.contactSourceText ? data.contactSourceText : ""}
                  </h1>
                </div>
              ) : null}

              <div className={style.cards}>
                <h2>{t("butce")}</h2>
                <h1>
                  <NumberFormat
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      width: "70%",
                      color: "#333e48",
                    }}
                    value={data.budget}
                    isNumericString
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={data.currency ? ` ${data.currency}` : " TRL"}
                  />
                  {/* {data.budget ? data.budget : ""} */}
                </h1>
              </div>

              <div className={style.cards}>
                <h2>{t("kanal")}</h2>
                <h1>{data.channel ? data.channel : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("olusturanPersonel")}</h2>
                <h1>{data.owner ? data.owner : ""}</h1>
              </div>
              <div className={style.cards}>
                <h2>{t("olusturulmaTarihi")}</h2>
                <h1>{data.createAtReal ? data.createAtReal : ""}</h1>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default ContactCard;
