import style from "./subheader.module.scss";
import { BackIcon } from "../../assets/icons";
import { useNavigate } from "react-router";

const LeadCard = ({ noteData }) => {
  const navigate = useNavigate();
  return (
    <div className={style.leadCard}>
      <button
        className={style.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </button>
      <h2 style={{ marginLeft: "1rem" }}>{noteData.title}</h2>
    </div>
  );
};

const NoteDetailMenu = ({ noteData }) => {
  return (
    <div className={style.leadDetailMenu}>
      <div className={style.left}>
        <LeadCard noteData={noteData} />
      </div>
      <div className={style.right}>
        {/* <NewButton title={"E-Mail Gönder"} /> */}
      </div>
    </div>
  );
};

export default NoteDetailMenu;
