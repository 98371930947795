import style from "./InventoryPopup.module.scss";
import { useTranslation } from "react-i18next";

const InventoryPopup = ({ closeClick, successClick, targetInventory }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>{t("uniteSilmePopupTitle")}</div>
          <hr />
          <div className={style.textcontainer}>
            <strong>{targetInventory.projectName}</strong>{t("uniteSilmePopupPart1")}  {" "}
            <strong>{targetInventory.InventoryName}</strong> {t("uniteSilmePopupPart2")} {" "}
            <strong>{targetInventory.numberOfBids} {t("uniteSilmePopupPart3")} </strong>{t("uniteSilmePopupPart4")} 
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("vazgec")}
            </button>
            <button onClick={successClick} className={style.successModalPopup}>
              {t("sil")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default InventoryPopup;
