import { useState } from "react";
import { NewButton } from "./Buttons";
import style from "./subheader.module.scss";
import { useSelector } from "react-redux";
import ExcelExportPopup from "./ExcelExportPopup";

import { useTranslation } from "react-i18next";

const ApprovalMenu = ({ approvalData }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("projeAdi"),
      t("uniteIsmi"),
      t("urunStatusu"),
      t("urunTipi"),
      t("musteriAdi"),
      t("olusturanPersonel"),
      t("olusturmaTarihi"),
    ]);
    const newData = data.map((item) => {

      return {
        [t("projeAdi")]: item.queryProjectName,
        [t("uniteIsmi")]: item.inventoryName,
        [t("urunStatusu")]: item.inventoryId.inventoryCategory,
        [t("urunTipi")]: item.inventoryId.corporateStatus,
        [t("musteriAdi")]:item.queryContactName,
        [t("olusturanPersonel")]: item.ownerBid,
        [t("olusturmaTarihi")]: item.createdAt,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  return (
    <>
      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="20px 30px"
          data={excelData}
          fileName="APPROVAL"
          text={t("excelExportOnaylar")}
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("onaylar")}
          </div>
        </div>
        <div className={style.right}>
           {(user.department === "admin" ||
            user.department === "ceo"  ) && (
          <NewButton
            title={"Veriyi Dışa Aktar"}
            icon={"exel"}
            onClick={() => {
              excelExportAndDownload(approvalData);
            }}
          />)}
        </div>
      </div>
    </>
  );
};

export default ApprovalMenu;
