import style from "./createcontact.module.scss";
import { ContactForm } from "../../components";

const CreateContact = () => {
  return (
    <div className={style.createContact}>
      <ContactForm />
    </div>
  );
};

export default CreateContact;
