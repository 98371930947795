import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllBids = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = 'get',
  data = {}
) => {
  let request;

  if (method === 'get') {
    request = await axios.get(
      `${BaseUrl}/api/bid/getBid/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === 'post') {

    request = await axios.post(`${BaseUrl}/api/bid/queryBid/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }
  const payload = {
    data: request.data.bid,
    pageCount: request.data.pageCount,
  };
  return payload;
};

export const deleteBid = async (jwt, bidId, contactId) => {
  const request = await axios.post(`${BaseUrl}/api/bid/delete/${bidId}`,
  {
    contactId: contactId,
  },
  {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return request;
};
