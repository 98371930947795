import React, { useState, useEffect } from "react";
import style from "./elements.module.scss";
import { getLog } from "../../lib/logApi";

function ContactLog({ contactId, user }) {

  const [data, setData] = useState(null);

  useEffect(() => {

    const params = { documentId: contactId, documentName: "Contact", userType: user.userType }
    const req = getLog(user.token, user.userType, params);
    req.then(res => {
      setData(res);
    }).catch((error) => {
      setData(null);
    });
  }, [contactId, user.department, user.token, user.userType]);
  
  return (
    (user.department === "admin" && data?.data?.logs ? 
    <>
    <section id="detail-log">
      <div className={style.contactNote}>
        <div className={style.notes}>Loglar</div>
        <ul className={style.logList}>
        {data.data.logs.map((log, index) => (
          <li key={index} className={style.logItem}>
            <span className={style.createdBy}>{log?.createdBy?.name} {log?.createdBy?.surname}</span>
            <span className={style.createdAt}>{log.createdAt}</span>
            {log.ip ? <span className={style.ip}>Ip: {log?.ip}</span> : ""}
            <hr/>
            <span className={style.logDetails}>
              {Object.entries(JSON.parse(log.body)).map(([key, value]) => (
                <div key={key} className={style.logDetailItem}>
                  <span >{key}:</span>
                  <span >{JSON.stringify(value)}</span>
                </div>
              ))}
            </span>
          </li>
        ))}
        </ul>
      </div>
    </section>
  </>
  : <></>)

  );
}

export default ContactLog;
