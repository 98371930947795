import style from "./sidenav.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideNav = ({
  contactDetail,
  lenghts,
  inventoryDetail,
  bidDetail,
  noteDetail,
  projectDetail,
  rapor,
  adminSystem,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("detail-card");
  const SmoothScroll = (event, id) => {
    event.preventDefault();
    let element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    setActive(id);
  };

  return (
    <div className={style.sideNav}>
      {inventoryDetail === "1" || bidDetail === "1" || noteDetail === "1" ? (
        <div></div>
      ) : (
        <>
          <h2>{t("navigasyon")}</h2>
          <div className={style.navWrapper}>
            {contactDetail ? (
              <>
                <Link
                  onClick={(e) => SmoothScroll(e, "detail-card")}
                  to="#detail-card"
                  className={active === "detail-card" ? style.active : null}
                >
                  <div>{t("kisiKarti")}</div>
                </Link>
                {/* <Link
                  to="a"
                  onClick={(e) => SmoothScroll(e, "detail-card")}
                  to="#detail-card"
                  className={active === "detail-card" ? style.active : null}
                >
                  <div>Detay Kartı</div>
                </Link> */}
                <Link
                  className={active === "bid-card" ? style.active : null}
                  onClick={(e) => SmoothScroll(e, "bid-card")}
                  to="#bid-card"
                >
                  {t("aktiviteler")}
                  <span className="badge">{lenghts ? lenghts.bids : 0}</span>
                </Link>
                <Link
                  className={
                    active === "documention-card" ? style.active : null
                  }
                  onClick={(e) => SmoothScroll(e, "documention-card")}
                  to="#documention-card"
                >
                  {t("dokumanlar")}
                  <span className="badge">
                    {lenghts.documents ? lenghts.documents : 0}
                  </span>
                </Link>
                <Link
                  className={active === "detail-note" ? style.active : null}
                  onClick={(e) => SmoothScroll(e, "detail-note")}
                  to="#detail-note"
                >
                  {t("notlar")}
                  <span className="badge">
                    {lenghts.notes ? lenghts.notes : 0}
                  </span>
                </Link>
                {/* <Link to="/lead-detail">
                  E-Postalar
                  <span className="badge">0</span>
                </Link> */}
                {/* <Link to="/lead-detail">
                  Mesajlar
                  <span className="badge">0</span>
                </Link> */}
              </>
            ) : projectDetail ? (
              <div className={style.active}>{t("proje")}</div>
            ) : rapor ? (
              <div className={style.active}>{t("raporlar")}</div>
            ) : adminSystem ? (
              <div className={style.active}>{t("sistem")}</div>
            ) : (
              <div className={style.active}>{t("kisiKarti")}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SideNav;
