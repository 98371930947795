import React, { useState } from "react";
import style from "./elements.module.scss";
import { /* DeleteIcon, */ EditIcon } from "../../assets/icons/index";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReadMore = ({ children }) => {
  const { t } = useTranslation();
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    setSbstr(500);
  };
  const [substr, setSbstr] = useState(120);
  return (
    <p>
      {text.substr(0, substr)}
      {text.length > substr ? (
        <span onClick={toggleReadMore}>{t("dahaFazlaText")}</span>
      ) : text.length > 120 ? (
        <span onClick={() => setSbstr(120)}>{t("dahaAziText")}</span>
      ) : null}
    </p>
  );
};
function ContactNoteItem({
  notes,
  setSelectedId,
  setPopup,
  bids,
  setNote,
  setChangeButton,
  setFakeInput,
}) {
  const { t } = useTranslation();
  let contactId = window.location.pathname.split("/")[2];
  return (
    <>
      {notes
        ? notes.map((item, index) => {
            return (
              <div key={`notes-${item._id}`} className={style.notesItem}>
                <div className={style.title}>
                  {item.title ? item.title : ""}
                </div>
                <div className={style.note}>
                  <ReadMore>{item.note ? item.note : ""}</ReadMore>
                </div>

                <div className={style.footer}>
                  <span>
                    <h2>{t("olusturanPersonel")} - </h2>
                    <h5 style={{ color: "#5dade2" }}>
                      {item.noteOwner ? item.noteOwner : ""}
                    </h5>
                  </span>
                  <span style={{ width: "200px", textAlign: "start" }}>
                    <h2>{t("iliskiliAktivite")} - </h2>
                    {item.isGeneral === false ? (
                      <h5>
                        <Link
                          style={{ color: "#5dade2" }}
                          to={`/bid-detail/${
                            item.bids ? item.bids._id : ""
                          }/${contactId}`}
                        >
                          {item.bids ? item.bids.bidName : ""}
                        </Link>
                      </h5>
                    ) : (
                      <h5 style={{ color: "#5dade2" }}>{t("genel")}</h5>
                    )}
                  </span>

                  <span id="last-date">
                    <h2>{t("tarih")} - </h2>
                    <h4>{item.meetingDate}</h4>
                  </span>
                </div>
                <div className={style.actionDeleteAndEdit}>
                  
                  <div
                    onClick={() => {
                      setNote({
                        note: item.note,
                        title: item.title,
                        bids: item.bids ? item.bids._id : "",
                      });
                      setSelectedId(item._id);
                      setChangeButton(true);
                      setFakeInput(true);
                    }}
                  >
                    <EditIcon />
                  </div>
                  {/*
                  <div
                    onClick={() => {
                      setPopup(true);
                      setSelectedId(item._id);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                */}
                </div>
              </div>
            );
          })
        : null}
    </>
  );
}

export default ContactNoteItem;
