import React from "react";
// import { UserIcon } from "../../assets/icons";
import style from "./header.module.scss";
// import { useState } from "react";
import { AdminIcon, TemplateIcon } from "./../../assets/icons/index";
// import { Link } from "react-router-dom";

function AdminHeader({ isAdminPanelHandler, isAdminPanel }) {
  return (
    <>
      <button onClick={isAdminPanelHandler} className={style.adminButton}>
        {/* <AdminIcon />  */}

        {isAdminPanel === "admin" ? (
          <>
            <TemplateIcon /> CRM Geri Dön
          </>
        ) : (
          <>
            <AdminIcon />
            Admin Paneli
          </>
        )}
      </button>
    </>
  );
}

export default AdminHeader;
