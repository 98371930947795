import style from "./header.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const ProfilePopup = ({ setPopup, closePopUp, menuRef }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const outClick = () => {
    localStorage.removeItem("AuthToken");
    navigate("/login", { replace: true });
    window.location.reload();
  };

  const [userDepartment, setUserDepartment] = useState("");

  useEffect(() => {
    if (user.department === "admin") {
      setUserDepartment("Admin");
    } else if (user.department === "ceo") {
      setUserDepartment(t("ceo"));
    } else if (user.department === "gm") {
      setUserDepartment(t("gm"));
    } else if (user.department === "mc") {
      setUserDepartment(t("mc"));
    } else if (user.department === "dms") {
      setUserDepartment(t("dms"));
    } else if (user.department === "sd") {
      setUserDepartment(t("sd"));
    } else if (user.department === "rd") {
      setUserDepartment(t("rd"));
    } else if (user.department === "sm") {
      setUserDepartment(t("sm"));
    } else if (user.department === "ss") {
      setUserDepartment(t("ss"));
    } else if (user.department === "sa") {
      setUserDepartment(t("sa"));
    } else if (user.department === "hr") {
      setUserDepartment(t("hr"));
    } else if (user.department === "fd") {
      setUserDepartment(t("fd"));
    } else if (user.department === "pd") {
      setUserDepartment(t("pd"));
    }
  }, [user, t]);

  const infoPopUpHandler = () => {
    setPopup(false);
    navigate("/bilgilerim", { replace: true });
  };

  return (
    <div className={style.cardPopup} ref={menuRef}>
      <div className={style.close} onClick={() => setPopup(false)}>
        x
      </div>
      <div className={style.content}>
        <div className={style.profileImage}>
          {user.name[0] + user.surname[0]}
        </div>
        <div className={style.nameandsurname}>
          {user.name} {user.surname}
        </div>
        <div className={style.nameandsurname}>
          <strong>{userDepartment}</strong>
        </div>
        <div className={style.profileId}>
          <strong>ID:</strong>
          {user.id}
        </div>
      </div>
      <div className={style.cardPopupBottom}>
        <button className={style.myAcoountButton} onClick={infoPopUpHandler}>
          {t("bilgilerimButton")}
        </button>
        <button className={style.outButton} onClick={outClick}>
          {t("cikisButton")}
        </button>
      </div>
    </div>
  );
};

export default ProfilePopup;
