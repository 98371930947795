import React from 'react';
import style from './kvkelements.module.scss';
import { BaseUrl } from '../../ApiConfig';
import { Link } from "react-router-dom"
function KvkListItem({ kvkkData, setKvkkData }) {
  return kvkkData
    ? kvkkData.map(item => {
        return (
          <>
            <div className={style.leadListItem}>
              <input
                style={{ flex: 1 }}
                type="checkbox"
                onChange={e => {
                  let value = e.target.checked;
                  setKvkkData(
                    kvkkData.map(sd => {
                      if (sd.id === item.id) {
                        sd.select = value;
                      }
                      return sd;
                    })
                  );
                }}
                checked={item.select}
              />
              <div style={{ flex: 3 }}>{item.statusForm}</div>
              <div style={{ flex: 3 }}>{item.name + ' ' + item.surname}</div>
              <div style={{ flex: 3 }}>{item.dataProcessing}</div>
              <div style={{ flex: 3 }}>{item.channel}</div>
              <div style={{ flex: 3 }}>{item.createdAt}</div>
              <div style={{ flex: 3 }}>
                {item.kvkkDocumation === 'Döküman Eklendi' ? (
                  <Link
                    target="_blank"
                    to={`${BaseUrl}/public/uploads/${item.fileName}`}>
                    {item.fileName ? (
                      <>
                        {item.fileName.substr(0, 10)}

                        {item.fileName.length > 10 ? '...' : ''}
                      </>
                    ) : (
                      'Döküman'
                    )}{' '}
                  </Link>
                ) : null}
              </div>
              <div></div>
            </div>
          </>
        );
      })
    : null;
}

export default KvkListItem;
