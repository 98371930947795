import style from "./ApprovalPopup.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";


const ApprovalPopUp = ({ closeClick, successClick, fullName, type }) => {
  const { t } = useTranslation();

  const sendText = t("approveText");

  const deleteText = t("approveCancelText");

  const sendHandler = () => {
    successClick();
  };

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {type === "approve" ? t("approveSubjectText") : t("approveSubjectCancelText")}
          </div>
          <hr />
          <div className={style.textcontainer}>
            <strong>{fullName}</strong>{" "}
            {type === "approve" ? sendText : deleteText}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("vazgec")}
            </button>

            <button
              onClick={sendHandler}
              className={style.successModalPopup}
              style={{
                cursor: "pointer",
                backgroundColor:
                  type === "#ccc",
              }}
            >
              {type === "approve" ? t("onayla") : t("iptal")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox} onClick={closeClick}></div>
    </>
  );
};

export default ApprovalPopUp;
