import React from "react";
import Clock from "react-live-clock";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function HomeMenu() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  };
  var date = new Date().toLocaleDateString(
    i18n.language === "tr" ? "tr" : "en",
    options
  );
  return (
    <div
      style={{
        width: "100%",
        justifySelf: "center",
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <p
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {t("hosgeldin")},{" "}
        </p>
        &nbsp;&nbsp;
        <p
          style={{
            fontSize: "1rem",
          }}
        >
          {user ? user.name + " " + user.surname : null}
        </p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div
          style={{
            padding: "8px 15px",
            backgroundColor: "rgb(218 163 53 / 85%)",
            textAlign: "left",
            borderRadius: "5px",
            width: "255px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{date}</div> &nbsp;
          {" - "}&nbsp;
          <Clock format={"HH:mm:ss"} ticking={true} />
        </div>
      </div>
    </div>
  );
}

export default HomeMenu;
