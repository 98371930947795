import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/ui/Loader/Loader";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user.userData);
  const isLoading = useSelector((state) => state.user.isLoading);
  const hasError = useSelector((state) => state.user.hasError);

  if (hasError) {
    localStorage.removeItem("AuthToken");
    window.location.reload();
    window.location.href = "/login";
  }

  return isLoading ? (
    <Loader isLoading={isLoading} type="refresh" />
  ) : allowedRoles?.includes(user?.department) ? (
    <Outlet />
  ) : user?.id ? (
    <Navigate to="/" state={{ from: location?.pathname }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location?.pathname }} replace />
  );
};

export default RequireAuth;
