import style from "./excelExportPopup.module.scss";
import popupStyle from "./excelExportPopup.module.scss";
import { useState } from "react";
import XLSX from "xlsx";

import { useTranslation } from "react-i18next";

const ExcelExportPopup = ({
  closeClick,
  text,
  headers,
  data,
  paddingVal,
  fileName,
}) => {
  const { t } = useTranslation();
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([...headers]);

  const handleCheckboxChange = (data) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox === data
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter((checkedCheckbox) => checkedCheckbox !== data)
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(data));
    }
  };

  const excelDownload = () => {
    const newDataDeleteObjectPropertyIfNotChecked = data.map((item) => {
      const newItem = {};
      for (const key in item) {
        if (checkedCheckboxes.includes(key)) {
          newItem[key] = item[key];
        }
      }
      return newItem;
    });
    var today = new Date();
    var date =
      today.getDate() +
      "_" +
      (today.getMonth() + 1) +
      "_" +
      today.getFullYear();
    var time =
      today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    var dateTime = date + "--" + time;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      newDataDeleteObjectPropertyIfNotChecked
    );
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}-${dateTime}.xlsx`);
  };

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>{text}</div>
          <hr />
          <div className={popupStyle.container}>
            {headers &&
              headers.map((data, index) => {
                return (
                  <label key={`lb-${data}`} className={popupStyle.item}>
                    <input
                      key={`cb-${data}`}
                      value={data}
                      className={popupStyle.checkbox}
                      type="checkbox"
                      checked={checkedCheckboxes.some(
                        (checkedCheckbox) => checkedCheckbox === data
                      )}
                      onChange={() => handleCheckboxChange(data)}
                    />
                    {data}
                  </label>
                );
              })}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("vazgec")}
            </button>
            <button onClick={excelDownload} className={style.successModalPopup}>
              <i
                style={{ fontSize: "19px" }}
                className="ri-file-download-line"
              ></i>
              {t("excelIndir")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default ExcelExportPopup;
