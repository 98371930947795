import style from "./UserListPopUp.module.scss";

const UserListPopUp = ({ closeClick, successClick, fullName, type }) => {
  const deleteText =
    "isimli kullanıcıyı Silmek istediğinize emin misiniz? Bu işlemi geri alamayacaksınız.";

  const banText =
    "isimli kullanıcıyı Susturmak istediğinize emin misiniz? Susuturulan kullanıcı giriş yapamayacaktır ama ileriki zamanlarda Aktif edilirek tekrar giriş yapabilirler.";

  const unBanText =
    "isimli kullanıcıyı yeniden Aktif hale getirmek istediğinize emin misiniz? ";

  const resetText =
    "isimli kullanıcının şifresini sıfırlamak istediğinize emin misiniz? Bu işlem  şifresini unutan kullanıcıya yeniden yeniden şifre oluşturmasına olanak sağlayacaktır.";

  const errorText =
    " için işlem yaparken bir hata oluştu. Lütfen tekrar deneyiniz.";

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {type === "delete"
              ? "KULLANCIYI SİL"
              : type === "reset"
              ? "KULLANICININ ŞİFRESİNİ SIFIRLA"
              : type === "ban"
              ? "KULLANCIYI SUSTUR"
              : type === "unban"
              ? "KULLANICIYI YENIDEN AKTİF YAP"
              : type === "error"
              ? "HATA"
              : ""}
          </div>
          <hr />
          <div className={style.textcontainer}>
            {" "}
            <strong>{fullName}</strong>{" "}
            {type === "delete"
              ? deleteText
              : type === "reset"
              ? resetText
              : type === "ban"
              ? banText
              : type === "unban"
              ? unBanText
              : type === "error"
              ? errorText
              : ""}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {type === "error" ? "Tamam" : "Vazgeç"}
            </button>
            <button onClick={successClick} className={style.successModalPopup}>
              {type === "delete"
                ? "Sil"
                : type === "reset"
                ? "Sıfırla"
                : type === "ban"
                ? "Sustur"
                : type === "unban"
                ? "Aktif Et"
                : " "}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default UserListPopUp;
