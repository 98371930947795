import style from "./BidListPopup.module.scss"

import { useTranslation } from "react-i18next";

const BidListPopup = ({ closeClick, successClick, targetActivity }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>{t("activityPopUpDeletePart1")}</div>
          <hr />
          <div className={style.textcontainer}>
          {t("activityPopUpDeletePart2")}{" "}
            <strong>{targetActivity.ownerInventory}</strong> {t("activityPopUpDeletePart3")}{" "}
            <strong>{targetActivity.projeName}</strong> {t("activityPopUpDeletePart4")}{" "}
            <strong>{targetActivity.inventoryName}</strong> {t("activityPopUpDeletePart5")}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
            {t("vazgec")}
            </button>
            <button onClick={successClick} className={style.successModalPopup}>
              {t("activityPopUpDeleteButtonSil")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default BidListPopup;
