import style from "./createlead.module.scss";
import { BaseUrl } from "../../ApiConfig";
import { SubHeader } from "../../components";
import {
  GSMInput,
  TextInput,
  UserInput,
  Textarea,
} from "../../components/Forms/Inputs";
import axios from "axios";
import LeadPopup from "./LeadPopup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ProjectSelection from "../../components/ui/projectSelection/ProjectSelection";
import { getAllProjects } from "../../lib/projectApi";

import { useTranslation } from "react-i18next";

const CreateLead = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const d = new Date();
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [leadCreate, setLeadCreate] = useState({
    owner: user.id,
    ownerNameSurname: user.name + " " + user.surname,
    name: "",
    surname: "",
    phone: "",
    email: "",
    company: "",
    note: "",
    associatedProject: [],
    userType: user.userType,
    createdAt: d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear(),
  });
  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const [ , setwClick] = useState("");
  const _onClick = () => {
    leadCreate.associatedProject = checkedCheckboxes;

    //console.log(leadCreate);
    axios
      .post(`${BaseUrl}/api/lead/add`, leadCreate, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          navigate("/lead-list", { replace: true });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "Bu bilgide başka bir kayıt var.") {
          setErrorText(t("baskaKayitError"));
          setPopup(true);
        }
      });
  };

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        //console.log(res.data);
        setAllProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  const popUpCloseHandler = () => {
    setPopup(false);
    setErrorText("");
  };

  return (
    <>
      {popup && (
        <LeadPopup closeClick={popUpCloseHandler} errorText={errorText} />
      )}
      <SubHeader
        createLead
        setwClick={setwClick}
        form
        onClick={() => {
          const dep = user.department;
          if (
            checkedCheckboxes.length === 0 &&
            (dep === "ss" || dep === "sa" || dep === "sm")
          ) {
            setErrorText(t("projeSecinError"));
            setPopup(true);
            return;
          }
          if (leadCreate.name.length <= 2) {
            setErrorText(t("isim2harfError"));
            setPopup(true);
          } else if (leadCreate.surname.length <= 2) {
            setErrorText(t("soyisim2harfError"));
            setPopup(true);
          } else if (
            leadCreate.phone.length === 0 &&
            leadCreate.email.length === 0
          ) {
            setErrorText(t("emailTelefonError"));
            setPopup(true);
          } else {
            if (
              leadCreate.email.length > 4 &&
              !leadCreate.email.includes("@")
            ) {
              setErrorText(t("emailError"));
              setPopup(true);
            } else {
              _onClick();
            }
          }
        }}
        path="/lead-list"
        newButtonName={t("kaydet")}
        // buttonName={'Kaydet ve Yeni Ekle'}
      />
      <div className={style.createLeadContent}>
        <div className={style.leadForm}>
          <div className={style.leftLeadForm}>
            <TextInput
              value={leadCreate.name}
              onChange={(text) => setLeadCreate({ ...leadCreate, name: text })}
              title={t("isim")}
            />
            <TextInput
              value={leadCreate.surname}
              onChange={(text) =>
                setLeadCreate({ ...leadCreate, surname: text })
              }
              title={t("soyisim")}
            />

            <GSMInput
              value={leadCreate.phone}
              onChange={(text) => setLeadCreate({ ...leadCreate, phone: text })}
              title={t("telefon")}
              placeholder={t("telefon")}
            />
            <TextInput
              value={leadCreate.email}
              onChange={(text) => setLeadCreate({ ...leadCreate, email: text })}
              title={t("email")}
              maxLength={"50"}
            />
            <TextInput
              value={leadCreate.company}
              onChange={(text) =>
                setLeadCreate({ ...leadCreate, company: text })
              }
              title={t("sirket")}
            />
            <ProjectSelection
              title={t("iliskilendirilenProje")}
              placeholder={
                (checkedCheckboxes.length > 0
                  ? `(${checkedCheckboxes.length}) `
                  : "") + `${t("projeSeciniz")}`
              }
              allProjects={allProjects}
              setCheckedCheckboxes={setCheckedCheckboxes}
              checkedCheckboxes={checkedCheckboxes}
            />
          </div>
          <div className={style.rightLeadForm}>
            <TextInput
              disabled
              placeholder={user.userType}
              title={t("kanal")}
            />
            <UserInput
              value={`${user.name} ${user.surname}`}
              title={t("olusturanPersonel")}
            />
            <TextInput
              disabled
              placeholder={new Date().toLocaleString()}
              title={t("olusturulmaTarihi")}
            />
            <Textarea
              value={leadCreate.note}
              title={t("not")}
              placeholder={t("not")}
              onChange={(text) =>
                setLeadCreate((prev) => {
                  return { ...prev, note: text };
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLead;
