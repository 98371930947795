// import { LeftButton } from "./Buttons";
import { BackIcon } from "../../assets/icons";
import style from "./subheader.module.scss";
import { useNavigate } from "react-router-dom";

const InventoryDetailCard = ({ inventoryDetailData }) => {
  const navigate = useNavigate();
  return (
    <div className={style.leadCard}>
      <button
        className={style.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </button>
      {inventoryDetailData ? (
        <h2 style={{ marginLeft: "1rem" }}>
          <strong>{inventoryDetailData.inventoryName}</strong>{" "}-{" "}
          {inventoryDetailData?.ownerProject?.projectName}
        </h2>
      ) : null}
    </div>
  );
};

const InventoryDetail = ({ inventoryDetailData }) => {
  return (
    <div className={style.leadDetailMenu}>
      <div className={style.left}>
        <InventoryDetailCard inventoryDetailData={inventoryDetailData} />
      </div>
      <div className={style.right}>
        {/* <NewButton title={"E-Mail Gönder"} /> */}
      </div>
    </div>
  );
};

export default InventoryDetail;
