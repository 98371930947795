import React from "react";
import style from "./kvklist.module.scss";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SubHeader, SideFilter } from "../../components";
import { BaseUrl } from "../../ApiConfig";
import { getAllKvk } from "../../lib/kvkApi";

import Moment from "moment";
import ReactTooltip from "react-tooltip";
import { DocumentIcon2 } from "../../assets/icons/index";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";

function KvkList() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllKvk(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize
    )
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={getToggleAllRowsSelectedProps().checked ? true : undefined}
            ischecked={getToggleAllRowsSelectedProps().checked ? true : undefined}
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "Isim",
        accessor: "name",
      },
      {
        Header: "Soyisim",
        accessor: "surname",
      },
      {
        Header: "Kullanıcı Tipi",
        accessor: "userType",
      },
      {
        Header: "Veri Gerekçesi",
        accessor: "dataProcessing",
      },
      {
        Header: "Açık Rıza Tipi",
        accessor: "channel",
      },
      {
        Header: "Oluşturulma Tarihi",
        id: "creationDate",
        accessor: (row) =>
          Moment(row.creationDate).local().format("DD.MM.YYYY"),
        show: true,
      },
      {
        Header: "İşlemler",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <ReactTooltip
              id="view"
              type="warning"
              place="bottom"
              effect="solid"
            >
              Belgeyi Görüntüle
            </ReactTooltip>
            {row?.original?.kvkkDocumation === "Döküman Eklendi" ? (
              <a
                data-tip
                data-for="view"
                className={style.icon}
                target="_blank"
                rel="noreferrer"
                href={`${BaseUrl}/public/uploads/${row.original.fileName}`}
              >
                <DocumentIcon2 />
              </a>
            ) : (
              "Döküman Bulunamadı."
            )}
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: [],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageSize: pageSize,
    }));
  }, [pageSize]);

  return (
    <div className={style.kvklist}>
      {/* <SubHeader kvkMenu kvkkData={data} /> */}
      <SubHeader kvkMenu kvkkData={data} />
      <div className={style.content}>
        <SideFilter setKvkkData={setData} setHasFilterData={setHasFilterData} />
        <Table
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default KvkList;
