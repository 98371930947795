import style from "./ProjectDetail.module.scss";
import { SubHeader, SideNav } from "../../components";
import { useParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAProject } from "../../lib/projectApi";

import { useTranslation } from "react-i18next";

const ProjectDetail = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams();
  useEffect(() => {
    getAProject(user.token, id)
      .then((res) => {
        setData(res.data.project);
      })
      .catch((err) => {
        console.log(err);
        navigate("/project-list",{replace:true});
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user.token]);

  return (
    <div className={style.leadDetail}>
      {data ? (
        <div>
          <SubHeader
            projectDetail
            projectData={data}
          />

          <div className={style.content}>
            <SideNav projectDetail />
            <div className={style.info}>
              <div className={style.leadCard}>
                <div className={style.left}>
                  {user.department === "admin" && <div className={style.card}>
                    <h2>ID</h2>
                    <p>{data._id ? data._id : ""} </p>
                  </div>}
                  <div className={style.card}>
                    <h2>{t("projeAdi")}</h2>
                    <h1>{data.projectName ? data.projectName : ""} </h1>
                  </div>
                  <div className={style.card}>
                    <h2>{t("il")}</h2>
                    <h1>{data.city ? data.city : ""} </h1>
                  </div>
                  <div className={style.card}>
                    <h2>{t("ulke")}</h2>
                    <h1>{data.country ? data.country : ""} </h1>
                  </div>
                </div>
                <div className={style.right}>
                  <div className={style.card}>
                    <h2>{t("adres")}</h2>
                    <h1>{data.address ? data.address : ""} </h1>
                  </div>
                  <div className={style.card}>
                    <h2>{t("olusturulmaTarihi")}</h2>
                    <h1>{data.createdAt ? new Date(data.createdAt).toLocaleString() : ""} </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProjectDetail;
