import style from "../ProjectCreate/projectcreate.module.scss";
import React, { useState, useEffect } from "react";
import { SubHeader } from "../../components";
import { TextInput, Textarea } from "../../components/Forms/Inputs";
import ProjectPopup from "../ProjectCreate/ProjectPopup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAProject, updateAProject } from "../../lib/projectApi";

import {useTranslation} from "react-i18next";

const ProjectEdit = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const { id } = useParams();
  const [errorText, setErrorText] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [project, setProject] = useState({
    projectName: "",
    address: "",
    city: "",
    country: "",
  });

  const _onClick = () => {
    if (project.projectName.length < 2) {
      setErrorText(t("projeEklemeErrorProjeIsmi"));
      setIsPopupOpen(true);
      return;
    } else if (project.address.length < 2) {
      setErrorText(t("projeEklemeErrorProjeAdres"));
      setIsPopupOpen(true);
      return;
    } else if (project.city.length < 2) {
      setErrorText(t("projeEklemeErrorProjeSehir"));
      setIsPopupOpen(true);
      return;
    } else if (project.country.length < 2) {
      setErrorText(t("projeEklemeErrorProjeUlke"));
      setIsPopupOpen(true);
      return;
    }

    updateAProject(user.token, id, project)
      .then((res) => {
        navigate("/project-list", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAProject(user.token, id)
      .then((res) => {
        setProject({
            projectName: res.data.project.projectName,
            address: res.data.project.address,
            city: res.data.project.city,
            country: res.data.project.country,
        });
      })
      .catch((err) => {
        console.log(err);
        navigate("/project-list", { replace: true });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPopupOpen && (
        <ProjectPopup
          closeClick={() => setIsPopupOpen(false)}
          errorText={errorText}
        />
      )}
      <SubHeader
        onClick={_onClick}
        projectEdit
        form
        newButtonName={t("degistir")}
        // buttonName="Kaydet ve Yeni Ekle"
        path="/project-list"
      />
      <div className={style.createLeadContent}>
        <div className={style.leadForm}>
          <div className={style.leftLeadForm}>
            <TextInput
              value={project.projectName}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, projectName: text };
                })
              }
              title={t("projeAdi")}
            />

            <TextInput
              value={project.city}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, city: text };
                })
              }
              title={t("il")}
            />
            <TextInput
              value={project.country}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, country: text };
                })
              }
              title={t("ulke")}
            />
          </div>
          <div className={style.rightLeadForm}>
            <Textarea
              value={project.address}
              onChange={(text) =>
                setProject((prev) => {
                  return { ...prev, address: text };
                })
              }
              title={t("adres")}
              placeholder={t("adres")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectEdit;
