import style from './createnewsaleoffer.module.scss';
import { SubHeader, NewSaleOfferForm } from '../../components';

const CreateNewSaleOffer = () => {
  return (
    <div className={style.newSaleOffer}>
      <SubHeader form />
      <div className={style.content}>
        <NewSaleOfferForm />
      </div>
    </div>
  );
};

export default CreateNewSaleOffer;
