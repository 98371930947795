import React, { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import { DocumentIcon2 } from '../../assets/icons';
import { useSelector } from 'react-redux';
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    fontFamily: 'Roboto',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 48,
    fontSize: 12,
  },
});

 function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

 var date = new Date();
  var month = pad2(date.getMonth() + 1); //months (0-11)
  var day = pad2(date.getDate() - 1); //day (1-31)
  //var yesterday = pad2(date.getDate() - 1)
  var year = date.getFullYear();
  //var yesterday = new Date((new Date()).valueOf() - 1000*60*60*24);

  var formattedDate = day + '-' + month + '-' + year;


const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      borderTop: '1 solid black',
      borderLeft: '1 solid black',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </View>
);
const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </View>
);
const TableCell = ({ children }) => (
  <View
    style={{
      flex: 1,
      borderRight: '1 solid black',
      borderBottom: '1 solid black',
      paddingLeft: 4,
      paddingTop: 5,
      paddingBottom: 5,
    }}
  >
    <Text>{children}</Text>
  </View>
);

const PDFData = ({ data, contactData }) => {
  const user = useSelector(state => state.user);
  // const nowDate = new Date();
  let userType = user.userType;

  /*
  let date =
    nowDate.getDate() +
    '.' +
    (nowDate.getMonth() + 1) +
    '.' +
    nowDate.getFullYear();
  */
  const rightOfWithdrawal = [
    //tüketicinin cayma hakkı
    {
      text: '•	  Tüketici, sözleşmenin kurulduğu tarihten itibaren on dört gün içinde, herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına sahiptir.',
    },
    {
      text: '•		Cayma hakkının kullanıldığına dair bildirimin bu süre içinde satıcıya noterlikler aracılığıyla yöneltilmesi yeterlidir. Konut bedelinin bir kısmının bağlı kredi ile karşılanması durumunda, cayma hakkının kullanıldığı bilgisi satıcı tarafından ilgili konut finansmanı kuruluşuna derhal bildirilir. ',
    },
    {
      text: '•	  Taşınmazın kısmen veya tamamen bağlı krediyle alınması durumunda bağlı kredi sözleşmesi, sözleşmenin kurulduğu tarihte hüküm doğurmak üzere cayma hakkı süresi sonunda yürürlüğe girer. Konut finansmanı kuruluşu cayma hakkı süresi içinde tüketiciden faiz, komisyon, yasal yükümlülük ve benzeri isimler altında hiçbir masraf talep edemez.',
    },
    {
      text: '•	  Taşınmaz için bina tamamlama sigortası yaptırılmış ise, sigorta teminatı, kurulduğu tarihten itibaren hüküm doğurmak üzere cayma hakkı süresi sonunda yürürlüğe girer.',
    },
    {
      text: '•	  Cayma hakkının kullanılması durumunda, tüketiciye iade edilmesi gereken tutar ve tüketiciyi borç altına sokan her türlü belge cayma bildiriminin satıcıya ulaştığı tarihten itibaren en geç on dört gün içinde tüketiciye geri verilir.',
    },
    {
      text: '•	  Satıcının aldığı bedeli ve tüketiciyi borç altına sokan her türlü belgeyi iade ettiği tarihten itibaren, tüketici on gün içinde edinimlerini iade eder.',
    },
    {
      text: '•	  Satıcı, cayma hakkı konusunda tüketicinin bilgilendirildiğini ispat etmekle yükümlüdür.',
    },
  ];
  const rightToWithdrawFromContract = [
    //tüketicinin sözleşmeden dönme hakkı
    {
      text: '•	Ön ödemeli konut satışında, devir veya teslim tarihine kadar tüketicinin herhangi bir gerekçe göstermeden sözleşmeden dönme hakkı vardır. ',
    },
    {
      text: '•	Sözleşmeden dönme bildiriminin satıcıya noterlikler aracılığıyla yöneltilmesi yeterlidir. Konut bedelinin bir kısmının bağlı kredi ile karşılanması durumunda, tüketicinin sözleşmeden dönme hakkını kullandığına ilişkin bilgi, satıcı tarafından ilgili konut finansmanı kuruluşuna derhal bildirilir. ',
    },
    {
      text: '•	Sözleşmeden dönülmesi durumunda Alıcı, Satıcının ödemiş olduğu ön ödemeli konut satışı nedeniyle oluşan vergi, harç ve benzeri yasal yükümlülüklerden doğan masrafları ile sözleşme bedelinin yüzde ikisi tutarında tazminatı Satıcıya ödemekle yükümlüdür.',
    },
    {
      text: '•	Aşağıdaki hallerde tüketiciden herhangi bir bedel talep edilemez: ',
      otherText: [
        {
          text: 'a) Satıcının yükümlülüklerini hiç ya da gereği gibi yerine getirmemesi, ',
        },
        {
          text: 'b) Tüketicinin ölmesi, ',
        },
        {
          text: 'c) Tüketicinin kazanç elde etmekten sürekli olarak yoksun kalması sebebiyle ön ödemeleri yapamayacak duruma düşmesi, ',
        },
        {
          text: 'ç) Ön ödemeli konut satışının taksitle yapılması durumunda, mevcut sözleşmenin yerine konut teslim tarihini geçmeyen ve tüketicinin mevcut sözleşmeye göre talep edilen vade farkını ödemeyi de kabul ettiği yeni bir taksitle satış sözleşmesinin kurulmasına ilişkin önerisinin, satıcı tarafından kabul edilmemesi nedeniyle sözleşmeden dönülmesi. ',
        },
      ],
    },
    {
      text: '(5) Bir konutun birden fazla tüketiciye satılması nedeniyle tüketicinin sözleşmeden dönmesi halinde, tüketiciden ön ödemeli konut satışı nedeniyle oluşan vergi, harç, masraf, tazminat ve benzeri adlar altında hiçbir bedel talep edilemez.  ',
    },
    {
      text: '(6) Sözleşmeden dönülmesi durumunda, tüketiciye iade edilmesi gereken tutar ve tüketiciyi borç altına sokan her türlü belge, dönme bildiriminin satıcıya ulaştığı tarihten itibaren en geç yüz seksen gün içinde tüketiciye geri verilir. ',
    },
    {
      text: '(7) Konut bedelinin bir kısmının bağlı kredi ile karşılanması durumunda, tüketicinin ödediği satış bedeli, satıcı tarafından dönme bildiriminin kendisine ulaştığı tarihten itibaren en geç yüz seksen gün içinde yalnızca üçüncü fıkrada belirtilen masraf ve tazminat tutarı düşülerek ilgili konut finansmanı kuruluşuna iade edilir. Konut finansmanı kuruluşu söz konusu tutarı, Kanunun 37 nci maddesine uygun olarak tüketiciye derhal geri öder. ',
    },
    {
      text: '(8) Satıcıya veya konut finansmanı kuruluşuna ödenen bedel ile tüketiciyi borç altına sokan her türlü belgenin iade edildiği tarihten itibaren, tüketici on gün içinde edinimlerini iade eder.',
    },
  ];
  const currencyFormat = value => {
    var currency_symbol = '₺';

    var formattedOutput = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 0,
    });

    return value.length
      ? formattedOutput.format(value).replace(currency_symbol, '')
      : '';
  };
  return (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
          BİLGİLENDİRME FORMU{' '}
        </Text>
        <View style={{ fontSize: 10, marginTop: 4, marginBottom: 4 }}>
          <Text>
            İşbu Bilgilendirme Formu,{' '}
            {data.inventoryId.inventoryOwnerId.company}{' '}
          </Text>
          <Text>
            Tarafından, aşağıda bilgileri yer alan tüketicinin dikkatine
            sunulmuştur.{' '}
          </Text>
        </View>
        <Table>
          <TableRow>
            <TableCell>Satıcı</TableCell>
            <TableCell>
              {data.inventoryId.inventoryOwnerId.name}{' '}
              {data.inventoryId.inventoryOwnerId.surname}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Adres</TableCell>
            <TableCell>{data.inventoryId.inventoryOwnerId.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>İrtibat Telefonu</TableCell>
            <TableCell>{data.inventoryId.inventoryOwnerId.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>İrtibat E-mail</TableCell>
            <TableCell>{data.inventoryId.inventoryOwnerId.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tüketicinin Adı Soyadı/Ünvanı </TableCell>
            <TableCell>
              {contactData.name}
              {contactData.surname} / {contactData.corporateTitle}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TCKN/Vergi No</TableCell>
            <TableCell>
              {contactData.tckn}/{contactData.taxNo}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Adres</TableCell>
            <TableCell>{contactData.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>İrtibat Telefonu</TableCell>
            <TableCell>{contactData.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>İrtibat E-mail</TableCell>
            <TableCell>{contactData.email}</TableCell>
          </TableRow>
        </Table>
        <Text style={{ marginTop: 20 }}>BAĞIMSIZ BÖLÜM BİLGİLERİ</Text>
        <Table>
          <TableRow>
            <TableCell>Ada</TableCell>
            <TableCell>{data.inventoryId.island}</TableCell>
            <TableCell>Parsel</TableCell>
            <TableCell>{data.inventoryId.parsel}</TableCell>
            <TableCell>Pafta</TableCell>
            <TableCell>{data.inventoryId.pafta}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Blok</TableCell>
            <TableCell>{data.inventoryId.blockNo}</TableCell>
            <TableCell>Bağımsız Bölüm No</TableCell>
            <TableCell>{data.inventoryId.independentNo}</TableCell>
            <TableCell>Kat</TableCell>
            <TableCell>{data.inventoryId.floor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Brüt m2</TableCell>
            <TableCell>{data.inventoryId.brutM2} m2</TableCell>
            <TableCell>Net m2</TableCell>
            <TableCell>{data.inventoryId.netM2} m2</TableCell>
            <TableCell>Bahçe m2</TableCell>
            <TableCell>{data.inventoryId.gardenM2} m2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>KDV Dahil Satış Tutarı</TableCell>
            <TableCell>
              {currencyFormat(`${data.bidAmountWithVat}`)} TL
            </TableCell>
            <TableCell>Oda Sayısı</TableCell>
            <TableCell>{data.inventoryId.numberRooms}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>KDV Hariç Satış Tutarı Bedeli</TableCell>
            <TableCell>{currencyFormat(`${data.bidAmount}`)} TL</TableCell>
          </TableRow>
          {/* {userType !== 'Bireysel' ? (
            <TableRow>
              <TableCell>İndirim Oranı</TableCell>
              <TableCell>% {Number(data.discountRate).toFixed(0)}</TableCell>
            </TableRow>
          ) : null} */}
          <TableRow>
            <TableCell></TableCell>
            <TableCell>ÖDEME TUTARI</TableCell>
            <TableCell>TARİH</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>KAPORA</TableCell>
            <TableCell>
              {' '}
              {currencyFormat(`${data.advancePayment}`)} TL
            </TableCell>
            <TableCell>{data.advancePaymentDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PEŞİNAT</TableCell>
            <TableCell>{currencyFormat(`${data.downPaymnet}`)} TL</TableCell>
            <TableCell>{data.downPaymnetDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>VADELİ PEŞİNAT</TableCell>
            <TableCell>
              {currencyFormat(`${data.termDownPaymnet}`)} TL
            </TableCell>
            <TableCell>{data.termDownPaymnetDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>BANKA KREDİSİ </TableCell>
            <TableCell>{currencyFormat(`${data.bankCredit}`)} TL</TableCell>
            <TableCell>{data.bankCreditDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Senet Tutarı</TableCell>
            <TableCell>{currencyFormat(`${data.termDownPaymnet}`)} TL</TableCell>
            <TableCell>Senet Adedi</TableCell>
            <TableCell>{data.billLength[1]}</TableCell>
          </TableRow>
          {userType !== 'Bireysel' ? (
            <TableRow>
              <TableCell>Teslime İlişkin Diğer Masraflar</TableCell>
              <TableCell>
                {currencyFormat(`${data.otherExpenses}`)} TL
              </TableCell>
            </TableRow>
          ) : null}
          {/* {userType !== 'Bireysel' ? (
            <TableRow>
              <TableCell>
                Teminat / Bina Tamamlama Sigortası Bilgileri{' '}
              </TableCell>
              <TableCell>{data.interestAmount}</TableCell>
            </TableRow>
          ) : null} */}
          <TableRow>
            <TableCell>Teslim Tarihi</TableCell>
            <TableCell>{data.deliveryTime}</TableCell>
          </TableRow>
        </Table>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          TÜKETİCİNİN CAYMA HAKKI
        </Text>
        {rightOfWithdrawal.map(text => {
          return <Text>{text.text}</Text>;
        })}
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          TÜKETİCİNİN SÖZLEŞMEDEN DÖNME HAKKI
        </Text>
        {rightToWithdrawFromContract.map(text => {
          return (
            <Text>
              <Text>{text.text}</Text>
              {text.otherText
                ? text.otherText.map(text => {
                    return <Text style={{ width: '100%' }}>{text.text}</Text>;
                  })
                : null}
            </Text>
          );
        })}
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          YÖNETİM PLANINA UYGUN OLARAK GENEL GİDERLERE KATILIMA İLİŞKİN
          BİLGİLER:
        </Text>
        <Text>
          {contactData.name}
          {contactData.surname},{data.inventoryId.inventoryOwnerId.name}{' '}
          {data.inventoryId.inventoryOwnerId.surname} tarafından hazırlanacak
          yönetim planına uymayı, taraflar arasında sözleşme imzalanması halinde
          sözleşme hükümlerine uygun olarak konut fiili tesliminden itibaren
          ortak giderlere katılacaktır. Kat mülkiyetine geçilmemiş olsa dahi,
          Kat mülkiyeti kanunu’ nun tüm hükümlerine ve site yönetim planına
          riayet etmek, işletme projesinin uygulamaya konulmasına kadar süre
          içerisinde gider avanslarına katılmakla yükümlüdür.
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          KONUT TESLİM TARİHİ
        </Text>
        <Text>
          Konutun teslim tarihi{' '}
          {data.deliveryTime ? data.deliveryTime : '--/--/--'} ’dir. SATICI
          konut teslimini taraflar arasında sözleşme imzalanması halinde
          sözleşme hükümlerine uygun olarak 6 ay daha uzatma hakkına sahiptir.
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          ALICIYA VERİLECEK TEMİNATA İLİŞKİN BİLGİLER
        </Text>
        <Text>
          Satıcı , taşınmazın {contactData.name}
          {contactData.surname}’ ya devir ve teslim edilmesinin teminatı olarak
          6502 sayılı Kanun ve Ön Ödemeli Konut Satışları Hakkında Yönetmelikte
          öngörülen istisnalar saklı kalmak üzere, teminatı olarak mevzuatça
          öngörülen sistemlerden HAKEDİŞ SİSTEMİNİ seçmiştir. Buna göre, iş bu
          sözleşmeye göre yapılacak ödemeler Hakediş Sistemi Hesabı na
          yatırılacaktır. {data.inventoryId.inventoryOwnerId.name}{' '}
          {data.inventoryId.inventoryOwnerId.surname} gerekli gördüğünde
          teminatı yasada ön görülen diğer teminatlardan birisiyle
          değiştirebilir
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          EKLER
        </Text>
        <Text>
          Taraflar arasında imzalanacak satış vaadi sözleşmesine konu bağımsız
          bölümün planı, Vaziyet Planı ve Kat Planı Mahal Listesi Yukarıda
          belirtilen ekler, iş bu ön bilgilendirme formunun ayrılmaz bir
          parçasıdır. İş bu Bilgilendirme Formu 2 nüsha olarak düzenlenmiş olup,
          ıslak imzalı bir nüshası ekte yer alan bağımsız bölüm planı, vaziyet
          planı, kat planı ve mahal listesi ile birlikte Tüketiciye
          bilgilendirme amaçlı verilmiştir.
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>
          ALICI BEYANI
        </Text>
        <Text>
          İş Bu {formattedDate} tarihli ön bilgilendirme formunun ve eklerinin bir
          örneğini teslim aldığımı, satış vaadi sözleşmesi imzalamak istediğim
          bağımsız bölüme , projeye, satış koşullarına ilişkin olarak detaylı
          bilgilendirildiğimi, bilgi sahibi olduğumu kabul, beyan ve taahhüt
          ederim..
        </Text>
        <Text style={{ marginTop: 20 }}>
          SATICI :{data.inventoryId.inventoryOwnerId.name}{' '}
          {data.inventoryId.inventoryOwnerId.surname}
        </Text>
        <Text>İmza</Text>
        <Text style={{ marginTop: 20 }}>
          TÜKETİCİ :{contactData.name} {contactData.surname}
        </Text>
        <Text>İmza</Text>
        <Text style={{ marginTop: 20 }}>TARİH : {formattedDate}</Text>
      </Page>
    </Document>
  );
};
function PDF({ data, contactData }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={event => {
          event.stopPropagation();
          setOpen(true);
        }}
        style={{ margin: '0 10px' }}
      >
        {isOpen ? (
          <PDFDownloadLink
            document={<PDFData data={data} contactData={contactData} />}
            fileName={`${data.bidName}_bilgilendirmeformu.pdf`}
          >
            Bilgilendirme İndir
          </PDFDownloadLink>
        ) : null}
        {isOpen ? null : <DocumentIcon2 />}
      </button>
    </>
  );
}

export default PDF;
