import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateRandomColors } from "../../utils/general";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
const colors = generateRandomColors(6);

const BidDailyStage = ({ activityStage, period }) => {
  const { t } = useTranslation();

  const aProjects = activityStage || {};
  const keys = Object.keys(aProjects).map(
    (key) => `${key} - ${aProjects[key]}`
  );
  const values = Object.values(aProjects);

  const data = {
    labels: keys,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        datalabels: {
          color: "blue",
          font: {
            weight: "bold",
            size: 18,
          },
          anchor: "end",
          align: "start",
          offset: 10,
          formatter: (value, context) => {
            return value;
          },
        },
      },
    ],
  };
  return (
    <Card>
      <CardContent>
        <Box sx={{ textAlign: "center", marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t("dailyBidResponse", { period: t(period) })}
          </Typography>
          <Box sx={{ display: "inline-block", width: "auto", height: 450 }}>
            {Object.keys(aProjects).length !== 0 ? (
              <>
                <Pie
                  data={data}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: { display: true, position: "bottom" },
                      datalabels: { display: true },
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            return tooltipItem.label;
                          },
                        },
                      },
                    },
                  }}
                />{" "}
              </>
            ) : (
              <Typography variant="h6" gutterBottom sx={{ color: "red" }}>
                {t("noActivity")}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { BidDailyStage };
