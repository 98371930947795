import React from "react";
import style from "./inventoryedit.module.scss";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { SubHeader } from "../../components";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiConfig";
import {
  TextInput,
  DropInput2,
  DocumentInputIMG,
  NumberInput,
  DropInputJSONProject,
  NumberInputM2,
  DocumentInput3,
  DisabledNumberInput,
  Textarea,
  DateInput,
} from "../../components/Forms/Inputs";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function InventoryEdit() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  let userType = user.userType;
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState();
  const [allProject, setAllProject] = useState();
  const [inventoryOwner, setInventoryOwner] = useState();
  const [bids, setBids] = useState();
  const [independentSectionPlan, setIndependentSectionPlan] = useState();
  const [situationPlan, setSituationPlan] = useState();
  const [floorPlan, setFloorPlan] = useState();
  const [locusPlan, setLocusPlan] = useState();
  const [inventoryowners, setInventoryowners] = useState([]);
  const [projectImage, setProjectImage] = useState();
  const [inventory, setInventory] = useState({
    address: "",
    country: "",
    city: "",
    inventoryName: "",
    inventoryUseShape: "",
    vatRate: 0,
    numberRooms: "",
    Landscape: "",
    Direction: "",
    island: "",
    parsel: "",
    pafta: "",
    blockNo: "",
    floor: "",
    independentNo: "",
    circleType: "",
    brutM2: "",
    netM2: "",
    gardenM2: "",
    poolM2: "",
    terraceM2: "",
    balconyM2: "",
    currency: "",
    salesPriceM2: 0,
    salesRentM2: 0,
    excludingVatListPrice: 0,
    withVatListPrice: 0,
    rentPrice: 0,
    inventoryOwnerId: "",
    ownerProject: "",
    status: "",
    exteriorDoorNo: "",
    inventoryCategory: "",
    //files
    independentSectionPlanFileName: "",
    situationPlanFileName: "",
    floorPlanFileName: "",
    locusPlanFileName: "",

    //kurumsal alan
    corporateStatus: "", // kuumsal statü
    buildingName: "", //bina adı
    buildingOpeningDate: "", //bina açılış tarihi
    developer: "", //geliştirici
    floorAboveGroundTotalLength: "", //zemin üzeri toplam kat sayısı
    floorTotalLength: "", //toplam kat sayısı
    constructionZone: "", //İnşaat alanı
    totalOfficeSpace: "", //toplam ofis alanı
    totalStoreSpace: "", //toplam dükkan alanı
    ceilingHeight: "", //tavan yüksekliği brüt
    productivity: "", //verimlilik
    storeOptions: "", //dükkan seçenekleri
    floorOptions: "", //kat seçenekleri
    deliveryShape: "", //teslim şekli
    rentFreePeriod: "", //Kirasız dönem
    taxStatus: "", //vergi durumu
    dues: "", //aidat
    duesTotalM2: "", // metrekare toplam aidat
    parkingRight: "", //Otopark hakkı
    airConditioning: "", //iklimlendirme
    mechanicalDetail: "", //mekanik ayrıntı
    numberOfElevators: "", //asansor sayısı
    transportation: "", //ulaşım
    socialOpportunities: "", //sosyal olanaklar
    website: "", //websitesi
    note: "", //not
  });
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getOneInventory/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setInventoryOwner(res.data.inventory.inventoryOwnerId._id);
        setInventory(res.data.inventory);
        setProject(res.data.inventory.ownerProject);
        setBids(res.data.inventory.bids);
      })
      .catch((err) => {
        console.log(err);
        return navigate("/inventory-list", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getAll/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setAllProject(res.data.project);
      })
      .catch((err) => console.log(err.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const userType = user.userType;
    axios
      .get(`${BaseUrl}/api/contact/getallInventory/${userType}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        // setInventoryOwner(contact);

        res.data.contact.forEach((element) => {
          if (element.shape === "Kiralayan" || element.shape === "Satıcı") {
            setInventoryowners((prevState) => [
              ...prevState,
              {
                name: element.name + " " + element.surname,
                value: element._id,
              },
            ]);
          }
        });
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const kdv = () => {
      var fiyat = inventory.excludingVatListPrice;
      var kdv = inventory.vatRate;
      var toplamTutar = parseFloat(fiyat) + (fiyat * kdv) / 100;

      return setInventory((prev) => {
        return { ...prev, withVatListPrice: toplamTutar };
      });
    };
    kdv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory?.excludingVatListPrice]);
  //console.log(inventory?.corporateStatus);
  const onSubmit = () => {
    let formData = new FormData();
    formData.append("independentSectionPlan", independentSectionPlan);
    formData.append("situationPlan", situationPlan);
    formData.append("floorPlan", floorPlan);
    formData.append("locusPlan", locusPlan);
    formData.append("projectImage", projectImage);
    formData.append("inventory", JSON.stringify(inventory));
    axios
      .post(`${BaseUrl}/api/project/editInventory/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          navigate("/inventory-list", { replace: true });
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return " ₺";
    } else if (value === "USD") {
      return " $";
    } else if (value === "EUR") {
      return " €";
    } else if (value === "GBP") {
      return " £";
    } else if (value === "RUB") {
      return " ₽";
    } else {
      return " ₺";
    }
  };

  return (
    <>
      <SubHeader
        editInventory
        form
        onClick={onSubmit}
        path="/inventory-list"
        newButtonName={t("degistir")}
      />
      {inventory && bids && bids.length > 0 ? (
        <div className={style.box}>
          <h1>{t("lastBid")}</h1>
          {bids ? (
            <div className={style.textBox}>
              <p>
                {bids[0].contactId.name} {bids[0].contactId.surname}
              </p>
              <p>{bids[0].bidStage}</p>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={style.editInventoryContent}>
        <div className={style.editInventory}>
          <div className={style.leftInventoryEdit}>
            <DropInputJSONProject
              not
              data={
                allProject
                  ? allProject.map((item) => {
                      return {
                        value: {
                          id: item._id,
                          name: item.projectName,
                          city: item.city,
                          country: item.country,
                          address: item.address,
                        },
                        name: item.projectName,
                      };
                    })
                  : null
              }
              title={t("projeAdi")}
              placeholder={project ? project.projectName : null}
              setDropValue={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    city: JSON.parse(text).city,
                    country: JSON.parse(text).country,
                    address: JSON.parse(text).address,
                    ownerProject: JSON.parse(text).id,
                  };
                });
                setProject({
                  projectName: JSON.parse(text).name,
                  id: JSON.parse(text).id,
                });
              }}
            />
            <TextInput
              title={t("uniteIsmi")}
              placeholder={t("uniteIsmi")}
              value={inventory.inventoryName}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, inventoryName: text };
                })
              }
            />
            <DropInput2
              data={[
                { name: t("satisaAcikStok"), value: "Satışa Açık (Stok)" },
                {
                  name: t("satisaAcikOpsiyonlu"),
                  value: "Satışa Açık (Opsiyonlu)",
                },
                /*
                {
                  name: t("satisaAcikKaporali"),
                  value: "Satışa Açık (Kaparolu)",
                },
                */
                {
                  name: t("satisaKapaliKaparoli"),
                  value: "Satış (Kaparo)",
                },
                {
                  name: t("satisaKapaliSozlesmeli"),
                  value: "Satış (Sözleşme)",
                },
                {
                  name: t("satisaKapaliGorusmeAsamasi"),
                  value: "Satış (Görüşme Aşamasında)",
                },
                /*{
                  name: t("satisaKapaliOpsiyon"),
                  value: "Satış (Opsiyon)",
                },*/
                {
                  name: t("satisOnay"),
                  value: "Satış Onay",
                },
                { name: t("satisaKapali"), value: "Satışa Kapalı" },
                { name: t("barter"), value: "Barter" },
              ]}
              isDefault={inventory.status === "" ? "asda" : inventory.status}
              title={t("uniteSunumSekli")}
              placeholder={t("uniteSunumSekli")}
              setDropValue={(text) => {
                setInventory((prev) => {
                  return { ...prev, status: text };
                });
              }}
            />

            <TextInput
              value={inventory.circleType}
              title={t("daireTipi")}
              placeholder={t("daireTipi")}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, circleType: text };
                })
              }
            />
            <TextInput
              title={t("odaSayisi")}
              placeholder={t("odaSayisi")}
              value={inventory.numberRooms}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, numberRooms: text };
                })
              }
            />
            <NumberInputM2
              value={inventory.balconyM2}
              title={t("balkoMetrekare")}
              placeholder={t("balkoMetrekare")}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    balconyM2: text,
                  };
                });
              }}
            />
            {/* <DropInput2
              data={[
                { name: "Konut", value: "Konut" },
                { name: "Ofis", value: "Ofis" },
                { name: "Konut veya Ofis", value: "Konut veya Ofis" },
              ]}
              isDefault={inventory.inventoryUseShape === "" ? "asda" : inventory.inventoryUseShape}
              title="Kullanım Şekli"
              placeholder={inventory.inventoryUseShape}
              setDropValue={(text) => {
                setInventory((prev) => {
                  return { ...prev, inventoryUseShape: text };
                });
              }}
            /> */}
            {userType === "Kurumsal" ? (
              <>
                <TextInput
                  title={t("teslimSekli")}
                  placeholder={t("teslimSekli")}
                  value={inventory.deliveryShape}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, deliveryShape: text };
                    })
                  }
                />
                <TextInput
                  title={t("binaAdi")}
                  placeholder={t("binaAdi")}
                  value={inventory.buildingName}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, buildingName: text };
                    })
                  }
                />
                <DateInput
                  value={inventory.buildingOpeningDate}
                  title={t("binaAcilisTarihi")}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, buildingOpeningDate: text };
                    })
                  }
                />
                <TextInput
                  title={t("websitesi")}
                  placeholder={t("websitesi")}
                  value={inventory.website}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, website: text };
                    })
                  }
                />
                <TextInput
                  title={t("ulasim")}
                  placeholder={t("ulasim")}
                  value={inventory.transportation}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, transportation: text };
                    })
                  }
                />

                <TextInput
                  title={t("sosyalOlanaklar")}
                  placeholder={t("sosyalOlanaklar")}
                  value={inventory.socialOpportunities}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, socialOpportunities: text };
                    })
                  }
                />
                <TextInput
                  title={t("gelistirici")}
                  placeholder={t("gelistirici")}
                  value={inventory.developer}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, developer: text };
                    })
                  }
                />
                <TextInput
                  title={t("insaatAlani")}
                  placeholder={t("insaatAlani")}
                  value={inventory.constructionZone}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, constructionZone: text };
                    })
                  }
                />
                <TextInput
                  title={t("verimlilik")}
                  placeholder={t("verimlilik")}
                  value={inventory.productivity}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return {
                        ...prev,
                        productivity: text,
                      };
                    })
                  }
                />
                {inventory.corporateStatus === "ofis" ? (
                  <>
                    <DropInput2
                      data={[
                        { name: t("var"), value: "Var" },
                        { name: t("yok"), value: "Yok" },
                      ]}
                      isDefault={
                        inventory.airConditioning === ""
                          ? "asda"
                          : inventory.airConditioning
                      }
                      title={t("iklimlendirme")}
                      placeholder={t("iklimlendirme")}
                      setDropValue={(text) => {
                        setInventory((prev) => {
                          return { ...prev, airConditioning: text };
                        });
                      }}
                    />
                    <TextInput
                      title={t("asansorSayisi")}
                      placeholder={t("asansorSayisi")}
                      value={inventory.numberOfElevators}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            numberOfElevators: text,
                          };
                        })
                      }
                    />
                    <TextInput
                      title={t("zeminUzeriToplamKatSayisi")}
                      placeholder={t("zeminUzeriToplamKatSayisi")}
                      value={inventory.floorAboveGroundTotalLength}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            floorAboveGroundTotalLength: text,
                          };
                        })
                      }
                    />
                    <NumberInputM2
                      title={t("toplamOfisAlani")}
                      placeholder={t("toplamOfisAlani")}
                      value={inventory.totalOfficeSpace}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            totalOfficeSpace: text,
                          };
                        });
                      }}
                    />
                    <TextInput
                      title={t("katSecenekleri")}
                      placeholder={t("katSecenekleri")}
                      value={inventory.floorOptions}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            floorOptions: text,
                          };
                        })
                      }
                    />
                  </>
                ) : inventory.corporateStatus === "dukkan" ? (
                  <>
                    <TextInput
                      title={t("toplamKatSayisi")}
                      placeholder={t("toplamKatSayisi")}
                      value={inventory.floorTotalLength}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, floorTotalLength: text };
                        })
                      }
                    />
                    <NumberInputM2
                      title={t("toplamDukkanAlani")}
                      placeholder={t("toplamDukkanAlani")}
                      value={inventory.totalStoreSpace}
                      onChange={(text) => {
                        setInventory((prev) => {
                          return {
                            ...prev,
                            totalStoreSpace: text,
                          };
                        });
                      }}
                    />
                    <TextInput
                      title={t("dukkanSecenekleri")}
                      placeholder={t("dukkanSecenekleri")}
                      value={inventory.storeOptions}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return { ...prev, storeOptions: text };
                        })
                      }
                    />
                    <TextInput
                      title={t("mekanikAyrinti")}
                      placeholder={t("mekanikAyrinti")}
                      value={inventory.mechanicalDetail}
                      onChange={(text) =>
                        setInventory((prev) => {
                          return {
                            ...prev,
                            mechanicalDetail: text,
                          };
                        })
                      }
                    />
                  </>
                ) : null}
                <TextInput
                  title={t("tavanYuksekligi")}
                  placeholder={t("tavanYuksekligi")}
                  value={inventory.ceilingHeight}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return {
                        ...prev,
                        ceilingHeight: text,
                      };
                    })
                  }
                />
              </>
            ) : null}
            <TextInput
              title={t("ada")}
              placeholder={t("ada")}
              value={inventory.island}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, island: text };
                })
              }
            />
            <TextInput
              title={t("parsel")}
              placeholder={t("parsel")}
              value={inventory.parsel}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, parsel: text };
                })
              }
            />
            <TextInput
              title={t("pafta")}
              placeholder={t("pafta")}
              value={inventory.pafta}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, pafta: text };
                })
              }
            />
            <TextInput
              title={t("blokNo")}
              placeholder={t("blokNo")}
              value={inventory.blockNo}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, blockNo: text };
                })
              }
            />
            <TextInput
              title={t("disKapiNo")}
              placeholder={t("disKapiNo")}
              value={inventory.exteriorDoorNo}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, exteriorDoorNo: text };
                })
              }
            />
            <TextInput
              title={t("kat")}
              placeholder={t("kat")}
              value={inventory.floor}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, floor: text };
                })
              }
            />
            <TextInput
              title={t("bagimsizBolumNo")}
              placeholder={t("bagimsizBolumNo")}
              value={inventory.independentNo}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, independentNo: text };
                })
              }
            />
            <Textarea
              value={inventory.address}
              title={t("adres")}
              placeholder={t("adres")}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, address: text };
                })
              }
            />
            <TextInput
              value={inventory.city}
              title={t("il")}
              placeholder={t("il")}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, city: text };
                })
              }
            />
            <TextInput
              value={inventory.country}
              title={t("ulke")}
              placeholder={t("ulke")}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, country: text };
                })
              }
            />
          </div>
          <div className={style.rightInventoryEdit}>
            <TextInput
              title={t("manzara")}
              placeholder={t("manzara")}
              value={inventory.Landscape}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, Landscape: text };
                })
              }
            />
            <TextInput
              title={t("yon")}
              placeholder={t("yon")}
              value={inventory.Direction}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, Direction: text };
                })
              }
            />
            <NumberInputM2
              title={t("satisBrutMetrekare")}
              placeholder={t("satisBrutMetrekare")}
              value={inventory.brutM2}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    brutM2: text,
                  };
                });
              }}
            />
            <NumberInputM2
              value={inventory.flatBrutM2}
              title={t("daireBrutMetrekare")}
              placeholder={t("daireBrutMetrekare")}
              onChange={(text) => {}}
            />
            <NumberInputM2
              value={inventory.floorBrutM2}
              title={t("katBrutMetrekare")}
              placeholder={t("katBrutMetrekare")}
              onChange={(text) => {}}
            />
            <NumberInputM2
              value={inventory.netM2}
              title={t("netMetrekare")}
              placeholder={t("netMetrekare")}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    netM2: text,
                  };
                });
              }}
            />
            <NumberInputM2
              value={inventory.gardenM2}
              title={t("bahceMetrekare")}
              placeholder={t("bahceMetrekare")}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    gardenM2: text,
                  };
                });
              }}
            />
            <NumberInputM2
              value={inventory.poolM2}
              title={t("havuzMetrekare")}
              placeholder={t("havuzMetrekare")}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    poolM2: text,
                  };
                });
              }}
            />
            <NumberInputM2
              value={inventory.terraceM2}
              title={t("terasMetrekare")}
              placeholder={t("terasMetrekare")}
              onChange={(text) => {
                setInventory((prev) => {
                  return {
                    ...prev,
                    terraceM2: text,
                  };
                });
              }}
            />
            {userType === "Kurumsal" ? (
              <>
                <TextInput
                  title={t("otoparkHakki")}
                  placeholder={t("otoparkHakki")}
                  value={inventory.parkingRight}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, parkingRight: text };
                    })
                  }
                />
                <TextInput
                  title={t("vergiDurumu")}
                  placeholder={t("vergiDurumu")}
                  value={inventory.taxStatus}
                  onChange={(text) =>
                    setInventory((prev) => {
                      return { ...prev, taxStatus: text };
                    })
                  }
                />
              </>
            ) : null}

            {(inventory.inventoryCategory === "satılık" ||
              inventory.inventoryCategory === "kiralık" ||
              inventory.inventoryCategory === "satılıkveyakiralık") && (
              <DropInput2
                isDefault={
                  inventory?.currency === "" ? "TRY" : inventory?.currency
                }
                data={[
                  { name: t("try"), value: "TRY" },
                  { name: t("usd"), value: "USD" },
                  { name: t("eur"), value: "EUR" },
                  { name: t("gbp"), value: "GBP" },
                  { name: t("rub"), value: "RUB" },
                ]}
                title={t("dovizBirimi")}
                placeholder={t("dovizBirimi")}
                setDropValue={(text) => {
                  setInventory((prev) => {
                    return { ...prev, currency: text };
                  });
                }}
              />
            )}

            {inventory.inventoryCategory === "satılık" ? (
              <NumberInput
                suffix={currencyConvert(inventory.currency)}
                value={inventory.salesPriceM2}
                title={t("metrekareBirimSatisFiyati")}
                placeholder={t("metrekareBirimSatisFiyati")}
                onChange={(text) => {
                  setInventory((prev) => {
                    return {
                      ...prev,
                      salesPriceM2: text,
                    };
                  });
                }}
              />
            ) : inventory.inventoryCategory === "kiralık" ? (
              <NumberInput
                suffix={currencyConvert(inventory.currency)}
                value={inventory.salesRentM2}
                title={t("metrekareBirimKiraBedeli")}
                placeholder={t("metrekareBirimKiraBedeli")}
                onChange={(text) => {
                  setInventory((prev) => {
                    return {
                      ...prev,
                      salesRentM2: text,
                    };
                  });
                }}
              />
            ) : inventory.inventoryCategory === "satılıkveyakiralık" ? (
              <>
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.salesPriceM2}
                  title={t("metrekareBirimSatisBedeli")}
                  placeholder={t("metrekareBirimSatisBedeli")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        salesPriceM2: text,
                      };
                    });
                  }}
                />
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.salesRentM2}
                  title={t("metrekareBirimKiraBedeli")}
                  placeholder={t("metrekareBirimKiraBedeli")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        salesRentM2: text,
                      };
                    });
                  }}
                />
              </>
            ) : null}
            {inventory.inventoryCategory === "satılık" ? (
              <>
                <NumberInput
                  prefix={"%"}
                  title={t("kdvOrani")}
                  placeholder={t("kdvOrani")}
                  value={Number(inventory.vatRate)}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        vatRate: Number(text),
                      };
                    });
                  }}
                />
                {/*
                <DropInput2
                  click={() =>
                    setInventory((prev) => {
                      return {
                        ...prev,
                        excludingVatListPrice: 0,
                        withVatListPrice: 0,
                      };
                    })
                  }
                  data={[
                    { name: "%1", value: 1 },
                    { name: "%8", value: 8 },
                    { name: "%10", value: 10 },
                    { name: "%18", value: 18 },
                    { name: "%20", value: 20 },
                  ]}
                  isDefault={
                    inventory.vatRate === ""
                      ? "asda"
                      : parseInt(inventory.vatRate)
                  }
                  title={t("kdvOrani")}
                  placeholder={t("kdvOrani")}
                  setDropValue={(text) => {
                    setInventory((prev) => {
                      return { ...prev, vatRate: Number(text) };
                    });
                  }}
                />
                */}
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.excludingVatListPrice}
                  title={t("kdvHaricListeFiyati")}
                  placeholder={t("kdvHaricListeFiyati")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        excludingVatListPrice: text,
                      };
                    });
                  }}
                />
                <DisabledNumberInput
                  disabled
                  suffix={currencyConvert(inventory.currency)}
                  title={t("kdvDahilSatisFiyati")}
                  value={inventory.withVatListPrice}
                  placeholder={t("kdvDahilSatisFiyati")}
                />
              </>
            ) : inventory.inventoryCategory === "kiralık" ? (
              <NumberInput
                suffix={currencyConvert(inventory.currency)}
                value={inventory.rentPrice}
                title={t("kiraBedeli")}
                placeholder={t("kiraBedeli")}
                onChange={(text) => {
                  setInventory((prev) => {
                    return {
                      ...prev,
                      rentPrice: text,
                    };
                  });
                }}
              />
            ) : inventory.inventoryCategory === "satılıkveyakiralık" ? (
              <>
                <NumberInput
                  prefix={"%"}
                  title={t("kdvOrani")}
                  placeholder={t("kdvOrani")}
                  value={Number(inventory.vatRate)}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        vatRate: Number(text),
                      };
                    });
                  }}
                />
                {/*
                <DropInput2
                  data={[
                    { name: "%1", value: 1 },
                    { name: "%8", value: 8 },
                    { name: "%10", value: 10 },
                    { name: "%18", value: 18 },
                    { name: "%20", value: 20 },
                  ]}
                  title={t("kdvOrani")}
                  placeholder={t("kdvOrani")}
                  isDefault={
                    inventory.vatRate === ""
                      ? "asda"
                      : parseInt(inventory.vatRate)
                  }
                  setDropValue={(text) => {
                    setInventory((prev) => {
                      return { ...prev, vatRate: Number(text) };
                    });
                  }}
                />
                */}
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.excludingVatListPrice}
                  title={t("kdvHaricListeFiyati")}
                  placeholder={t("kdvHaricListeFiyati")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        excludingVatListPrice: text,
                      };
                    });
                  }}
                />
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.withVatListPrice}
                  title={t("kdvDahilSatisFiyati")}
                  placeholder={t("kdvDahilSatisFiyati")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        withVatListPrice: text,
                      };
                    });
                  }}
                />
                <NumberInput
                  suffix={currencyConvert(inventory.currency)}
                  value={inventory.rentPrice}
                  title={t("kiraBedeli")}
                  placeholder={t("kiraBedeli")}
                  onChange={(text) => {
                    setInventory((prev) => {
                      return {
                        ...prev,
                        rentPrice: text,
                      };
                    });
                  }}
                />
              </>
            ) : null}
            {inventory.inventoryCategory === "kiralık" ||
            inventory.inventoryCategory === "satılıkveyakiralık" ? (
              userType === "Kurumsal" ? (
                <>
                  <NumberInput
                    suffix={currencyConvert(inventory.currency)}
                    title={t("aidat")}
                    placeholder={t("aidat")}
                    value={inventory.dues}
                    onChange={(text) => {
                      setInventory((prev) => {
                        return {
                          ...prev,
                          dues: text,
                        };
                      });
                    }}
                  />
                  <NumberInputM2
                    title={t("toplamMetrekareAidat")}
                    placeholder={t("toplamMetrekareAidat")}
                    value={inventory.duesTotalM2}
                    onChange={(text) => {
                      setInventory((prev) => {
                        return {
                          ...prev,
                          duesTotalM2: text,
                        };
                      });
                    }}
                  />
                  <TextInput
                    value={inventory.rentFreePeriod}
                    title={t("kirasizDonem")}
                    placeholder={t("kirasizDonem")}
                    onChange={(text) =>
                      setInventory((prev) => {
                        return { ...prev, rentFreePeriod: text };
                      })
                    }
                  />
                </>
              ) : null
            ) : null}
            <DropInput2
              data={inventoryowners}
              setDropValue={(text) => {
                setInventory((prev) => {
                  return { ...prev, inventoryOwnerId: text };
                });
              }}
              title={t("malSahibi")}
              placeholder={t("malSahibi")}
              isDefault={inventoryOwner === "" ? "asda" : inventoryOwner}
            />
            <DocumentInput3
              placeholder={inventory.independentSectionPlanFileName}
              id={"independentSectionPlan"}
              setDocumentFile={setIndependentSectionPlan}
              documentFile={independentSectionPlan}
              title={t("bagimsizBolumPlani")}
              title2={t("bagimsizBolumPlaniYukleyiniz")}
            />
            {inventory.independentSectionPlanFileName ? (
              <Link
                target="_blank"
                key={inventory.independentSectionPlanFileName}
                className={style.editDownloadLink}
                to={`/public/uploads/${inventory.independentSectionPlanFileName}`}
              >
                {t("belgeIndir")}
              </Link>
            ) : null}
            <DocumentInput3
              placeholder={inventory.situationPlanFileName}
              id={"situationPlan"}
              setDocumentFile={setSituationPlan}
              documentFile={situationPlan}
              title={t("vaziyetPlani")}
              title2={t("vaziyetPlaniYukleyiniz")}
            />
            {inventory.situationPlanFileName ? (
              <Link
                target="_blank"
                key={inventory.situationPlanFileName}
                className={style.editDownloadLink}
                to={`/public/uploads/${inventory.situationPlanFileName}`}
              >
                {t("belgeIndir")}
              </Link>
            ) : null}
            <DocumentInput3
              placeholder={inventory.floorPlanFileName}
              id={"floorPlan"}
              setDocumentFile={setFloorPlan}
              documentFile={floorPlan}
              title={t("katPlani")}
              title2={t("katPlaniYukleyiniz")}
            />
            {inventory.floorPlanFileName ? (
              <Link
                target="_blank"
                key={inventory.floorPlanFileName}
                className={style.editDownloadLink}
                to={`/public/uploads/${inventory.floorPlanFileName}`}
              >
                {t("belgeIndir")}
              </Link>
            ) : null}
            <DocumentInput3
              id={"locusPlan"}
              placeholder={inventory.locusPlanFileName}
              setDocumentFile={setLocusPlan}
              documentFile={locusPlan}
              title={t("mahalListesi")}
              title2={t("mahalListesiYukleyiniz")}
            />
            {inventory.locusPlanFileName ? (
              <Link
                target="_blank"
                key={inventory.locusPlanFileName}
                className={style.editDownloadLink}
                to={`/public/uploads/${inventory.locusPlanFileName}`}
              >
                {t("belgeIndir")}
              </Link>
            ) : null}
            <DocumentInputIMG
              title={t("projeGorseli")}
              title2={t("projeGorseliYukleyiniz")}
              placeholder={inventory.projectImage}
              documentFile={projectImage}
              setDocumentFile={setProjectImage}
              id="projectImage"
            />
            {inventory.projectImage ? (
              <Link
                target="_blank"
                key={inventory.projectImage}
                className={style.editDownloadLink}
                to={`/public/uploads/${inventory.projectImage}`}
              >
                {t("belgeIndir")}
              </Link>
            ) : null}
            <Textarea
              value={inventory.note}
              title={t("not")}
              placeholder={t("not")}
              onChange={(text) =>
                setInventory((prev) => {
                  return { ...prev, note: text };
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryEdit;
