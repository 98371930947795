import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../components";
const Layout = () => {
  const location = useLocation();

  return (
    <main>
      {["/login", "/reset-password"].includes(location.pathname) === false && <Header />}
      <Outlet />
    </main>
  );
};

export default Layout;
