import { createSlice } from "@reduxjs/toolkit";
import { getDropDownItems } from "../../lib/dropdowns";

const initialState = {
  contactSource: {
    contactSourceTR: [],
    contactSourceEN: [],
  },
  meetingShape: {
    meetingShapeTR: [],
    meetingShapeEN: [],
  },
  bankCredit: {
    bankCreditTR: [],
    bankCreditEN: [],
  },
  bidStage: {
    bidStageTR: [],
    bidStageEN: [],
  },
  failedStageNote: {
    failedStageNoteTR: [],
    failedStageNoteEN: [],
  },
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setDropDownItems: (state, action) => {
      const type = action.payload.type;
      const TRlist = [];
      const ENlist = [];
      action.payload.data.forEach((item) => {
        const trItem = {
          id: item._id,
          value: item.value,
          name: item.name,
        };
        const engItem = {
          id: item._id,
          value: item.value,
          name: item.engTranslation,
        };
        TRlist.push(trItem);
        ENlist.push(engItem);
      });

      if (type === "contactSource") {
        state.contactSource.contactSourceTR = TRlist;
        state.contactSource.contactSourceEN = ENlist;
      } else if (type === "meetingShape") {
        state.meetingShape.meetingShapeTR = TRlist;
        state.meetingShape.meetingShapeEN = ENlist;
      } else if (type === "bankCredit") {
        state.bankCredit.bankCreditTR = TRlist;
        state.bankCredit.bankCreditEN = ENlist;
      } else if (type === "bidStage") {
        state.bidStage.bidStageTR = TRlist;
        state.bidStage.bidStageEN = ENlist;
      } else if (type === "failedStageNote") {
        state.failedStageNote.failedStageNoteTR = TRlist;
        state.failedStageNote.failedStageNoteEN = ENlist;
      }
    },
  },
});

export const getDropDownThunk = (jwt) => {
  return async (dispatch) => {
    const { data } = await getDropDownItems(jwt, {
      types: [
        "contactSource",
        "meetingShape",
        "bankCredit",
        "bidStage",
        "failedStageNote",
      ],
    });
    data.forEach((item) => {
      if (item.type === "contactSource") {
        dispatch(setDropDownItems({ type: "contactSource", data: item.data }));
      } else if (item.type === "meetingShape") {
        dispatch(setDropDownItems({ type: "meetingShape", data: item.data }));
      } else if (item.type === "bankCredit") {
        dispatch(setDropDownItems({ type: "bankCredit", data: item.data }));
      } else if (item.type === "bidStage") {
        dispatch(setDropDownItems({ type: "bidStage", data: item.data }));
      } else if (item.type === "failedStageNote") {
        dispatch(
          setDropDownItems({ type: "failedStageNote", data: item.data })
        );
      }
    });
  };
};

export const { setDropDownItems } = systemSlice.actions;
export default systemSlice.reducer;
