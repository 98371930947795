import React, { useState, useEffect, useRef } from "react";
import style from "./userselection.module.scss";
import { DownIcon, UpIcon } from "../../../assets/icons/index";
import { useTranslation } from "react-i18next";

function UserSelection({
  allUsers,
  checkedUserCheckboxes,
  setCheckedUserCheckboxes,
  title,
  placeholder,
  props,
}) {
  const { t } = useTranslation();
  const [select, setSelect] = useState(false);
  const [searchUsers, setSearchUsers] = useState();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchUsers(allUsers);
  }, [allUsers]);

  const userMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      userMenu.current &&
      select &&
      !userMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleCheckboxChange = (data) => {
    const isChecked = checkedUserCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox === data
    );
    if (isChecked) {
      setCheckedUserCheckboxes(
        checkedUserCheckboxes.filter((checkedCheckbox) => checkedCheckbox !== data)
      );
    } else {
      setCheckedUserCheckboxes(checkedUserCheckboxes.concat(data));
    }
  };

  const searchHandler = (e) => {
    setSearchValue(e.target.value);
    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      setSearchUsers(allUsers);
      return;
    }
    const filteredUsers = allUsers?.filter((user) => {
      return user?.userName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchUsers(filteredUsers);
  };

  return (
    <div className={style.checkboxContact} ref={userMenu}>
      <div className={style.checkboxTitle}>{title}</div>
      <button
        {...props}
        className={style.button}
        onClick={() => {
          setSelect(!select);
        }}
      >
        {placeholder}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <input
            className={style.selectInput}
            placeholder={t("projeAra")}
            type="search"
            value={searchValue}
            onChange={searchHandler}
          />
          {allUsers?.length === 0 && <div>Henüz hiç proje eklenmemiş.</div>}
          {searchUsers?.length === 0 && (
            <div>Aradığınız sonuç bulunamadı.</div>
          )}
          <div className={style.items}>
            {searchUsers?.map((column) => (
              <div key={column._id}>
                <label>
                  <input
                    key={column._id}
                    type="checkbox"
                    value={column._id}
                    checked={checkedUserCheckboxes.some(
                      (checkedCheckbox) => checkedCheckbox === column._id
                    )}
                    onChange={() => handleCheckboxChange(column._id)}
                  />
                  {column.name} {column.surname}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UserSelection;
