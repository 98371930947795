import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllApprovals = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = 'get',
  data = {}
) => {
  let request;

  if (method === 'get') {
    request = await axios.get(
      `${BaseUrl}/api/approval/getAllApprovals/${userType}?pageNumber=${pageNumber}
    &pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === 'post') {

    request = await axios.post(`${BaseUrl}/api/approval/queryApproval/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }
  const payload = {
    data: request.data.bid,
    pageCount: request.data.pageCount,
  };
  return payload;
};

export const sendApprove = async (jwt, userType, inventoryInfo) => {
  const request = await axios.post(
    `${BaseUrl}/api/approval/approve/${userType}`,
    {
      data: inventoryInfo,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    message: request.data.message,
  };
  return payload;
};


export const sendApproveCancel = async (jwt, userType, inventoryInfo) => {
  const request = await axios.post(
    `${BaseUrl}/api/approval/cancel/${userType}`,
    {
      data: inventoryInfo,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    message: request.data.message,
  };
  return payload;
};

