import React, { useState } from "react";
import style from "./DropDownModal.module.scss";
import { TextInput } from "../../components/Forms/Inputs";
import { updateDropDownItems } from "../../lib/dropdowns";
import { useSelector } from "react-redux";
function DropDownModal({ setShowModal, data, modalType }) {
  const user = useSelector((state) => state.user.userData);
  const [inputData, setInputData] = useState(data);
  const [deletedItems, setDeletedItems] = useState([]);

  const addNewInputHandler = () => {
    const lastData = inputData[inputData.length - 1];
    if (inputData.length > 0) {
      if (
        lastData.name === "" ||
        lastData.value === "" ||
        lastData.engTranslation === ""
      ) {
        return;
      }
    }

    setInputData([
      ...inputData,
      { _id: "", name: "", value: "", engTranslation: "" },
    ]);
  };

  const kaydetHandler = () => {
    if (inputData.length > 0) {
      for (let i = 0; i < inputData.length; i++) {
        if (
          inputData[i].name === "" ||
          inputData[i].value === "" ||
          inputData[i].engTranslation === ""
        ) {
          return;
        }
      }
    }

    updateDropDownItems(user.token, {
      dataArray: inputData,
      deletedArray: deletedItems,
      type: modalType,
    })
      .then((res) => {
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.inputContainer}>
            {inputData.map((item, index) => (
              <div className={style.inputPart}>
                <TextInput
                  key={index}
                  title={""}
                  placeholder={"Dropdown Değeri"}
                  value={item.value}
                  hasTitle={false}
                  maxLength="80"
                  onChange={(text) => {
                    const newData = [...inputData];
                    newData[index].name = text;
                    newData[index].value = text;
                    setInputData(newData);
                  }}
                />
                <TextInput
                  key={index}
                  title={item.engTranslation}
                  placeholder={"İngilizce Çeviri"}
                  value={item.engTranslation}
                  hasTitle={false}
                  maxLength="80"
                  onChange={(text) => {
                    const newData = [...inputData];
                    newData[index].engTranslation = text;
                    setInputData(newData);
                  }}
                />
                <button
                  onClick={() => {
                    if (item._id !== "") {
                      setDeletedItems([...deletedItems, item._id]);
                    }
                    setInputData(inputData.filter((item, i) => i !== index));
                  }}
                  className={style.deleteButton}
                >
                  x
                </button>
              </div>
            ))}
            <div className={style.addButtonContainer}>
              <button
                onClick={addNewInputHandler}
                className={style.addNewButton}
              >
                + Yeni Alan Ekle
              </button>
            </div>
          </div>

          <div className={style.buttonContainer}>
            <button onClick={kaydetHandler} className={style.successModalPopup}>
              Kaydet
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className={style.closeModalPopup}
            >
              Vazgeç
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox} />
    </>
  );
}

export default DropDownModal;
