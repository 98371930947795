/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
// import { StandartButton, NewButton } from "./Buttons";
import { NewButton } from "./Buttons";
import style from "./subheader.module.scss";
// import axios from "axios";
// import { useSelector, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { BaseUrl } from "../../ApiConfig";
// import Popup from "../LeadElements/Popup";
import ExcelExportPopup from "./ExcelExportPopup";

const KvkkMenu = ({ kvkkData }) => {
  const user = useSelector((state) => state.user.userData);
  //const dispatch = useDispatch();
  //const reduxKvkk = useSelector(state => state.selectedKvkk.selectedKvkk);
  //const [popup, setPopup] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  //const deleteCustomerByIds = () => {
    // let arrayids = [];
    // kvkkData.forEach(d => {
    //   if (d.select) {
    //     arrayids.push(d.id);
    //   }
    // });
    // axios
    //   .post(`${BaseUrl}/api/kvkk/delete`, {
    //     ids: arrayids,
    //   })
    //   .then(res => {
    //     setTimeout(() => {
    //       dispatch(removeKvkk());
    //       window.location.reload();
    //     }, 2000);
    //   })
    //   .catch(err => console.log(err.response));
  //};

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      "İsim",
      "Soyisim",
      "Statu Formu",
      "Dosya İsmi",
      "Veri İşleme",
      "Oluşturma Tarihi",
    ]);
    const newData = data.map((item) => {
      return {
        "İsim": item.name,
        "Soyisim": item.surname,
        "Statu Formu": item.statusForm,
        "Dosya İsmi": item.fileName,
        "Veri İşleme": item.dataProcessing,
        "Oluşturma Tarihi": item.createdAt,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  return (
    <>
      {/* {popup ? (
        <Popup closeClick={setPopup} successClick={deleteCustomerByIds} />
      ) : null} */}
      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="20px 30px"
          data={excelData}
          fileName="KVK"
          text="Filtrelediğiniz Kvklara ait Excel başlıklarını seçiniz:"
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            KVKK Veritabanı
          </div>
        </div>
        <div className={style.right}>
          {/* {reduxKvkk.length ? (
            <StandartButton
              click={() => dispatch(removeKvkk())}
              title={'Seçimleri Temizle'}
            />
          ) : null} */}
           {(user.department === "admin" ||
            user.department === "ceo"  ) && (
          <NewButton
            title={"Veriyi Dışa Aktar"}
            icon={"exel"}
            onClick={() => {
              excelExportAndDownload(kvkkData);
            }}
          />)}
          {/* <StandartButton title={"Sil"} click={() => setPopup(true)} /> */}
        </div>
      </div>
    </>
  );
};

export default KvkkMenu;
