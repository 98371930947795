import React from "react";
// import { SubHeader, SideNav, ContactCard } from "../../components";
// import style from "./test.module.scss";
import PDF from "../BidDetail/PDF";
function Test() {
  return (
    <>
      <PDF />
    </>
  );
}

export default Test;
