import style from "./UserPopup.module.scss";
import { useTranslation } from "react-i18next";
const UserPopup = ({ closeClick, errorText }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>YENİ KULLANICI KAYIT</div>
          <hr />
          <div className={style.textcontainer}>{errorText}</div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("tamam")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default UserPopup;
