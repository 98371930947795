import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Loader({ isLoading, type = "main" }) {
  return (
    <div
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "99999999999999",
        backgroundColor:
          type === "refresh" ? "rgba(0,0,0,0.02)" : "rgba(0,0,0,0.3)",
      }}
    >
      <BeatLoader
        color={"#daa335"}
        loading={isLoading}
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "white",
        }}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loader;
