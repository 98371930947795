import style from "./ContactPopUp.module.scss";
import { useTranslation } from "react-i18next";
const ContactPopUp = ({
  closeClick,
  successClick,
  fullName,
  numOfActivities,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}> {t("kontakSilPopupTitle")}</div>
          <hr />
          <div className={style.textcontainer}>
            <strong>{fullName}</strong> {t("kontalSilPopupPart1")}{" "}
            <strong>
              {numOfActivities} {t("kontalSilPopupPart2")}
            </strong>{" "}
            {t("kontakSilPopupPart3")}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
            {t("kontakSilPopupIptalButton")}
            </button>
            <button onClick={successClick} className={style.successModalPopup}>
            {t("kontakSilPopupOnayButton")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default ContactPopUp;
