
import style from "./reset.module.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo2.png";
import loginlogo from "../../assets/img/loginlogo.png";
import bannerLogo from "../../assets/img/loginbanner.png";
import Loader from "../../components/ui/Loader/Loader";
import { checkActivationCodeThunk, checkResetEmailThunk, setPasswordThunk } from "../../redux/slice/userSlice";


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [error, setError] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showActivationCode, setShowActivationCode] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const dispatch = useDispatch();

  const {
    isLoginLoading,
  } = useSelector((state) => state.user);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setError();
    const body = { email };
    if (body.email.length === 0) {
      setError("Bütün alanları doldurunuz.");
      return;
    } else {
      const checkResetEmail =  dispatch(checkResetEmailThunk(body));
      checkResetEmail.then((res) => {
        if (res.payload.success === false)
        {
            setError(res.payload.message);
        }
        if (res.payload.success === true)
        {
            setShowActivationCode(true);
        }
      });
    }
  };

  const handleActivation = (e) => {
    e.preventDefault();
    setError();
    const body = { activationCode, email };
    if (body.activationCode.length === 0 && body.email.length === 0) {
      setError("Bütün alanları doldurunuz.");
      return;
    } else {
        const checkActivationCode =  dispatch(checkActivationCodeThunk(body));
        checkActivationCode.then((res) => {
          if (res.payload.success === false)
          {
              setError(res.payload.message);
          }
          if (res.payload.success === true)
          {
            setShowResetPassword(true);
          }
        });
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    setError();
    const body = { newPassword, confirmPassword, activationCode, email };
    if (body.newPassword.length === 0 && body.confirmPassword.length === 0) {
      setError("Bütün alanları doldurunuz.");
      return;
    } if (body.newPassword.length < 6 && body.confirmPassword.length < 6) {
        setError("Şifreniz 6 karakterden küçük olamaz.");
        return;
    } else if (newPassword === confirmPassword) {
      const setPassword =  dispatch(setPasswordThunk(body));
      setPassword.then((res) => {
        if (res.payload.success === false)
        {
            setError(res.payload.message);
        }
        if (res.payload.success === true)
        {
            setResetSuccess(true);
        }
      });
    } else {
        setError("Sifreler Uyuşmuyor");
        return;
    }
  };

  return (
    
    <div className={style.innerContainer}>
        <Loader isLoading={isLoginLoading} />
        <div className={style.formcontent}>
            <img src={logo} alt="" style={{ height: "60px" }} />
        {resetSuccess ? (
            <div>
            <p>Şifre başarıyla sıfırlandı!</p>
            </div>
        ) : showResetPassword ? (
            <form className={style.forms} onSubmit={handlePassword}>
                <h1>Şifre Değiştime</h1>
                <label><h1>Yeni Şifre:</h1></label>
                <input
                    type="password"
                    name="newPasword"
                    autoComplete="off"
                    placeholder="Yeni Şifre"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <label><h1>Şifre Kontrolü:</h1></label>
                <input
                    type="password"
                    name="confirmPassword"
                    autoComplete="off"
                    placeholder="Şifre Kontrolü"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {error ? <h3 className={style.error}>{error}</h3> : null}
                <button type="submit">Devam et</button>
            </form>
        ) :  showActivationCode ? (
            <form className={style.forms} onSubmit={handleActivation}>
                <h1>Aktivasyon İşlemi</h1>
                <label><h1>Aktivasyon Kodu:</h1></label>
                <input
                    type="text"
                    name="activationCode"
                    autoComplete="off"
                    placeholder="Aktivasyon Kodu"
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}
                />
                {error ? <h3 className={style.error}>{error}</h3> : null}
                <button type="submit">Devam et</button>
            </form>
        ) : (
            <form className={style.forms} onSubmit={handleResetPassword}>
                <h1>Şifremi Unuttum</h1>
                <label><h1>Email:</h1></label>
                <input
                    type="email"
                    name="email"
                    autoComplete="on"
                    placeholder="Email giriniz"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {error ? <h3 className={style.error}>{error}</h3> : null}
                <button type="submit">Şifreyi Sıfırla</button>
            </form>
        )}
            <div className={style.loginLink}>
                <Link to="/login">Giriş yap</Link>
            </div>
            <h5>{new Date().getFullYear()}© Aremas A.Ş </h5>
        </div>
        
        <div
            className={style.banner}
            style={{ background: `url(${bannerLogo})` }}
        >
            <img src={loginlogo} alt="" />
        </div>
    </div>
  );
};

export default ResetPassword;
