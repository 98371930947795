import style from './elements.module.scss';

const Card = ({ header, children }) => {
  return (
    <div className={style.cardElement}>
      <div className={style.header}>{header}</div>
      <div className={style.children}>{children}</div>
    </div>
  );
};

export default Card;
