import style from "./sidefilter.module.scss";
import { useState } from "react";

const InputBetween = ({
  title,
  firstValue,
  secondValue,
  currencyValue,
  setFirstChange,
  setSecondChange,
  setCurrencyChange,
  currencyShow = false,
  data = null,
  setData = null,
  nullData = null,
}) => {
  const [isOpen, setOpen] = useState();

  const toggle = (e) => {
    setOpen(e.target.checked);
    if (!data) {
      setFirstChange({ target: { value: "" } });
      setSecondChange({ target: { value: "" } });
      setCurrencyChange({ target: { value: "" } });
    } else {
      const updatedData = { ...data };
      Object.keys(nullData).forEach((key) => {
        nullData[key].forEach((field) => {
          updatedData[field] = "";
        });
      });
      setData(updatedData);
    }
  };

  return (
    <>
      <label className={style.input}>
        <input onChange={(e) => toggle(e)} type="checkbox" />
        <h3>{title}</h3>
      </label>
      {isOpen ? (
        <>
          <div>
            <input
              className={style.inputBox}
              type="text"
              placeholder="..."
              value={firstValue}
              onChange={setFirstChange}
            />
          </div>
          <div>
            <input
              className={style.inputBox}
              type="text"
              placeholder="..."
              value={secondValue}
              onChange={setSecondChange}
            />
          </div>
          {currencyShow ? (
            <div>
              <select
                className={style.selectOption}
                onChange={setCurrencyChange}
                defaultValue="asda"
              >
                <option value="asda" disabled>
                  Seçiniz
                </option>
                <option key="TRY" value="TRY">
                  TRY
                </option>
                <option key="USD" value="USD">
                  USD
                </option>
                <option key="EUR" value="EUR">
                  EUR
                </option>
                <option key="GBP" value="GBP">
                  GBP
                </option>
              </select>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default InputBetween;
