import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userCheck, loginUser, checkResetEmail, checkActivationCode, setPassword } from "../../lib/userApi";
import jwt_decode from "jwt-decode";

const initialState = {
  userData: {
    id: "",
    name: "",
    surname: "",
    email: "",
    department: "",
    userType: "",
    assignedProject: "",
    tokenExp: 0,
    token: "",
  },
  isLoading: true,
  hasError: false,
  successfulLogin: false,
  isLoginLoading: false,
  loginSuccessMessage: "",
  loginErrorMessage: "",
};

export const checkResetEmailThunk = createAsyncThunk(
  "user/checkResetEmail",
  async (body) => {
    const response = await checkResetEmail(body);
    return response;
  }
);

export const checkActivationCodeThunk = createAsyncThunk(
  "user/checkActivationCode",
  async (body) => {
    const response = await checkActivationCode(body);
    return response;
  }
);

export const setPasswordThunk = createAsyncThunk(
  "user/setPassword",
  async (body) => {
    const response = await setPassword(body);
    return response;
  }
);

export const checkUserThunk = createAsyncThunk(
  "user/checkUser",
  async (token) => {
    const decodedToken = jwt_decode(token);
    const response = await userCheck(token, decodedToken.id);
    return {
      data: response.data,
      token: token,
      tokenExp: decodedToken.exp,
      decodedToken: decodedToken,
    };
  }
);

export const loginUserThunk = createAsyncThunk(
  "user/loginUser",
  async (body) => {
    const response = await loginUser(body);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    clearUser: (state) => {
      state.userData = initialState.userData;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setHasError: (state, action) => {
      state.hasError = action.payload;
    },
  },
  extraReducers: {
    [checkUserThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [checkUserThunk.fulfilled]: (state, action) => {
      const { data, isActive, success } = action.payload.data;
      const { token, tokenExp, decodedToken } = action.payload;
      if (data.department !== decodedToken.department) {
        localStorage.removeItem("AuthToken");
        window.location.reload();
        window.location.href = "/login";
      }
      if (success && isActive) {
        state.userData.id = data.id;
        state.userData.name = data.name;
        state.userData.surname = data.surname;
        state.userData.email = data.email;
        state.userData.department = data.department;
        state.userData.userType = data.userType;
        state.userData.assignedProject = data.assignedProject;
        state.userData.tokenExp = +tokenExp;
        state.userData.token = token;
      }
      state.isLoading = false;
    },
    [checkUserThunk.rejected]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      localStorage.removeItem("AuthToken");
      window.location.reload();
      window.location.href = "/login";
    },
    [loginUserThunk.pending]: (state) => {
      state.isLoginLoading = true;
      state.isLoading = false;
    },
    [loginUserThunk.fulfilled]: (state, action) => {
      const { data, success, acces_token, isActive } = action.payload;
      const decodedToken = jwt_decode(acces_token);
      state.isLoading = false;
      if (success) {
        if (isActive) {
          state.successfulLogin = true;
          state.loginSuccessMessage = "Giriş Başarılı yönlendiriliyorsunuz...";
          state.userData.id = data.id;
          state.userData.name = data.name;
          state.userData.surname = data.surname;
          state.userData.email = data.email;
          state.userData.department = data.department;
          state.userData.userType = data.userType;
          state.userData.assignedProject = data.assignedProject;
          state.userData.tokenExp = +decodedToken.exp;
          state.userData.token = acces_token;
          state.hasError = false;
          localStorage.setItem("AuthToken", acces_token);
          // window.location.href = "/";
        } else if (!isActive) {
          state.loginErrorMessage =
            "Bir Sorun Oluştu Lütfen Daha Sonra Tekrar Deneyiniz.";
          state.successfulLogin = false;
          state.hasError = true;
        }
        state.isLoginLoading = false;
      }
    },
    [loginUserThunk.rejected]: (state) => {
      state.isLoading = false;
      state.successfulLogin = false;
      state.loginErrorMessage =
        "Bir Sorun Oluştu Lütfen Daha Sonra Tekrar Deneyiniz.";
      state.isLoginLoading = false;
      state.hasError = true;
    },
  },
});

export const { setUser, clearUser, setIsLoading, setHasError } =
  userSlice.actions;
export default userSlice.reducer;
