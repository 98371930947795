import React from "react";
import style from "./projectList.module.scss";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAllProjects, deleteAProject } from "../../lib/projectApi";
import Moment from "moment";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { ViewIcon, /* DeleteIcon,*/ EditIcon } from "../../assets/icons/index";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";
import { SubHeader, SideFilter } from "../../components";
import PrpjectListPopup from "./ProjectListPopup";

import { useTranslation } from "react-i18next";

function ProjectList() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const user = useSelector((state) => state.user.userData);

  const [targetUser, setTargetUser] = useState({
    fullName: "",
    id: "",
    count:0
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllProjects(user.token, user.userType)
      .then((res) => {
        //sort data by date
        res.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={getToggleAllRowsSelectedProps().checked ? true : undefined}
            ischecked={getToggleAllRowsSelectedProps().checked ? true : undefined}
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("projeAdi"),
        accessor: "projectName",
      },
      {
        Header: t("ulke"),
        accessor: "country",
      },
      {
        Header: t("il"),
        accessor: "city",
      },
      {
        Header: t("adres"),
        accessor: "address",
      },
      {
        Header: t("olusturulmaTarihi"),
        accessor: (row) => Moment(row.createdAt).local().format("DD.MM.YYYY"),
      },
      {
        Header:  t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <ReactTooltip
              id="view"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("projeyiDetayliGoruntule")}
            </ReactTooltip>

            <ReactTooltip
              id="update"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("projeyiDüzenle")}
            </ReactTooltip>

            <ReactTooltip
              id="delete"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("projeyiSil")}
            </ReactTooltip>
            {(user.department !== "hr" ||
              user.department !== "fd" ||
              user.department !== "pd" ) && (
            <Link
              data-tip
              data-for="view"
              className={style.icon}
              to={`/project-detail/${row.original._id}`}
            >
              <ViewIcon />
            </Link>)}
            
            {(user.department === "admin" ||
              user.department === "ceo" 
              // ||
              // user.department === "gm" ||
              // user.department === "sd" ||
              // user.department === "rd" ||
              // user.department === "sm"
              ) && (
              <Link
                data-tip
                data-for="update"
                className={style.icon}
                // to={`/note-detail/${row.original._id}`}
                to={`/project-list-edit/${row.original._id}`}
              >
                <EditIcon />
              </Link>
            )}
            {/*
            {(user.department === "admin" ||
              user.department === "ceo" 
              // ||
              // user.department === "gm" ||
              // user.department === "sd" ||
              // user.department === "rd" ||
              // user.department === "sm"
              ) && (
              <button
                data-tip
                data-for="delete"
                className={style.icon}
                onClick={() => {
                  setModalType("delete");
                  //console.log(row.original)
                  setTargetUser({
                    fullName: row.original.projectName,
                    id: row.original._id,
                    count:row.original.inventory.length
                  });
                  setIsPopupOpen(true);
                }}
              >
                <DeleteIcon />
              </button>
            )}
            */}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        hiddenColumns: ["address"],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const popUpClose = () => {
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
    });
    setIsPopupOpen(false);
  };

  const successClick = () => {
    if (modalType === "delete") {
      deleteAProject(user.token, [targetUser.id])
        .then((res) => {
          //console.log(res);
        })
        .then((err) => console.log(err));
    }

    window.location.reload();
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
      count:0
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && (
        <PrpjectListPopup
          type={modalType}
          closeClick={popUpClose}
          successClick={successClick}
          fullName={targetUser.fullName}
          count={targetUser.count}
        />
      )}
      <div className={style.projectList}>
        <SubHeader projectMenu projectData={data} />

        <div className={style.content}>
          <SideFilter setProjectData={setData} setHasFilterData={setHasFilterData} />
          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectList;
