import { Box, Container, Grid } from "@mui/material";

const Welcome = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          minWidth: 400,
        }}
      >
        {" "}
        <Container maxWidthLg>
          <Grid container spacing={1}>
            <h2
              style={{
                textAlign: "center",
                width: "100%",
                fontSize: "2rem",
                marginTop: "2rem",
              }}
            >
              Hoş geldiniz
            </h2>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                fontSize: "1rem",
                marginTop: "2rem",
              }}
            >
              Aremas CRM
            </div>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Welcome;
