import React from "react";
import style from "./header.module.scss";
import Profile from "./Profile";
import AdminHeader from "./AdminHeader";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

function HeaderButtons({ isAdminPanelHandler ,isAdminPanel }) {
  const { department } = useSelector((state) => state.user.userData);
  const { i18n } = useTranslation();

  const languageChangeHandler = () => {
    if (i18n.language === "tr") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("tr");
      localStorage.setItem("language", "tr");
    }
  }

  return (
    <>
      {/* <button className={style.cursor}>
        <SearchIcon />
      </button> */}
      {/* <button className={style.cursor}>
        <NotificationIcon />
      </button> */}
      {department === "admin" ? (
        <AdminHeader isAdminPanel={isAdminPanel} isAdminPanelHandler={isAdminPanelHandler} />
      ) : null}
      {/* <Announcement /> */}
      <div className={style.headerButtons}>
        <button onClick={languageChangeHandler} style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap:5,
          width: 55,
          fontSize: 13,
          fontWeight: 600,
        }}>
        {i18n.language === "tr" ? `TR 🇹🇷` : `EN 🇬🇧`}
        </button>
      </div>
      <Profile />
    </>
  );
}

export default HeaderButtons;
