import {
  TextInput,
  UserInput,
  DropInput,
  DisabledInput,
  AccountInput,
  ContactInput,
  DateInput,
} from './Inputs';
import style from './forms.module.scss';

const NewSaleOfferForm = () => {
  return (
    <form className={style.newSaleOfferForm}>
      <div className={style.top}>
        <div>
          <h1>Aktivite Bilgileri</h1>
          <div>
            <UserInput title={'Kontakt Sahibi'} />
            <DropInput title={'Aktivite Adı'} />
            <AccountInput title={'Hesap'} />
            <DropInput title={'İlişkili Proje'} />
            <ContactInput title={'Kontakt'} />
          </div>
        </div>
        <div>
          <div>
            <DropInput title={'Aktivite Verilen Daire'} />
            <TextInput title={'Blok'} />
            <TextInput title={'Kat'} />
            <TextInput title={'Daire No'} />
            <TextInput title={'Tip'} />
            <TextInput title={'M2'} />
            <DropInput title={'Ödeme Tipi'} />
            <TextInput title={'M2 Birim Fiyatı'} />
            <TextInput title={'Liste Fiyatı'} />
            <TextInput title={'Peşin Fiyatı'} />
            <TextInput title={'Vadeli Fiyatı'} />
            <TextInput title={'İndirim Oranı'} />
            <TextInput title={'Gelir Öngörüsü'} />
            <DisabledInput title={'Gelir Öngörüsü Sonuç'} />
            <DropInput title={'Teklif Aşaması'} />
            <DateInput title={'Kapanma Tarihi'} />
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <div>
          <h1>Kredi Hesaplama</h1>
          <div>
            <TextInput title={'Kredi Tutarı'} />
            <DropInput title={'Ay'} />
            <TextInput title={'Aylık Faiz'} />
            <TextInput title={'Vergi %BSMV'} />
            <TextInput title={'Fon %KKDF	'} />
            <TextInput title={'Yıllık Faiz %	'} />
            <TextInput title={'Vergili Faiz %	'} />
            <DropInput title={'Kredi Kullandıran Bank'} />
            <DisabledInput title={'Toplam Geri Ödeme'} />
            <DisabledInput title={'Toplam Faiz %'} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewSaleOfferForm;
