import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import tr from "./assets/Language/tr.json";
import en from "./assets/Language/en.json";

const resources = {
  tr: {
    translation: tr,
  },
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "tr",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
