import style from "../ProjectDetail/ProjectDetail.module.scss";
import { SubHeader, SideNav } from "../../components";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserInfoForProfile } from "../../lib/userApi";

import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUserInfoForProfile(user.token, user.id)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, user.id, user.token]);

  const userDeparmentConverter = (department) => {
    if (department === "admin") {
      return "Admin";
    } else if (department === "ceo") {
      return t("ceo");
    } else if (department === "gm") {
      return t("gm");
    } else if (department === "mc") {
      return t("mc");
    } else if (department === "dms") {
      return t("dms");
    } else if (department === "sd") {
      return t("sd");
    } else if (department === "rd") {
      return t("rd");
    } else if (department === "sm") {
      return t("sm");
    } else if (department === "ss") {
      return t("ss");
    } else if (department === "sa") {
      return t("sa");
    } else if (department === "hr") {
      return t("hr");
    } else if (department === "fd") {
      return t("fd");
    } else if (department === "pd") {
      return t("pd");
    }
  };

  return (
    <div className={style.leadDetail}>
      <div>
        <SubHeader
        //projectDetail
        // projectData={data}
        />

        <div className={style.content}>
          <SideNav />
          <div className={style.info}>
            <div className={style.leadCard}>
              <div className={style.left}>
                <div className={style.card}>
                  <h2>{t("isim")}</h2>
                  <h1>{user.name + " " + user.surname} </h1>
                </div>
                <div className={style.card}>
                  <h2>{t("telefon")}</h2>
                  <h1>
                    {userData.phone
                      ?.toString()
                      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}{" "}
                  </h1>
                </div>
                <div className={style.card}>
                  <h2>{t("email")}</h2>
                  <h1>{user.email} </h1>
                </div>
                <div className={style.card}>
                  <h2>{t("kullanciTipi")}</h2>
                  <h1>{user.userType} </h1>
                </div>
                <div className={style.card}>
                  <h2>{t("kurumsalUnvan")}</h2>
                  <h1>{userDeparmentConverter(user.department)} </h1>
                </div>
              </div>
              <div className={style.right}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
