import style from "./sidefilter.module.scss";
import { DownIcon } from "../../assets/icons";
// import { useState } from "react";
const DropDown = ({ title, data, setChange }) => {
  return (
    <label className={style.dropDownLabel}>
      <select
        defaultValue=""
        className={style.dropdownSelect}
        onChange={(e) => setChange(e.target.value)}
      >
        <option value="" disabled>
          {title}
        </option>
        {data
          ? data.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
      <div className={style.downIcon}>
        <DownIcon />
      </div>
    </label>
  );
};

export default DropDown;
