import style from "./subheader.module.scss";
import { BackIcon } from "../../assets/icons";
import { useNavigate } from "react-router";
const LeadCard = ({ contactDetailData }) => {
  const navigate = useNavigate();

  return (
    <div className={style.leadCard}>
      <button
        className={style.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </button>
      <div className={style.avatar}>
        {contactDetailData?.name && contactDetailData?.name?.charAt(0)}
        {contactDetailData?.surname && contactDetailData?.surname?.charAt(0)}
      </div>

      {contactDetailData ? (
        <h2>
          {contactDetailData.name} {contactDetailData.surname}
        </h2>
      ) : null}
    </div>
  );
};

const ContactDetail = ({ href, contactDetailData }) => {
  return (
    <div className={style.leadDetailMenu}>
      <div className={style.left}>
        {/* <LeftButton href={href} /> */}
        <LeadCard contactDetailData={contactDetailData} />
      </div>
      <div className={style.right}>
        {/* <NewButton title={"E-Mail Gönder"} /> */}
      </div>
    </div>
  );
};

export default ContactDetail;
