import style from "./elements.module.scss";
import { useTranslation } from "react-i18next";
const Popup = ({ closeClick, successClick, count, type, isError, page }) => {
  const { t } = useTranslation();
  const pageReplacer =
    page === "lead"
      ? t("iliski")
      : page === "contact"
      ? t("kontak")
      : page === "activities"
      ? t("aktivite")
      : page === "inventory"
      ? t("urunTipi")
      : page === "project"
      ? t("proje")
      : null;

  const errorText =
    t("errorTextPopUpPart1") +
    " " +
    pageReplacer +
    " " +
    t("errorTextPopUpPart2");

  const deleteText =
    t("deleteTextPopUpPart1") + pageReplacer + t("deleteTextPopUpPart2");

  const sendContactText = t("sendContactPopUp");

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {page === "lead" &&
              (type === "delete"
                ? t("cokluIliskiSilmeHeader")
                : "ÇOKLU İLİŞKİYİ KONTAKLARA GÖNDERME")}
            {page === "contact" &&
              (type === "delete" ? t("cokluKontakSilmeHeader") : null)}
            {page === "activities" &&
              (type === "delete" ? t("cokluAktiviteSilmeHeader") : null)}
            {page === "inventory" &&
              (type === "delete" ? t("cokluUrunTipiSilmeHeader") : null)}
            {page === "project" &&
              (type === "delete" ? t("cokluProjeSilmeHeader") : null)}
          </div>
          <hr />
          {isError ? (
            <div className={style.textcontainer}>{errorText}</div>
          ) : type === "delete" ? (
            <div className={style.textcontainer}>
              {" "}
              <strong>{count}</strong> {deleteText}
            </div>
          ) : (
            <div className={style.textcontainer}>
              <strong>{count}</strong> {sendContactText}
            </div>
          )}

          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {isError ? t("tamam") : t("vazgec")}
            </button>
            {!isError && (
              <button
                onClick={successClick}
                className={style.successModalPopup}
              >
                {type === "delete"
                  ? t("secilileriSil")
                  : t("taneKontaklaraGonder")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default Popup;
