import style from "./elements.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LeadCard = ({ data }) => {
  const user = useSelector((state) => state.user.userData);
  const { t } = useTranslation();

  return (
    <div className={style.leadCard}>
      <div className={style.left}>
        {user.department === "admin" && (
          <div className={style.card}>
            <h2>ID</h2>
            <p>{data._id ? data._id : ""} </p>
          </div>
        )}
        <div className={style.card}>
          <h2>{t("isimSoyisim")}</h2>
          <h1>
            {data.name ? data.name : ""} {data.surname ? data.surname : ""}
          </h1>
        </div>
        <div className={style.card}>
          <h2>{t("telefon")}</h2>
          <h1>
            {data.phone
              ? data?.phone
                  ?.toString()
                  .replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, "+$1($2) $3-$4")
              : ""}
          </h1>
        </div>

        <div className={style.card}>
          <h2>{t("email")}</h2>
          <h1>{data.email ? data.email : ""}</h1>
        </div>
        <div className={style.card}>
          <h2>{t("sirket")}</h2>
          <h1>{data.company ? data.company : ""}</h1>
        </div>
        <div className={style.card}>
          <h2>{t("kanal")}</h2>
          <h1>{data.channel ? data.channel : ""}</h1>
        </div>
        {/* <div className={style.card}>
          <h2>İlişki Sahibi</h2>
          <h1>{data.owner ? data.owner : ""}</h1>
        </div> */}
        <div className={style.card}>
          <h2>{t("olusturulmaTarihi")}</h2>
          <h1>{data.createdAt ? data.createdAt : ""}</h1>
        </div>
        {/* <div className={style.card}>
          <h2>Konu</h2>
          <h1>{data.subject ? data.subject : ""}</h1>
        </div>
        <div className={style.card}>
          <h2>Ip</h2>
          <h1>{data.ip ? data.ip : ""}</h1>
        </div> */}
      </div>
      <div className={style.right}>
        <div className={style.card}>
          <h2>{t("not")}</h2>
          <h1>{data.note ? data.note : ""}</h1>
        </div>
        {data.associatedProject.length > 0 &&
          data.associatedProject.map((item) => {
            if (user.assignedProject.includes(item._id)) {
              return (
                <div className={style.card}>
                  <h2>{t("iliskilendirilenProje")}</h2>
                  <Link to={`/project-detail/${item._id}`}>
                    {item.projectName}
                  </Link>
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default LeadCard;
