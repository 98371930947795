import style from "./inventorydetail.module.scss";
import { SubHeader, SideNav, InventoryLog } from "../../components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { BaseUrl } from "../../ApiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const InventoryDetail = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  let userType = user.userType;
  const { id } = useParams();
  const navigate = useNavigate();
  const [inventory, setInventory] = useState();
  const [inventoryCategory, setInventoryCategory] = useState("");

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getOneInventory/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setInventory(res.data.inventory);
        setInventoryCategory(res.data.inventory.inventoryCategory);
        //console.log(res.data.inventory);
      })
      .catch((err) => {
        console.log(err);
        navigate("/inventory-list", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  let date = new Date(inventory ? inventory.createDate : null);
  let fullDate =
    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  /*
  const formatDate = (_date) => {
    let date = new Date(_date);
    let fullDate =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    return fullDate;
  };
  */
  const statusConverter = (status) => {
    if (status === "Kiraya uygun") {
      return t("kirayaUygun");
    } else if (status === "Kaporalı") {
      return t("kaporali");
    } /*else if (status === "Opsiyonlu") {
      return t("opsiyonlu");
    } */ else if (status === "Kiraya uygun değil") {
      return t("kirayaUygunDegil");
    } else if (status === "Satışa Açık (Stok)") {
      return t("satisaAcikStok");
    } else if (status === "Satışa Açık (Opsiyonlu)") {
      return t("satisaAcikOpsiyonlu");
    } else if (status === "Satış (Sözleşme)") {
      return t("satisaKapaliSozlesmeli");
    } else if (status === "Satış (Kaparo)") {
      return t("satisaKapaliKaparoli");
    } else if (status === "Satışa Kapalı") {
      return t("satisaKapali");
    } else if (status === "Barter") {
      return t("barter");
    }
  };

  const urunStatusuConverter = (status) => {
    if (status === "satılık") {
      return t("satilik");
    } else if (status === "kiralık") {
      return t("kiralik");
    } else if (status === "satılıkveyakiralık") {
      return t("satilikVeyaKiralik");
    }
  };

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return " ₺";
    } else if (value === "USD") {
      return " $";
    } else if (value === "EUR") {
      return " €";
    } else if (value === "GBP") {
      return " £";
    } else if (value === "RUB") {
      return " ₽";
    } else {
      return " ₺";
    }
  };

  return (
    <div className={style.inventoryDetail}>
      <div>
        <SubHeader
          inventoryDetailData={inventory}
          inventoryBackHref="/inventory-list"
          inventoryDetail
        />

        <div className={style.content}>
          <SideNav inventoryDetail="1" />
          <div className={style.info}>
            {inventory && inventory.bids.length > 0 ? (
              <div className={style.box}>
                <h1>{t("lastBid")}</h1>
                <div className={style.textBox}>
                  <p>
                    {inventory.bids[0].contactId.name}{" "}
                    {inventory.bids[0].contactId.surname}
                  </p>
                  <p>{inventory.bids[0].bidStage}</p>
                </div>
              </div>
            ) : null}
            <div className={style.inventoryCard}>
              {inventory ? (
                <>
                  <div className={style.left}>
                    {user.department === "admin" && (
                      <div className={style.card}>
                        <h2>ID</h2>
                        <p>{inventory._id ? inventory._id : ""} </p>
                      </div>
                    )}
                    <div className={style.card}>
                      <h2>{t("projeAdi")}</h2>
                      <h1>{inventory.ownerProject.projectName}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("uniteIsmi")}</h2>
                      <h1>{inventory.inventoryName}</h1>
                    </div>
                    {userType === "Kurumsal" ? (
                      <div className={style.card}>
                        <h2>{t("urunTipi")}</h2>
                        <h1>
                          {inventory.corporateStatus === "dukkan"
                            ? t("dukkan")
                            : inventory.corporateStatus === "ofis"
                            ? t("ofis")
                            : inventory.corporateStatus === "atolye"
                            ? t("atolye")
                            : inventory.corporateStatus === "konut"
                            ? t("konut")
                            : inventory.corporateStatus === "villa"
                            ? t("villa")
                            : ""}
                        </h1>
                      </div>
                    ) : null}
                    <div className={style.card}>
                      <h2>{t("uniteIsmi")}</h2>
                      <h1>{inventory.inventoryName}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("uniteSunumSekli")}</h2>
                      <h1>
                        {inventory.status
                          ? statusConverter(inventory.status)
                          : ""}
                      </h1>
                    </div>

                    <div className={style.card}>
                      <h2>{t("daireTipi")}</h2>
                      <h1>{inventory.circleType}</h1>
                    </div>
                    {/* <div className={style.card}>
                      <h2>Kullanım Şekli</h2>
                      <h1>{inventory.inventoryUseShape}</h1>
                    </div> */}

                    {userType === "Kurumsal" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("teslimSekli")}</h2>
                          <h1>{inventory.deliveryShape}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("binaAdi")}</h2>
                          <h1>{inventory.buildingName}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("binaAcilisTarihi")}</h2>
                          <h1>{inventory.buildingOpeningDate}</h1>
                          {/* <h1>{formatDate(inventory.buildingOpeningDate)}</h1>  */}
                        </div>
                        <div className={style.card}>
                          <h2>{t("websitesi")}</h2>
                          <h1>{inventory.website}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("ulasim")}</h2>
                          <h1>{inventory.transportation}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("sosyalOlanaklar")}</h2>
                          <h1>{inventory.socialOpportunities}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("gelistirici")}</h2>
                          <h1>{inventory.developer}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("insaatAlani")}</h2>
                          <h1>{inventory.constructionZone}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("verimlilik")}</h2>
                          <h1>%{inventory.productivity}</h1>
                        </div>
                        {inventory.corporateStatus === "ofis" ? (
                          <>
                            <div className={style.card}>
                              <h2>{t("iklimlendirme")}</h2>
                              <h1>
                                {inventory.airConditioning
                                  ? inventory.airConditioning === "Var"
                                    ? t("var")
                                    : t("yok")
                                  : ""}
                              </h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("asansorSayisi")}</h2>
                              <h1>{inventory.numberOfElevators}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("zeminUzeriToplamKatSayisi")}</h2>
                              <h1>{inventory.floorAboveGroundTotalLength}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("toplamOfisAlani")}</h2>
                              <h1>{inventory.totalOfficeSpace}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("katSecenekleri")}</h2>
                              <h1>{inventory.floorOptions}</h1>
                            </div>
                          </>
                        ) : inventory.corporateStatus === "dukkan" ? (
                          <>
                            <div className={style.card}>
                              <h2>{t("toplamKatSayisi")}</h2>
                              <h1>{inventory.floorTotalLength}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("toplamDukkanAlani")}</h2>
                              <h1>{inventory.totalStoreSpace} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanSecenekleri")}</h2>
                              <h1>{inventory.storeOptions}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("mekanikAyrinti")}</h2>
                              <h1>{inventory.mechanicalDetail}</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanBrutAlani")}</h2>
                              <h1>{inventory.shopGrossArea} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanAcikAlanBrutAlani")}</h2>
                              <h1>{inventory.shopOpenAreaGrossArea} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanHemzeminDepoAlani")}</h2>
                              <h1>{inventory.shopLevelStorageArea} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanAltKatDepoAlani")}</h2>
                              <h1>{inventory.shopDownstairsStorageArea} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("dukkanSaftAlani")}</h2>
                              <h1>{inventory.shopShaftArea} m²</h1>
                            </div>
                          </>
                        ) : inventory.corporateStatus === "atolye" ? (
                          <>
                            <div className={style.card}>
                              <h2>{t("atolyeBrutAlan123")}</h2>
                              <h1>{inventory.workshopGrossArea123} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("atolyeBrutAlan45")}</h2>
                              <h1>{inventory.workshopGrossArea45} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("atolyeBrutAlan67")}</h2>
                              <h1>{inventory.workshopGrossArea67} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("atolyeBrutAlan89")}</h2>
                              <h1>{inventory.workshopGrossArea89} m²</h1>
                            </div>
                            <div className={style.card}>
                              <h2>{t("atolyeSaftAlani")}</h2>
                              <h1>{inventory.workshopShaftArea} m²</h1>
                            </div>
                          </>
                        ) : null}

                        <div className={style.card}>
                          <h2>{t("tavanYuksekligi")}</h2>
                          <h1>{inventory.ceilingHeight}</h1>
                        </div>
                      </>
                    ) : null}
                    <div className={style.card}>
                      <h2>{t("odaSayisi")}</h2>
                      <h1>{inventory.numberRooms}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("ada")}</h2>
                      <h1>{inventory.island}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("parsel")}</h2>
                      <h1>{inventory.parsel}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("pafta")}</h2>
                      <h1>{inventory.pafta}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("blokNo")}</h2>
                      <h1>{inventory.blockNo}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("disKapiNo")}</h2>
                      <h1>{inventory.exteriorDoorNo}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("kat")}</h2>
                      <h1>{inventory.floor}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("bagimsizBolumNo")}</h2>
                      <h1>{inventory.independentNo}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("adres")}</h2>
                      <h1>{inventory.address}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("il")}</h2>
                      <h1>{inventory.city}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("ulke")}</h2>
                      <h1>{inventory.country}</h1>
                    </div>
                  </div>
                  <div>
                    <div className={style.card}>
                      <h2>{t("manzara")}</h2>
                      <h1>{inventory.Landscape}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("yon")}</h2>
                      <h1>{inventory.Direction}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("satisBrutMetrekare")}</h2>
                      <h1>{inventory.brutM2} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("netMetrekare")}</h2>
                      <h1>{inventory.netM2} m²</h1>
                    </div>

                    <div className={style.card}>
                      <h2>{t("cepheGenisligim")}</h2>
                      <h1>{inventory.facadeWidth} m</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("brutTavanYuksekligi")}</h2>
                      <h1>{inventory.grossCeilingHeight} m</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("hemzeminDepoAlani")}</h2>
                      <h1>{inventory.atLevelWarehouse} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("altKatDepoAlaniBrutAlani")}</h2>
                      <h1>{inventory.grossAreaBasementWarehouse} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("depoSaftAlani")}</h2>
                      <h1>{inventory.warehouseShaftArea} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("acikYuklemeAlani")}</h2>
                      <h1>{inventory.openLoadingArea} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("tahsisliCalismaAlani")}</h2>
                      <h1>{inventory.dedicatedWorkspace} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("idariOfisAlani")}</h2>
                      <h1>{inventory.administrativeOfficeArea} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("sahanlikAlani")}</h2>
                      <h1>{inventory.shelfArea} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("ofisBrutAlani")}</h2>
                      <h1>{inventory.officeGrossArea} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("tirGirebilmeDurumu")}</h2>
                      <h1>
                        {inventory.truckEntryStatus
                          ? inventory.truckEntryStatus === "var"
                            ? t("var")
                            : t("yok")
                          : ""}
                      </h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("kreynAltyapisi")}</h2>
                      <h1>{inventory.craneInfrastructure}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("tahsisliCatiMekanikAlani")}</h2>
                      <h1>{inventory.assignedRoofMechanicalSpace}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("toplamSatilabilirAlan")}</h2>
                      <h1>{inventory.totalSalableArea}</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("bahceMetrekare")}</h2>
                      <h1>{inventory.gardenM2} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("havuzMetrekare")}</h2>
                      <h1>{inventory.poolM2} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("terasMetrekare")}</h2>
                      <h1>{inventory.terraceM2} m²</h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("balkoMetrekare")}</h2>
                      <h1>{inventory.balconyM2} m²</h1>
                    </div>
                    {userType === "Kurumsal" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("otoparkHakki")}</h2>
                          <h1>{inventory.parkingRight} </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("vergiDurumu")}</h2>
                          <h1>{inventory.taxStatus} </h1>
                        </div>
                      </>
                    ) : null}
                    <div className={style.card}>
                      <h2>{t("urunStatusu")}</h2>
                      <h1>
                        {inventory.inventoryCategory
                          ? urunStatusuConverter(inventory.inventoryCategory)
                          : ""}
                      </h1>
                    </div>
                    {inventoryCategory === "satılık" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("metrekareBirimSatisFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={Number(inventory.salesPriceM2).toFixed(2)}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                      </>
                    ) : inventoryCategory === "kiralık" ? (
                      <div className={style.card}>
                        <h2>{t("metrekareBirimKiraBedeli")}</h2>
                        <h1>
                          <NumberFormat
                            style={{
                              width: "70%",
                              color: "#333e48",
                            }}
                            value={inventory.salesRentM2}
                            isNumericString
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={currencyConvert(inventory.currency)}
                            displayType="text"
                          />
                        </h1>
                      </div>
                    ) : inventoryCategory === "satılıkveyakiralık" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("metrekareBirimSatisFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={Number(inventory.salesPriceM2).toFixed(2)}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("metrekareBirimKiraBedeli")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.salesRentM2}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                      </>
                    ) : null}
                    {inventoryCategory === "satılık" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("kdvOrani")}</h2>
                          <h1>% {inventory.vatRate}</h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("kdvHaricListeFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.excludingVatListPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("kdvDahilSatisFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                fontSize: 18,
                                fontWeight: 500,
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.withVatListPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                      </>
                    ) : inventoryCategory === "kiralık" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("kiraBedeli")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.rentPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                      </>
                    ) : inventoryCategory === "satılıkveyakiralık" ? (
                      <>
                        <div className={style.card}>
                          <h2>{t("kdvOrani")}</h2>
                          <h1>% {inventory.vatRate} </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("kdvHaricListeFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.excludingVatListPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("kdvDahilListeFiyati")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.withVatListPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                        <div className={style.card}>
                          <h2>{t("kiraBedeli")}</h2>
                          <h1>
                            <NumberFormat
                              style={{
                                width: "70%",
                                color: "#333e48",
                              }}
                              value={inventory.rentPrice}
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(inventory.currency)}
                              displayType="text"
                            />
                          </h1>
                        </div>
                      </>
                    ) : null}
                    {inventoryCategory === "kiralık" ||
                    inventoryCategory === "satılıkveyakiralık" ? (
                      userType === "Kurumsal" ? (
                        <>
                          <div className={style.card}>
                            <h2>{t("aidat")}</h2>
                            <h1>
                              <NumberFormat
                                style={{
                                  width: "70%",
                                  color: "#333e48",
                                }}
                                value={inventory.dues}
                                isNumericString
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                suffix={" ₺"}
                              />
                            </h1>
                          </div>
                          <div className={style.card}>
                            <h2>{t("toplamMetrekareAidat")}</h2>
                            <h1>
                              <NumberFormat
                                style={{
                                  width: "70%",
                                  color: "#333e48",
                                }}
                                value={inventory.duesTotalM2}
                                isNumericString
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                suffix={currencyConvert(inventory.currency)}
                                displayType="text"
                              />
                            </h1>
                          </div>
                          <div className={style.card}>
                            <h2>{t("kirasizDonem")}</h2>
                            <h1>{inventory.rentFreePeriod} </h1>
                          </div>
                        </>
                      ) : null
                    ) : null}
                    <div className={style.card}>
                      <h2>{t("malSahibi")}</h2>
                      {!inventory.inventoryOwnerId ? (
                        <h1>{inventory.queryOwnerInventoryName}</h1>
                      ) : (
                        <Link
                          to={`/contact-detail/${
                            inventory.inventoryOwnerId
                              ? inventory.inventoryOwnerId._id
                              : ""
                          }`}
                        >
                          {inventory.inventoryOwnerId
                            ? inventory?.inventoryOwnerId?.name
                            : ""}{" "}
                          {inventory.inventoryOwnerId
                            ? inventory.inventoryOwnerId.surname
                            : ""}
                        </Link>
                      )}
                    </div>
                    <div className={style.card}>
                      <h2>{t("bagimsizBolumPlani")}</h2>
                      {inventory.independentSectionPlanFileName ? (
                        <Link
                          target="_blank"
                          to={`/public/uploads/${inventory.independentSectionPlanFileName}`}
                          className={style.inventoryFiles}
                        >
                          {inventory.independentSectionPlanFileName ? (
                            <>
                              {inventory.independentSectionPlanFileName.substr(
                                0,
                                17
                              )}
                              {inventory.independentSectionPlanFileName.length >
                              17
                                ? "..."
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : null}
                    </div>
                    <div className={style.card}>
                      <h2>{t("vaziyetPlani")}</h2>
                      {inventory.situationPlanFileName ? (
                        <Link
                          target="_blank"
                          to={`/public/uploads/${inventory.situationPlanFileName}`}
                          className={style.inventoryFiles}
                        >
                          {inventory.situationPlanFileName ? (
                            <>
                              {inventory.situationPlanFileName.substr(0, 17)}
                              {inventory.situationPlanFileName.length > 17
                                ? "..."
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : null}
                    </div>
                    <div className={style.card}>
                      <h2>{t("katPlani")}</h2>
                      {inventory.floorPlanFileName ? (
                        <Link
                          target="_blank"
                          to={`/public/uploads/${inventory.floorPlanFileName}`}
                          className={style.inventoryFiles}
                        >
                          {inventory.floorPlanFileName ? (
                            <>
                              {inventory.floorPlanFileName.substr(0, 17)}
                              {inventory.floorPlanFileName.length > 17
                                ? "..."
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : null}
                    </div>
                    <div className={style.card}>
                      <h2>{t("mahalListesi")}</h2>
                      {inventory.locusPlanFileName ? (
                        <Link
                          target="_blank"
                          to={`/public/uploads/${inventory.locusPlanFileName}`}
                          className={style.inventoryFiles}
                        >
                          {inventory.locusPlanFileName ? (
                            <>
                              {inventory.locusPlanFileName.substr(0, 17)}
                              {inventory.locusPlanFileName.length > 17
                                ? "..."
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : null}
                    </div>
                    <div className={style.card}>
                      <h2>{t("projeGorseli")}</h2>
                      {inventory.projectImage ? (
                        <Link
                          target="_blank"
                          to={`/public/uploads/${inventory.projectImage}`}
                          className={style.inventoryFiles}
                        >
                          {inventory.projectImage ? (
                            <>
                              {inventory.projectImage.substr(0, 17)}
                              {inventory.projectImage.length > 17 ? "..." : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : null}
                    </div>
                    <div className={style.card}>
                      <h2>{t("not")}</h2>
                      <h1>{inventory.note} </h1>
                    </div>
                    <div className={style.card}>
                      <h2>{t("olusturulmaTarihi")}</h2>
                      <h1>{fullDate}</h1>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <InventoryLog inventoryId={id} user={user} />
            <div style={{ marginBottom: "5em" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetail;
