import React, { useState } from "react";
import style from "./elements.module.scss";
import axios from "axios";
import { NewButton } from "../SubHeader/Buttons";
import ContactUploadFileItem from "./ContactUploadFileItem";
import { BaseUrl } from "../../ApiConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function ContactUploadFile({ document, setFin, fin, setDocuments, bids }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  let contactId = window.location.pathname.split("/")[2];
  let ownerName = user.name;
  const [checkAll, setCheckAll] = useState(false);
  const [bidSelectedLead, setBidSelectedLead] = useState("1");
  const onFilesChange = (files) => {
    let data = new FormData();
    data.append("documentContact", files);
    axios
      .post(
        `${BaseUrl}/api/documentContact/upload/${contactId}/${ownerName}/${files.size}/${bidSelectedLead}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      )
      .then((res) => {
        setFin(fin + 1);
      })
      .catch((err) => console.log(err.response));
  };
  const allChecked = () => {
    if (checkAll) {
      setCheckAll(false);
      setDocuments(
        document.map((item) => {
          item.select = false;
          return item;
        })
      );
    } else {
      setCheckAll(true);
      setDocuments(
        document.map((item) => {
          item.select = true;
          return item;
        })
      );
    }
  };
  /*
  const deleteCustomerByIds = () => {
    let contactId = window.location.pathname.split("/")[2];
    let arrayids = [];
    document.forEach((d) => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    let body = {
      ids: arrayids,
      contactId: contactId,
    };
    axios
      .post(`${BaseUrl}/api/documentContact/delete`, body, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((data) => {
        setFin(fin + 1);
      })
      .catch((err) => console.log(err.response));
  };
  */
  return (
    <section id="documention-card">
      <div className={style.contactUploadFile}>
        <div className={style.upload}>
          <span> {t("dokuman")}</span>
          <div>
            <input
              id="file"
              type="file"
              placeholder="asda"
              onChange={(e) => onFilesChange(e.target.files[0])}
            />
            <div className={style.buttons}>
              <h2>{t("iliskiliAktivite")}</h2>
              <select
                defaultValue="1"
                onChange={(e) => setBidSelectedLead(e.target.value)}>
                <option value="1">
                  {t("genel")}
                </option>
                {bids
                  ? bids.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.bidName}
                        </option>
                      );
                    })
                  : null}
              </select>
              <NewButton title={t("dokumanEkle")} />
              {/* 
              <div className={style.delete} onClick={deleteCustomerByIds}>
                {t("sil")}
              </div>
              */}
            </div>
          </div>
        </div>
        <div className={style.navUpload}>
          <div style={{ flex: 1 }}>
            <input
              type="checkbox"
              checked={checkAll}
              onChange={(e) => {
                //console.log(e)
              }}
              onClick={allChecked}
            />
          </div>
          <div style={{ flex: 5 }}>{t("dokumanIsmi")}</div>
          <div style={{ flex: 2 }}>{t("dokumanSahibi")}</div>
          <div style={{ flex: 2 }}>{t("iliskiliAktivite")}</div>
          <div style={{ flex: 2 }}>{t("tarih")}</div>
          <div style={{ flex: 2 }}>{t("dokumanBoyutu")}</div>
        </div>
        <ContactUploadFileItem
          setDocuments={setDocuments}
          document={document}
        />
      </div>
    </section>
  );
}

export default ContactUploadFile;
