import React, { useState } from 'react';
import {
  Page,
  Text,
  Document,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import { ContractIcon } from '../../assets/icons';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
  ],
});

const currencyFormat = value => {
  var currency_symbol = '₺';

  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 0,
  });

  return value.length
    ? formattedOutput.format(value).replace(currency_symbol, '')
    : '';
};

const ContractPDFData = ({ data, contactData, styles }) => {
  //console.log('ContractPdf', data);
  //console.log('contactData', contactData);

  function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var date = new Date();
  var month = pad2(date.getMonth() + 1); //months (0-11)
  var day = pad2(date.getDate() - 1); //day (1-31)
  //var yesterday = pad2(date.getDate() - 1)
  var year = date.getFullYear();
  //var yesterday = new Date((new Date()).valueOf() - 1000*60*60*24);

  var formattedDate = day + '-' + month + '-' + year;
  

  const documentData = [
    {
      title: 'MADDE 2.	TANIMLAR',
      text: 'İşbu Sözleşmede aşağıdaki terimler, karşılarında verilen anlamları taşımaktadır:',
      caption: '',
    },
    {
      title: '',
      text: 'Satışa konu Taşınmazın SATICI tarafından hazırlanan ve ilgili kurumlarca tasdik edilmiş projelerine, ekteki Teknik Şartnameye ve işbu Sözleşme’ye uygun, tam ve eksiksiz olarak tamamlanıp, Taşınmazın kullanım amacına uygun olarak ALICI’ya teslimidir.',
      caption: 'ANAHTAR TESLİMİ: ',
    },
    {
      title: '',
      text: 'Muğla ili, Bodrum ilçesi, Gökçebel Mahallesi, 778 ada 1 parselde kayıtlı arsa üzerinde (Projeye başka parseller eklenmesi ve/veya tevhit, ifraz durumunda ada-parsel numarasının değişmesi hallerinde bu ada-parsellerde de geçerli olmak üzere) SATICI tarafından inşa edilecek 73 adet ayrı yapıdan oluşan projedir',
      caption: 'PROJE:',
    },
    {
      title: '',
      text: 'Arsa payına sahip olacak olan ve ekli Teknik Şartname, vaziyet planı ve kat planına göre proje kapsamında inşa edilecek satışa konu taşınmazdır. ',
      caption: 'TAŞINMAZ:',
    },
    {
      title: '',
      text: 'Şaftlar, ışıklıklar, hava bacaları, galeri boşlukları hariç, Taşınmazın dış konturlarının çevrelediği alanı ifade etmektedir.',
      caption: 'TAŞINMAZ BRÜT ALANI:',
    },
    {
      title: '',
      text: 'Sözleşmeye konu Taşınmazın satış bedelidir.',
      caption: 'SÖZLEŞME BEDELİ:',
    },
    {
      title: '',
      text: 'Sözleşme’ye konu Taşınmazın ve projenin özelliklerini belirten sözleşmenin ekli şartnamesidir. ',
      caption: 'TEKNİK ŞARTNAME:',
    },
    {
      title: 'MADDE 3.	SÖZLEŞMENİN KONUSU',
      text: 'İşbu Sözleşme’nin konusu; SATICI tarafından yapımı üstlenilen Proje ile ilgili olarak Taşınmazın (Konumu, büyüklüğü aynı kalmak kaydıyla, numaralandırma-isimlendirme değişebilir.); mevcut veya tadil edilmiş projeler ve ayrıca ekteki “Teknik Şartname”ye uygun olarak inşa edilecek ya da inşaa ettirilecek Taşınmazın Sözleşme’de belirtilen satış bedeli mukabilinde; ALICI’ya satış vaadini, satış vaadine ilişkin ödeme şartlarını, mülkiyet devrine ilişkin koşullarını, Taşınmazın Anahtar Teslimi’ne ilişkin şartlarını ve tarafların hak ve yükümlülüklerinin düzenlenmesidir.',
      caption: '',
    },
    {
      title: 'MADDE 4.	TAŞINMAZIN SATIŞ VAADİ BEDELİ ',
      text: `Sözleşme konusu Taşınmazın peşin satış bedeli KDV hariç/dahil ${currencyFormat(
        `${data.bidAmountWithVat}`
      )} TL’dir. ALICI bu bedeli ${
        data.paymentType ? data.paymentType : ''
      } şeklinde ödeyecektir. İşbu bedel emlak vergi beyanı altında değildir. ALICI belirtilen tarih ve vadelerde, herhangi bir ihbara gerek kalmaksızın, ödeme yapmayı kabul ve taahhüt eder. Ödeme planında belirtilen bedeller yine belirtilen sürelerde ödenmediği takdirde aylık ticari faiz uygulanacaktır. `,
      caption: '4.1.',
    },
    {
      title: '',
      text: `Taşınmazın satış vaadi bedeline;  
      - İşbu Sözleşme’ye ilişkin noter masrafları, 
      - Taşınmazın satış bedeline ilişkin KDV,
      - İskan harcı,
      - Cins tashih harcı, 
      - Tapu ferağ takriri sırasında ödenmesi gereken alım harcı;
      - Döner sermaye harcı
      - Kredi kullanılması veya ALICI lehine ipotek tesis edilmesi halinde ipotek harcı
      - Elektrik, su, doğal gaz abonelik ve altyapı bedelleri  
      - Taşınmaza ait sigorta (DASK) işlemleri 
      - Kat mülkiyetinin kurulması sırasında ödenmesi gereken harç ve vergiler dahil olmayıp, satış bedeline ilaveten ALICI tarafından ödenecektir. `,
      caption: '4.2.',
    },
    {
      title: '',
      text: `ALICI’nın Taşınmazı devir ve/veya teslim alabilmesi için; satış vaadi bedelinin ve 4.2 maddede belirtilen bedeller tamamının eksiksiz ödemesi gerekir. Aksi takdirde SATICI’nın bu bedellerin tamamı ödenene kadar, işbu Sözleşmede daha erken teslim tarihi öngörülmüş olsa dahi Taşınmazı devir ve/veya teslimden imtina edebileceğini bildiğini peşinen kabul ve beyan eder. `,
      caption: '4.3.',
    },
    {
      title: '',
      text: `İşbu Sözleşme ve Taşınmaz ile ilgili vergi, harç oranlarının arttırılması veya yeni vergi, harç vs oluşturulması halinde ALICI kendi Taşınmazına isabet eden kısımlardan ayrıca sorumludur. KDV, Taşınmazın teslimi sonrasında kesilecek fatura karşılığında ALICI tarafından ayrıca ve ilaveten ödenecektir.`,
      caption: '4.4.',
    },
    {
      title: 'MADDE 5.	SATICININ BEYAN, TEKEFFÜL VE YÜKÜMLÜLÜKLERİ',
      text: `SATICI Projede yer alan ve Vaziyet Planında kırmızı renkli kalemle taralı, özellikleri işbu Sözleşmenin ekinde yer alan Teknik Şartnamede belirtilen Taşınmazı, yine işbu Sözleşmenin belirtilen bedel mukabilinde işbu Sözleşmede yazılı koşullarla satmayı vaat, kabul ve taahhüt etmiştir. `,
      caption: '5.1.',
    },
    {
      title: '',
      text: `SATICI, Taşınmazı mücbir nedenler ve mevsime bağlı gecikmeler dışında Aralık 2023 tarihine kadar tamamlayacaktır ve “Anahtar Teslimi” olarak ALICI’ya teslim edecektir. Peyzaj düzenlemelerinin tamamlanması, bu sürelerin dışında olup, peyzaj düzenlemeleri söz konusu Projedeki taşınmazların alıcılara tesliminden itibaren 6 ay içinde tamamlanacaktır. İskan; Yapı Denetim Firmasının “İş Bitirme Belgesini” vermesini müteakip ve o tarihteki taşınmaz hissedarlarının yasal yükümlülüklerini yerine getirmesi kayıt ve şartıyla alınacak olup iskân harcını ödemeyen hissedarlar diğer hissedarların bu nedenle uğrayacakları zararları karşılayacaktır.  
  
      SATICI; teslim süresinden önce Taşınmazı teslim etmek istediği takdirde ALICI teslim almak zorundadır.  ALICI teslim almaktan kaçınsa dahi teslim almış gibi tüm yükümlülükleri (aidat-işletme bedeli vs) yerine getirmekle mükelleftir.  `,
      caption: '5.2.',
    },
    {
      title: '',
      text: `Sözleşme’de belirtilen süre içinde ALICI’nın yazılı talebine rağmen Taşınmaz teslim edilmediği ve gecikmenin 6 aydan fazla sürdüğü takdirde, ek 6 aylık sürenin bitiminden sonra başlamak üzere her için, cezai şart ve aylık kira bedeli toplamı olarak, peşin Sözleşme bedelinin binde üçü SATICI tarafından ALICI’ya ödenir. Ancak SATICI’nın kusuru olmaksızın mücbir sebepler gibi kendisine bağlı olmayan nedenlerden dolayı oluşabilecek gecikmelerde ALICI’nın cezai şart ve aylık kira bedeli talep hakkı bulunmamaktadır. Cezai şart ve kira bedelinin ödenebilmesi için ALICI’nın yazılı talepte bulunması zorunludur.`,
      caption: '5.3.',
    },
    {
      title: 'MADDE 6. TAPU FERAĞ TAKRİRİNİN VERİLMESİ VE ANAHTAR TESLİM',
      text: `SATICI, Taşınmazın tapu ferağ takririni; ALICI’nın temel yükümlülüğü olan satış bedelinin yukarıda belirlenmiş ödeme şekli ve planına uygun olarak eksiksiz ödemesi, banka kredisi kullanması halinde krediyi kullandığı banka ve/veya finans kuruluşu lehine 1. veya 2. derecede ipotek vermek kaydı ve işbu Sözleşme ile yüklendiği diğer yükümlülüklerini de süresinde ve eksiksiz ifa etmesi koşulu ile teslim tarihini takip eden 6 (altı) ay içerisinde gerçekleştirilecektir. Vadeli ve senetli satışlarda ise Sözleşmede belirtilen ödeme taksitleri ile sair parasal yükümlülüklerin SATICI’ya ödenmesinden sonra Taşınmazın tapusu ALICI’ya devredilecektir. ALICI tarafından ödemeler tam ve eksiksiz olarak tamamlanmadığı sürece SATICI, ALICI’ya teslim dışında, tapu devir ve ferağı da yapmakla yükümlü değildir.`,
      caption: '6.1.',
    },
    {
      title: '',
      text: `Tapusunu alan ALICI, kendi mükellefiyetinin başladığı dönemden itibaren yasal süresi içinde ilgili belediyeye emlak beyanı vermek ve emlak vergilerini ve sair vergi, harç ve resimleri ödemekle yükümlüdür. Tapu işlemlerinde ALICI’dan kaynaklı yaşanacak gecikmeden dolayı doğabilecek usulsüzlük cezası, yönetime ilişkin ortak giderler ve sair tüm zararlardan münhasıran ALICI sorumlu olacaktır. `,
      caption: '6.2.',
    },
    {
      title: '',
      text: `SATICI’nın tüm bu bedelleri tahsil etmeden Sözleşme konusu Taşınmaza ilişkin tapu ferağ takririni ALICI’ya vermesi ve/veya Taşınmazı teslim etmesi; Sözleşme’nin 4.maddesinde belirtilen bedelleri almayacağı anlamına gelmez. Ayrıca SATICI tapu ferağ takririni, Taşınmazın fiili teslim tarihinden daha önceki bir tarihte, ALICI adına vermek istediği takdirde, ALICI tapu ferağ takririni alabilmesi için yerine getirmesi gereken tüm yükümlülükleri yerine getirerek, tapusunu teslim almak zorundadır. `,
      caption: '6.3.',
    },
    {
      title: '',
      text: `ALICI 4.maddede belirtildiği üzere, başta tapu devri ile ilgili masraf, vergi ve harçlar olmak üzere diğer vergi ve harçları, belirlenen sürede ödemezse, SATICI gecikmeden dolayı ALICI’dan ticari gecikme faizi oranında gecikme cezası da talep edebilir.  ALICI’nın bu konuda herhangi bir itirazı yoktur. `,
      caption: '6.4.',
    },

    {
      title: '',
      text: `Abonelik ve sigorta (DASK) işlemleri ALICI’nın abonelik ile ilgili bedelleri süresinde ödemesi kaydıyla SATICI tarafından tamamlanacak olup, bu bedeller zamanında ödenmediği takdirde; abonelik işlemlerini ALICI kendi yapmakla mükelleftir. Yasal zorunluluk gereği hizmetin alınması için tüm kat maliklerinin başvuruda bulunmasının gerekmesi halinde SATICI abonelik işlemlerini ALICI adına yapacak ve ödenecek bedeller talep edilmesinden itibaren 7 (yedi) gün içinde ALICI tarafından SATICI’ya ödenecektir. Bu işlemler tamamlanmadan ALICI Sözleşme’ye konu Taşınmazı kullanamaz.
  
      ALICI’nın bu madde kapsamında Taşınmazı teslim alamaması veya kullanamaması avans/aidat, vergi vs giderlere katılmasına engel değildir.`,
      caption: '6.5.',
    },
    {
      title: 'MADDE 7.  ALICI’NIN BEYAN, TEKEFFÜL VE YÜKÜMLÜLÜKLERİ',
      text: `ALICI, Proje’de yer alan, ekli Vaziyet Planında kırmızı renkli kalemle taralı, Taşınmazı, yine işbu Sözleşmede belirtilen  bedel mukabilinde ve Sözleşme’de yazılı koşullarla satın almayı vaat, kabul ve taahhüt etmiştir. 
  
      Taraflar, işbu Sözleşme konusu Taşınmazın Brüt Alanının Sözleşmede ve tasdikli ruhsat projesinde takribi olarak belirtildiğini; fiili teslimde brüt alanda (+)(-) %3 oranlarında farklılık gösterebileceğini kabul etmişlerdir. Taraflar, fiili teslim sırasında brüt alandaki farklılığın (+)(-) %3’ten daha fazla olması halinde %3’ü aşan veya eksik kalan metrekareden sonraki farkların Taşınmazın Satış Bedelini orantısal olarak arttıracağını veya azaltacağını kabul etmişlerdir. (+)(-) %3’e kadar olan farklılıklarda Tarafların birbirlerinden herhangi bir talepleri olmayacaktır.`,
      caption: '7.1.',
    },
    {
      title: '',
      text: `Proje işbu sözleşmenin imza tarihinde tek parselden oluşmakta ise de daha sonra başka parseller eklenmek suretiyle proje SATICI tarafından birden fazla etap meydana getirilebilir ve/veya yeni eklenen parsel/ler ile toplu yapı oluşturulabilir. ALICI bu hususu bildiğini beyan ve kabul eder. ALICI, projeye dahil edilebilecek diğer parseller arasından kamuya açık imar yollarının geçtiğini bildiğini beyan eder. ALICI ayrıca mimari projede sözleşme konusu Taşınmaza ilişkin ilgili kısımları incelediğini ve ilerde her ne nam altında olursa olsun bu konuda SATICI’dan herhangi bir talepte bulunmayacağını beyan, kabul ve taahhüt eder. 
  
      İşbu sözleşme ile SATICI tarafından ALICI’ya verilen tüm taahhütler, sadece Taşınmazın üzerinde yer aldığı parsele ilişkindir. ALICI, Proje kapsamında kendi Taşınmazı dışında diğer taşınmazlara da bir bahçe ve otopark başta olmak üzere bir takım tahsisler yapıldığını bildiğini, lehine bahçe veya sair tahsisi yapılan taşınmaz maliki/sakini dışında, diğer taşınmaz malikleri/sakinleri, ilgili taşınmaz maliklerine tahsis edilmiş alanları hiçbir şekilde kullanamayacağını bildiğini ve itirazı olmadığını beyan ve kabul etmiştir.`,
      caption: '7.2.',
    },
    {
      title: '',
      text: `ALICI, SATICI’nın, işbu Sözleşme’nin imzalanmasından sonra Belediye veya diğer resmi kurumlar tarafından bildirilen emirler, yasaklar ya da teknik zorunluluklar nedeni ile veya SATICI’nın gerekli veya faydalı görmesi nedeniyle, Sözleşme konusu Taşınmazın brüt alanı, konumu ve kalitede düşüklük olmaması kaydı ile Teknik Şartnamede ve Vaziyet Planında değişiklik yapabileceğini bildiğini kabul, beyan ve taahhüt eder. `,
      caption: '7.3.',
    },
    {
      title: '',
      text: `ALICI, alacağı Taşınmazın Brüt Alanı’nda ve konumunda değişiklik olmaması kaydı ile mimari projenin tasdikinden sonra Proje ve/veya Taşınmaz isminde ve numarasında değişiklik olabileceğini şimdiden kabul etmiştir. ALICI’nın bu konuda hiçbir itirazı yoktur.`,
      caption: '7.4.',
    },
    {
      title: '',
      text: `ALICI, ekli Teknik Şartname’ye uygun olarak genel standart ve donatımı ile tam olarak kabul etmiş sayılır. Bunda değişiklik ve ilave isteyemeyeceği gibi, teslim aldıktan sonra, Sözleşme konusu Taşınmazın taşıyıcı sistemlerini bozmamak, giriş kapısı ve dış cepheye ilişkin hiçbir şeyi değiştirmemek, cam balkon vs gibi uygulamalar yapmamak, silüeti bozmamak kayıt ve şartıyla tadilat ve imalat yapabilir. ALICI bu maddeye aykırı olarak herhangi bir tadilat veya imalat yaptırdığı takdirde hukuki ve cezai sorumluluk kendisine ait olup, ayrıca Taşınmaz metrekarelerinin değişmesi nedeniyle vergi artışı ve/veya herhangi bir vergi cezası doğması halinde de ALICI söz konusu cezadan tek başına sorumlu olacağını peşinen kabul, beyan ve taahhüt eder. Proje kapsamında yer alan işbu sözleşmeye konu Taşınmaz da dahil tüm taşınmazların yapı ve tahsis sınırları kısmen duvar ve/veya peyzaj öğeleri ile ayrılmış durumdadır. Bu sınırların proje dahilinde bir bütünlük ve görsel açıdan muntazam görünmesi önem arz etmekte olup; ALICI bu sınırlara müdahale etmeyeceğini, duvar ve/veya peyzaj yüksekliğini, şeklini, kullanılan malzemeyi değiştirmeyeceğini beyan, kabul ve taahhüt eder. Bu taahhüt tapu devrine kadar işbu Sözleşme kapsamında, tapu devrinden sonra da tapuya işlenen/işlenecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesi” kapsamında devam edecektir. ALICI, her iki sözleşme kapsamında da yükümlülüklerine uyacağını ve şayet aykırı bir uygulama söz konusu olur ise sözleşmede öngörülen yaptırımlar kendisi aleyhine işletileceğini bildiğini ve buna bir itirazı olmadığını kabul eder.   `,
      caption: '7.5.',
    },
    {
      title: '',
      text: 'ALICI, SATICI tarafından teslim alması için yazılı olarak davet etmesinden itibaren mücbir sebepler dışında 30 gün içinde bilfiil kendisi veya noterden vereceği vekâlete binaen bir vekil aracılığı ile Taşınmazı işbu sözleşmeye uygun olduğunu kontrol ederek teslim almak zorundadır.',
      caption: '7.6.',
    },
    {
      title: '',
      text: 'ALICI kendisine yapılan davet üzerine satın aldığı ve kendisine teslim edilmek istenen Taşınmazı;',
      caption: '',
    },
    {
      title: '',
      text: `Tamamlanmış olmasına rağmen teslim almaması veya teslim edilmek üzere yapılan vaki davete 30 gün içerisinde katılmaması halinde, Taşınmazı eksiksiz teslim almış sayılacak ve SATICI’dan açık ayıplarla ilgili herhangi bir talepte bulunmayacaktır. ALICI ayrıca Taşınmazı teslim alması için yapılan davet tarihinden itibaren site ile ilgili her türlü yönetim giderleri (aidat-işletme vs) ve ortak masrafları tapuya işlenen/işlenecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinde” belirtilen oranda ödemek zorundadır. Ayrıca Taşınmazla ilgili bakım, onarım giderleri ile vergi, resim, harçları da ödemek zorundadır. ALICI bu giderleri ödemekte gecikmesi halinde, aylık %5 gecikme tazminatı ve yasal faizi ile ödemek zorundadır.`,
      caption: 'a.',
    },
    {
      title: '',
      text: 'ALICI yönetim ve ortak masrafları ödememesi ve bu bedellerin SATICI tarafından karşılanması durumunda, ALICI ödenen bedeli SATICI’ya ödemekle birlikte, geciken her ay için %5 gecikme tazminatı ödeyecektir.',
      caption: 'b.',
    },
    {
      title: '',
      text: 'Hazır olan Taşınmazı davete rağmen, belirlenen tarihe kadar teslim almayan ALICI, işbu tarihten sonra, Taşınmazın teslim edilmediği gerekçesi ile Sözleşmenin 5.3üncü maddesinde belirtilen cezai şart ve kira bedeli talebinden vazgeçmiş sayılacağını kabul ve beyan eder.',
      caption: 'c.',
    },
    {
      title: '',
      text: `ALICI vaki davet üzerine Taşınmazı SATICI ile birlikte kontrol ederek, Taşınmazda Sözleşmeye uygun olarak tamamlanmamış olan eksiklikler var, ancak bu eksiklikler Taşınmazın kullanımına engel teşkil etmiyorsa, Taşınmazı mevcut hali ile teslim alacaktır. Ancak bu aşamada düzenlenecek teslim zaptında eksiklikler ve giderilme süresi belirtilecektir. SATICI bu eksiklikleri belirtilen süre içinde tamamlayarak, Taşınmaz ALICI’ya teslim edecektir. ALICI, Projedeki diğer taşınmazlarda veya eklenmesi halinde diğer etaplarda inşaatın devam etmesinin, çevre düzenlemesiyle sosyal alanların tamamlanmamış ve henüz faaliyete geçmemiş olmasının kullanıma engel teşkil ettiğini ileri sürmeyeceğini kabul ve taahhüt eder. `,
      caption: 'd.',
    },
    {
      title: '',
      text: 'Kullanıma engel olmayan eksikliklerin bulunması durumunda ALICI Taşınmazı teslim almaktan imtina etmesi halinde bu eksiklikler tamamlanıp, Taşınmaz ALICI’ya teslimine kadar geçen sürede Sözleşmenin 5.3.maddesinde belirtilen cezai şart ve rayiç kira bedeli ALICI tarafından talep edilmeyecektir.',
      caption: '',
    },
    {
      title: '',
      text: `ALICI, SATICI tarafından yapılan vaki davet üzerine Taşınmazı kontrol ederek, Sözleşme’ye göre tamamlanmamış olan eksiklikler var ise ve bu eksiklikler Taşınmazın kullanımına engel teşkil ediyorsa Taşınmazı teslim almaktan imtina etmek hakkına sahiptir. Bu husus, taraflarca müştereken tanzim edecekleri bir zapta yazılacaktır. Zapta tüm eksiklikler ve tamamlama süreleri de yazılacaktır. `,
      caption: 'e.',
    },
    {
      title: '',
      text: 'ALICI, inşaat faaliyeti devam ettiği sürece, Taşınmazını görmek için şantiye sahasına izinsiz giremez.  SATICI’nın bilgisi dışında ALICI’nın inşaat sahasına girmesi fevkalade sakıncalı olup; bu durumda meydana gelebilecek kazalardan ve üçüncü kişilere verilecek her türlü zarardan ALICI sorumlu olacaktır.',
      caption: '7.7.',
    },
    {
      title: '',
      text: 'ALICI, sözleşme bedelini, süresinde ödemek zorundadır. ALICI iki (2) taksitini süresinde ödemediği takdirde ve/veya ödenmeyen taksit tutarları toplamının ve/veya bankaya ödemesi gerekip de ödemediği tutarların toplamının satış bedelinin %10’undan fazla olması halinde SATICI kendisine otuz (30) gün süre tanıyarak muacceliyet uyarısında bulunur. Bu süre içerisinde ödemelerini yapmaması durumunda geri kalan taksitler muaccel hale gelir. SATICI, bu durumda Sözleşme’nin haklı sebeple feshini talep de edebilir.',
      caption: '7.8.',
    },
    {
      title: '',
      text: 'Tapu için istenilen evrakları zamanında göndermeyerek tapu devrinin gecikmesine neden olan ALICI, SATICI tarafından ödenmek durumunda kalınan vergi harç ve masrafları faiziyle birlikte SATICI’ya ödemeyi taahhüt eder. SATICI’nın 4.3. ve 6.4.maddede belirtilen hakları saklıdır.',
      caption: '7.9.',
    },
    {
      title: '',
      text: 'İskân; (yapı kullanma izin belgesi) Projedeki inşaatının tamamlanmasını müteakip, o tarihte taşınmaz maliklerinin mali yükümlülüklerini (Belediye’ye bina olarak emlak vergisi beyannamesi ile Vergi Dairesine  ile ilgili olarak ödenmesi gereken harç ve vergileri, cins tashih harcını ve Belediye’ye ödenmesi gereken iskan harcının hissesine isabet eden kısmının ödemiş olmaları) yerine getirmiş olmaları ve yürürlükteki mevzuatın imkan vermesi ve idarenin de onaylaması kaydıyla 6 (altı) ay içerisinde SATICI tarafından alınacaktır. ALICI üzerine düşen yükümlülüğü yerine getirmemesi halinde iskanın alınmamasıyla ilgili mali, hukuki ve cezai tüm sorumluluk ve yükümlülüğün tarafına ait olduğunu kabul eder. ',
      caption: '7.10.',
    },
    {
      title: '',
      text: 'İskan alınması için yukarıdaki maddede tanınan süre içerisinde, Proje dahilindeki doğalgaz, su, elektrik vs. nedeniyle gerçekleşen kullanımdan kaynaklanan tüm bedeller ve aidat-işletme giderleri, avans ve sair Proje genel giderleri ALICI tarafından karşılanacaktır. ALICI bu hususu işbu Sözleşme ile gayri kabili rücu kabul beyan ve taahhüt eder.',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI ekte örneği verilen genel vekâletnameyi SATICI adına ileri bir tarihte, kendisine bildirilmesi üzerine, vermeyi kabul ve taahhüt etmiştir. Ekteki vekaletname gereği, ALICI’nın vekili azletmesi veya bu yetkileri kullanılamaz hale getirmesi halinde, SATICI’nın her türlü zararı ALICI tarafından karşılanacaktır. ',
      caption: '7.11.',
    },
    {
      title: '',
      text: 'ALICI, gerekli hukuki ve teknik desteği aldığını, işbu Sözleşme konusu Taşınmazın içinde bulunduğu Projenin Turizm Tesis Alanı imarına sahip olduğunu, Projede kat irtifakı kurulamayacağını ve kendisine SATICI tarafından arsa payı devredileceğini bildiğini ve işbu Sözleşmeyi bu şartlarda kabul ettiğini, beyan, kabul ve taahhüt eder.',
      caption: '7.12.',
    },
    {
      title: '',
      text: 'ALICI ayrıca Proje kapsamında inşa edilecek 73 adet (SATICI’nın gerekli görmesi halinde bu sayı azaltılabilir veya arttırılabilir. ALICI’nın Taşınmazının konumu ve büyüklüğünde bir değişiklik olmadığı takdirde bu artış veya azalışa ALICI itiraz edemez.) taşınmazın her birine, taşınmazların konum, büyüklük, değer ve sair özellikleri dikkate alarak arsa payları belirlendiğini, tapuda işlenmemiş olsa dahi bu arsa paylarının projede inşa edilecek taşınmazlarla (yapılarla) sıkı sıkıya bağlı olduğu ve birbirinden ayrı düşünülemeyeceğini bildiğini, kat irtifakı kurulamaması ve arsa paylarının farklı hissedarlar adına kayıtlı olmasından dolayı, kendisi tarafından diğer hissedarlar aleyhine izaleyi şüyu davası açmayacağını veya arsa payının satışı halinde şufa hakkını kullanmayacağını, tapuya işlenmiş/işlenecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesini” bildiğini ve itirazsız kabul ettiğini, izaleyi şüyu davası ve şufa haklarından işbu Sözleşme ile feragat ettiğini beyan, kabul ve taahhüt eder.',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI, SATICI’nın diğer hissedarlara bu kapsamda verdiği taahhüdü bildiğini ve şayet bu madde kapsamındaki taahhütlerine aykırı davranması halinde SATICI’nın her türlü zararını karşılayacağını, SATICI’nın kendi taahhüdüne aykırılık nedeniyle bir bedel ödemek zorunda kalması halinde bu bedeli kendisinin ödeyeceğini gayri kabili rücu beyan, kabul ve taahhüt eder.',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI, Projenin Turizm Tesis Alanı imarına sahip olmasından dolayı, Taşınmazını buna uygun kullanacağını, işbu Satış Vaadi Sözleşmesinin hiçbir suretle konut satışı niteliğinde olmadığını, bu kapsamda yetkili idareler ve SATICI tarafından kendisine bildirilen talimatlara gecikmeye mahal vermeksizin uyacağını, Taşınmazın niteliğine aykırı kullanılmasından kaynaklanan her türlü zararın kendisi tarafından karşılanacağını ve bu konuda bir zararın oluşmaması için gereken tedbirleri alacağını  beyan, kabul ve taahhüt eder.',
      caption: '7.13.',
    },
    {
      title: 'MADDE 8.	PROJENİN YÖNETİMİ VE ORTAK GİDERLER',
      text: 'Proje kapsamındaki bütün yapılar, ortak yer, yapı ve tesisler tamamlanıncaya ve istikrar tesis edilinceye kadar, tapuya işlenmiş/işlenecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinde” Yönetici olarak tanımları yapılmış, yönetim organları için öngörülmüş tüm hak ve yetkiler ve gerekli tüm girişim ve çağrılarda bulunmak üzere, bu sıfatla ana taşınmazın yönetim ve işletmesinin başladığı tarihten itibaren on yıl boyunca SATICI tarafından atanan kişilere veya Hizmet Şirketine aittir.',
      caption: '8.1.',
    },
    {
      title: '',
      text: 'Aksi tapuya işlenmiş/işlenecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesini” kararlaştırılmadıkça Projesi kapsamında yer alan ve herhangi bir taşınmaza tahsis edilmemiş ortak alan ve sosyal tesislerle ilgili her türlü gider (işletim, malzeme alımı, bakım, temizlik, gözetim, güvenlik vs) hissedarlar tarafından karşılanacaktır. Proje kapsamında taşınmazlara tahsis edilen bahçe ve havuzların bakım ve onarımları da ücreti karşılığında yönetici/hizmet şirketi tarafından karşılanacaktır. ',
      caption: '',
    },
    {
      title: '',
      text: 'Her hâlükârda sosyal tesis ve donatı alanların faaliyete geçebilmesi projedeki tüm taşınmazların yüzde yetmiş doluluk oranına ulaşmasına bağlıdır.',
      caption: '8.2.',
    },
    {
      title: '',
      text: 'SATICI mezkûr proje dâhilinde yer alan sosyal tesis ve donatı alanlarını tefriş etmeden, boş olarak Proje Yöneticisine teslim etmekle yükümlüdür.',
      caption: '8.3.',
    },
    {
      title: '',
      text: 'ALICI, tapuya şerh edilmiş/edilecek “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinin” bağlayıcılığını kabul ve taahhüt eder. ',
      caption: '8.4.',
    },
    {
      title: '',
      text: 'ALICI Taşınmazın anahtar ve/veya tapu teslim tarihinden sonra, “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinin” tüm hükümleri ile hissedarlar tarafından alınmış veya alınacak bilcümle kararlara aynen uymak ve  yukarıda belirlendiği şekilde ve fiilen teslim aldığı tarihten itibaren Taşınmazı kullansın/kullandırsın kullanım ile ilgili masraflar ile yönetimin saptayacağı giderlere “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinde” belirlenen oranda katılmak ve bunları süresinde ödemek mecburiyetindedir. ',
      caption: '8.5.',
    },
    {
      title: '',
      text: 'Gider ve işletme payını, bildirilen tarihte ödemeyen ALICI, ödemede geciktiği günler için aylık %5 gecikme tazminatı ödemekle yükümlüdür.',
      caption: '8.6.',
    },
    {
      title: '',
      text: 'Ortak giderlere ve zamanında yapılmayan ödemelere ilişkin olarak “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesinde” yer alan hüküm, yaptırım ve yasal yollar bu sözleşmeden doğan yükümlülükler için de aynen geçerlidir. ',
      caption: '',
    },
    {
      title: 'MADDE 9.	ELEKTRİK - SU - DOĞALGAZ V.S. ABONMAN İŞLEMLERİ',
      text: 'ALICI, Taşınmaza elektrik, su, doğalgaz, telefon v.s. bağlanmasına ve Zorunlu Deprem Sigortası (DASK) ilişkin abonman ve sözleşme giderlerini ödeyecektir. ALICI bu giderleri, talep üzerine ilgili kuruma ve kişiye ödemekle yükümlüdür. Taşınmaza ait olan bu tesislerin kullanım giderleri ALICI’ya aittir. ',
      caption: '',
    },
    {
      title: 'MADDE 10.	MÜCBİR SEBEBLER',
      text: 'Olağanüstü haller, genel veya kısmi seferberlik halleri, savaş hali, yangın ve bulaşıcı hastalıklar, sel ve deprem gibi tabii afetler, grev, lokavt, direniş, halk hareketleri, ulaşımın kesilmesi, elektrik ve akaryakıt kısıtlaması dahil ve bunlarla sınırlı olmaksızın ilgili yasalarda belirtilen mücbir sebepler ve Taraflar’ın denetimi dışında olan, kendilerinden kaynaklanmayan ve Taraflarca önceden öngörülemeyen haller, mücbir sebep sayılacaktır.',
      caption: '',
    },
    {
      title: '',
      text: 'Mücbir sebepten kaynaklanan gecikmeler inşaat süresine ilave edilecektir. Bunun yanı sıra resmi merciler ve Belediyelerden alınan izinlerin geçici olarak iptal edilmesi, yetkili makamların emir ve yasakları, yapımı ve tahsisi, SATICI’nın elinde olmayan ve resmi idarelerden, adli kurum ve mahkemelerden kaynaklı nedenler ve bunlara bağlı işçilik,  malzeme ve enerji teminindeki aksamalar sebebiyle meydana gelebilecek gecikmeler, her türlü idari izin, ruhsat ve sair işlemlerde meydana gelecek gecikmeler, olağanüstü iklim ve hava koşulları nedeniyle oluşan gecikmeler, inşaat teslim süresine ilave edilecek olup, bu ilave süreler için cezai şart talep edilemeyecektir. ALICI’ya ait yükümlülüklerin süresinde ve gereği gibi ifa edilmemesi veya mücbir sebepler nedeniyle oluşan gecikmeler, yukarıda belirtilen inşaat bitim ve teslim tarihine eklenecek ve bu tarih aynı nispette uzatılmış kabul edilecektir. Bu tür süre uzatımları İşbu Sözleşme’nin 5.3.maddesinde belirlenen gecikme cezasına tabi değildir. ',
      caption: '',
    },
    {
      title: 'MADDE 11.	SÖZLEŞMENİN FESH EDİLMESİ ve SÖZLEŞMEDEN DÖNME',
      text: 'ALICI’nın iki taksitini vade tarihinde ödememesi ve bu taksitlerin toplamının veya bankaya ödemesi gerekip de ödemediği kredi bedelinin veya tüm bunların toplamının satış bedelinin %10’undan fazla olması halinde, SATICI tarafından kendisine verilen 30 (otuz) günlük muacceliyet uyarısına rağmen, herhangi bir ödemesini süresinde yapmaması durumunda, ',
      caption: '11.1.',
    },
    {
      title: '',
      text: 'SATICI’nın, işbu Sözleşme gereği edimini ifa edebilmesi için verilmesi/ödenmesi zorunlu hususların veya işbu Sözleşmede düzenlenen taahhütlere aykırı davranılması halinde bu aykırılığın giderilmesine yönelik olarak ALICI’nın bildirdiği adrese yapılacak tebliğde belirtilen taleplerin 10 (on) günlük sürede, ALICI tarafından hazırlanıp mücbir sebepler dışında SATICI’ya verilmemesi veya aykırılığın giderilmemesi, talep edileni yerine getirmemesi/ödeme yapmaması durumunda,',
      caption: '11.2.',
    },
    {
      title: '',
      text: 'ALICI tarafından tapu devir işlemleri için gerekli belgelerin, talep halinde SATICI’ya verilmemesi ve/veya tapu harç bedellerinin ödenmemesi durumunda, ',
      caption: '11.3.',
    },
    {
      title: '',
      text: 'SATICI işbu Sözleşme’yi tek taraflı olarak feshedebilir.',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI, SATICI’nın temerrüdüne bağlı olarak kanundan doğan seçimlik haklarını, ancak temerrüt (mücbir sebepler dışında) SATICI’nın kusuruyla, belirlenen teslim tarihinden 6 (altı) ay geçtikten sonra hala teslim gerçekleşmemiş ise kullanabilir. ',
      caption: '11.4.',
    },
    {
      title: '',
      text: 'Sözleşme’nin feshi veya ALICI’nın sözleşmeden dönmesi akabinde, ALICI’nın, SATICI’ya o tarihe kadar ödediği bedellerden gerekli kesintiler (ALICI’nın ödemediği ve/veya SATICI tarafından ALICI adına ödenen aidat/işletme bedelleri, komisyonlar, kredi taksitleri, vergi, elektrik, su, doğalgaz v.b. giderler) yapıldıktan ve ödenilen bedelin %15’i oranında (+KDV) cezai şart bedeli düşüldükten sonra 120 gün içerisinde faizsiz iade olunur. Şayet Taşınmazı ALICI’ya teslim edilmiş ise ödenen bedelin iadesi için satışa konu Taşınmazın ALICI tarafından SATICI’ya teslim alındığı halde teslim edilmesi gerekir. Bu halde iade yine ilgili kesintiler ve cezai şart düşüldükten sonra, Taşınmazın teslim tarihinden itibaren 120 gün içerisinde yine faizsiz olarak iade olunur. Yine varsa sözleşme kapsamında ALICI tarafından verilen senetler iptal edilmek suretiyle, aynı süre içinde ALICI’nın bizzat müracaatı üzerine kendisine iade edilecektir.',
      caption: '11.5.',
    },

    {
      title: '',
      text: 'Sözleşmesi feshedilen veya kendisi sözleşmeden dönen ALICI, SATICI’dan faiz ya da her ne nam altında olursa olsun talepte bulunamayacağını, hak iddia edemeyeceğini peşinen kabul ve taahhüt etmiştir.',
      caption: '11.6.',
    },
    {
      title: '',
      text: 'ALICI’nın kiracıları veya ALICI adına işgal edenler işbu Sözleşme hükümlerine aynen uymayı kabul ve beyan eder.',
      caption: '11.7.',
    },
    {
      title: '',
      text: 'Sözleşmesi feshedilen veya kendisi sözleşmeden dönen ALICI, satın aldığı haliyle, fesih tarihinden itibaren on beş gün içinde tahliye ederek SATICI’ya teslim etmek zorundadır. Taşınmaz belirtilen sürede tahliye edilmezse tahliye tarihine kadar işleyecek ve sözleşme bedelinin yüzde iki oranında aylık kira bedelleri ile birlikte ödenen bedelinin %15’i (+KDV) kadar bedeli cezai şart bedelini ödeyeceğini veya bu kadar bedelin ödediği bedelden kesinti yapılmasını gayri kabili rücu olarak kabul ve taahhüt eder.',
      caption: '11.8.',
    },
    {
      title: '',
      text: 'Sözleşme’nin feshedilmesi veya ALICI’nın sözleşmeden dönmesi halinde Sözleşme Konusu Taşınmaz’ın tapusunun devrinin ALICI’ya yapılmış olması halinde; ALICI 3 (üç) gün içerisinde tapuda taşınmazın yeniden SATICI’ya devri için bizzat müracaat edecek veya noterden vekaletname düzenleyerek SATICI’ya teslimini sağlayacaktır. Tapu devrinin belirtilen sürede gerçekleşmemesi halinde tapu devri gerçekleşene kadar ALICI’ya herhangi bir ödeme yapılmayacağı gibi, SATICI’nın tüm zararı ALICI tarafından derhal ödenecektir. Tapunun daha önce ALICI’ya devredilmiş olması halinde yeniden SATICI’ya devri sırasında ödenmesi gereken tapu alım-satım harcı ile şayet daha önce fatura düzenlenmiş veya düzenlenecek ise faturadaki KDV ALICI tarafından karşılanacaktır.',
      caption: '11.9.',
    },
    {
      title: 'MADDE 12.	TEBLİGAT ADRESİ:',
      text: 'Tarafların tebligat adresleri, Sözleşme’nin ilk kısmında yazılıdır. Adres değişiklikleri, değişikliğin yapıldığı tarihten itibaren 10 gün içerisinde noter aracılığı veya bizzat başvurularak ıslak imzalı dilekçe ile karşı tarafa bildirilmedikçe sözleşmede bildirilen adreslere yapılan tebligat, geçerli bir tebligatın tüm hukuki sonuçlarını doğurur.',
      caption: '',
    },
    {
      title: 'MADDE 13.	ÖZEL HÜKÜMLER',
      text: 'İşbu Düzenleme Şeklinde Gayrimenkul Satış Vaadi Ve İnşaat Yapım Sözleşmesi, TARAFLARIN BİRLİKTE MÜRACATI İLE Taşınmazın ALICI’ya teslimini müteakip, mevzuatın imkan tanıması halinde ve masrafı ALICI’ya ait olmak üzere ilgili Tapu Dairesi’nde şerh edilebilecektir. ',
      caption: '13.1.',
    },
    {
      title: '',
      text: 'ALICI, Sözleşme içeriğinde belirtilen kendisine ait olan borçları süresinde ve/veya talep edildiğinde ödemediği takdirde SATICI’nın yazılı uyarısı ile on beş (15) gün içerisinde şerhi kaldıracaktır. Aksi halde geçen her gün için toplamı sözleşme bedelini aşmamak kaydıyla 2.000 TL cezai şart ödeyecektir. ',
      caption: '',
    },
    {
      title: '',
      text: 'SATICI’nın, Sözleşme dışındaki satış ile ilgili diğer evrak, broşür, ilan veya reklâmlarda kullandığı tefriş amaçlı yer alan ürünleri (şezlong, bank vs) projede uygulamak zorunluluğu yoktur. Ayrıca teknik şartnamede yer almayan veya teknik şartnameden farklı gösterilen projeye ait örnek taşınmazda veya kataloglarda/ilanlarda yer alan özellikler tanıtım amaçlı konulmuş olup; yer ve duvar döşemeleri (duvar kağıtları ve perdeler dahil), aksesuarlar da sözleşme kapsamında olmayıp; farklılık halinde teknik şartname esas alınacaktır. ',
      caption: '13.2.',
    },
    {
      title: '',
      text: 'Taraflar karşılıklı tüm taahhütlerin bu Sözleşme’de belirtildiğini, bu Sözleşme ve eklerinde bulunanlar dışında birbirlerine karşı başkaca taahhütleri olmadığını kabul ve beyan ederler.',
      caption: '13.3.',
    },
    {
      title: '',
      text: 'SATICI, mezkur projede taşınmazların cephesinde, teknik zorunluluklar, mücbir sebepler ve SATICI tarafından öngörülen faydalı işler nedeniyle makette, renderlarda görünenler dışında değişiklik yapmak hakkına sahiptir. Maket, broşür, tanıtım, Projeye ilişkin her türlü görsel ve örnek taşınmaz ALICI’ya herhangi bir talep hakkı tanımadığı gibi, SATICI’nın tüm bunlarda değişiklik yapma hakkı olduğunu ALICI kabul ve beyan eder. ',
      caption: '13.4.',
    },
    {
      title: '',
      text: 'ALICI, Proje’ye dahil edilebilecek parseller arasından ve deniz ile sözleşme konusu Taşınmazın bulunduğu parsel arasından geçen yolların kamuya açık imar yolu oluğunu bildiğini ve bunu bilerek işbu Sözleşme’yi düzenlediğini, ilerde her ne nam altında olursa olsun bu sebeplerle SATICI’dan herhangi bir hak ve talepte bulunmayacağını beyan, kabul ve taahhüt eder.',
      caption: '13.5.',
    },
    {
      title: '',
      text: 'ALICI, SATICI’nın işbu Sözleşmedeki hak ve yükümlülüklerini kendisinden herhangi bir onay almaksızın üçüncü kişilere devredebileceğini, temlik yapabileceğini ortak alabileceğini, projeyi en geniş manada ortaklıklara konu edebileceğini kabul eder.',
      caption: '13.6.',
    },
    {
      title: '',
      text: 'ALICI, işbu sözleşme içeriğindeki gayrimenkulü fiili ve hukuki teslim tarihine kadar üçüncü bir şahsa devredemez. ',
      caption: '13.7.',
    },
    {
      title: '',
      text: 'ALICI’nın, bu maddeye aykırı hareket ederek, mezkûr Taşınmazı, tarafına fiili olarak teslim edilmeden üçüncü bir şahsa devretmesi durumunda, Sözleşme’nin tarafı olarak mezkur gayrimenkulü davet edildiği tarihte teslim almaya gelmediği takdirde; sözleşmeye konusu Taşınmazı, süresi içinde tamamlanmış; “Satış Sözleşmesi”, “Teknik Şartname”, “Vaziyet Planı”na uygun, kullanıma hazır, eksiksiz ve tam olarak, teslim almış sayılacağını, Taşınmaza ilişkin olarak imzalanmış bulunan yazılı sözleşmeler nedeniyle SATICI’dan, gizli ayıplarla ilgili talepler saklı kalmak kaydıyla, her ne nam altında olursa olsun hiçbir hak ve alacak talebinin olmadığını; mezkur “Düzenlenme Şeklinde Satış Vaadi Sözleşmesi” gereği SATICI’yı gayri kabili rücu ibra ettiğini, kabul beyan ve taahhüt eder. ',
      caption: '',
    },
    {
      title: '',
      text: 'Devir yasağının tek istisnası; ALICI, SATICI’nın muvafakat göstermesi halinde, işbu sözleşmeden doğan tüm borçlarını ödemiş olması ve tapu devrinin kendisine yapılmasını müteakip, ALICI, SATICI ve Devralan 3.Şahıs arasında noter marifetiyle, işbu sözleşmedeki şartları da içerir şekilde “Düzenlenme Şeklinde Satış Vaadi Sözleşmesi’nin Devir ve Temliki Sözleşmesi” imzalanmasıdır. Bahsi geçen devir sözleşmesi gereği, Taşınmazın devreden ALICI, Devralan 3.Şahıs ile birlikte Sözleşme konusu Taşınmaz ilgili olarak işbu Sözleşme’nin 4.maddesinde belirtilen borç ve yükümlülüklerden ve işbu Sözleşme’den doğan diğer tüm yükümlülüklerden, müşterek ve müteselsilen sorumlu olacaktır.',
      caption: '',
    },
    {
      title: '',
      text: 'Devir işleminin geçekleşmesi üzerine Devralan 3. Şahıs, ALICI’nın işbu Sözleşme’deki hak ve borçlarının da halefi olacaktır. Bu nedenlerle ALICI Taşınmazın devri sırasında, Devralan 3.şahsa Maliye’ye ödenmesi gereken cins tashih harcı, iskân alımında Belediyeye ödenmesi gereken harç, kat mülkiyetinin kurulması sırasında ödenmesi gereken harç bedellerini v.s. ödemesi gerektiği konusunda bilgi vermekle yükümlü olup, aksi halde ilgili bedellerin ödenmemesinden doğan sorumluluğun ve yükümlülüğün tarafına ait olacağını işbu Sözleşme’nin imzalanması ile birlikte kabul ettiğini beyan ve taahhüt etmiştir.  ',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI, işbu Sözleşme’nin ilgili maddeleri gereğince SATICI tarafından Taşınmaz bedeline ilişkin fatura kesilecek olup, ALICI işbu faturaya ilişkin KDV bedelini süresinde ödeme yükümlülüğünün (devre rağmen) tarafına ait olduğunu kabul, beyan ve taahhüt eder.',
      caption: '',
    },
    {
      title: '',
      text: 'ALICI’nın yukarıda anlatılan prosedüre uymadan, Taşınmazı, SATICI’nın bilgisi ve muvafakati dışında, 3.bir şahsa devretmesi halinde; SATICI’nın tek muhatabı (devre rağmen) ALICI olacak ve mezkur Taşınmazın ilgili tüm yükümlülüklerden (KDV bedeli, iskan harçları vs ödenmesi gibi) ALICI sorumlu tutulacaktır. Bu durumda ALICI  3.bir şahsa devrettiği ve ilgili bedellerin 3. şahıstan alınması gerektiği savunmasında bulunamaz.',
      caption: '',
    },

    {
      title: '',
      text: 'Sözleşmenin içerdiği hükümlerden bir kısmının kanun hükmü/idari merci kararı veya yargı kararı ile hükümsüz olması, diğerlerinin geçerliliğini etkilemez. Bu durumda taraflar bu hükümler olmaksızın sözleşmenin yapılmayacağı yolunda iddiada bulunmayacaktır.',
      caption: '13.8. Sözleşmedeki Bazı Hükümlerin Geçersizliği ',
    },
    {
      title: '',
      text: 'ALICI’nın birden fazla gerçek ve/veya tüzel kişinin oluşturduğu hallerde, bu kişiler işbu sözleşmedeki ALICI yükümlülüklerinden müteselsilen sorumludurlar. Devredilecek hisse tarafların yaptıkları ödeme miktarı dikkate alınmaksızın kişilere eşit paylı olarak çıkarılacaktır. ',
      caption: '13.9.',
    },
    {
      title: '',
      text: 'İşbu sözleşmeden doğan yükümlülüklerin tamamının ifa edilmemesi halinde anılan kişilerden birisi veya bazıları paylarına isabet edecek şekilde tapu devri yapılmasını talep edemez. SATICI tarafından ALICI’lardan birine yapılacak tebliğ veya birinden alınacak belge ve ibranameler, birine yapılacak teslim tüm alıcılar için geçerli kabul edilecektir.',
      caption: '',
    },
    {
      title: 'MADDE 14.	SÖZLEŞMENİN EKLERİ:',
      text: 'Teknik Şartname, Vaziyet Planı, Alıcı tarafından Satıcı’ya verilecek vekaletname örneği, tapuya şerh edilmiş olan “Şuyuun İdamesi ve Şufa Hakkından Feragat Sözleşmesi” işbu Sözleşme’nin ayrılmaz ve aynı derecede hüküm taşıyan ekleridir. ',
      caption: '',
    },
    {
      title: '',
      text: `İş bu Sözleşme taraflarca okunup, hür iradeleriyle  ${formattedDate} tarihinde akdedilmiştir. `,
      caption: '',
    },
    {
      title: '',
      text: `SATMAYI VAAD EDEN (SATICI)	: ${data.inventoryId.queryOwnerInventoryName}`,
      caption: '',
    },
    {
      title: '',
      text: `ALMAYI VAAD EDEN (ALICI)	: ${contactData.name} ${contactData.surname}`,
      caption: '',
    },
  ];

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          display: 'flex',
          fontFamily: 'Roboto',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
          fontWeight: 400,
        }}
      >
        <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
          DÜZENLEME ŞEKLİNDE GAYRİMENKUL SATIŞ VAADİ SÖZLEŞMESİ
        </Text>
        <Text> </Text>
        <Text style={{ fontWeight: 500 }}>{`MADDE 1.   TARAFLAR `}</Text>
        <Text>
          {`${data.inventoryId.queryOwnerInventoryName}
          ${data.inventoryId.address}
          ${data.inventoryId.city} / ${data.inventoryId.country}
          ${data.inventoryId.inventoryOwnerId.gsm}
          (Vergi No): ${data.inventoryId.inventoryOwnerId.taxNo}
(Bundan sonra kısaca SATICI olarak anılacaktır.)

${contactData.name} ${contactData.surname} 
${contactData.address} 
${contactData.city} / ${contactData.country}
 ${contactData.gsm} 
 (Vergi No):${contactData.taxNo}
 

(Bundan sonra kısaca ALICI olarak anılacaktır.)`}
        </Text>
        {documentData.map(i => {
          return (
            <>
              <Text> </Text>
              <Text style={{ fontWeight: 500 }}>{i.title}</Text>
              <Text> </Text>
              <Text>
                <Text style={{ fontWeight: 500 }}>{i.caption}</Text> {i.text}
              </Text>
            </>
          );
        })}
      </Page>
    </Document>
  );
};

const ContractPDF = ({ data, contactData, style, click }) => {
  //console.log('style', style);
  const [isOpen, setOpen] = useState(false);

  return (
    <button
      onClick={event => {
        event.stopPropagation();
        setOpen(true);
      }}
    >
      {isOpen ? (
        <PDFDownloadLink
          document={
            <ContractPDFData
              styles={style}
              data={data}
              contactData={contactData}
            />
          }
          fileName={`sozlesme_bilgilendirmeformu.pdf`}
        >
          Sözleşme İndir
        </PDFDownloadLink>
      ) : null}
      {isOpen ? null : <ContractIcon />}
    </button>
  );
};

export default ContractPDF;
