import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllKvk = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25
) => {
  const request = await axios.get(
    `${BaseUrl}/api/kvkk/getAll/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  const payload = {
    data: request.data.kvkk,
    pageCount: request.data.pageCount,
  };
  return payload;
};
