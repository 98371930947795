import style from "./ProjectPopup.module.scss";
import {useTranslation} from "react-i18next";

const ProjectPopup = ({
  closeClick,
  errorText
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>{t("yeniProjePopUpKayitTitle")}</div>
          <hr />
          <div className={style.textcontainer}>
           {errorText}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("tamam")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
};

export default ProjectPopup;
