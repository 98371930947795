import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllLeads = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = "get",
  data = {}
) => {
  let request;

  if (method === "get") {
    request = await axios.get(
      `${BaseUrl}/api/lead/getall/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === "post") {
    request = await axios.post(
      `${BaseUrl}/api/lead/queryLead/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }
  const payload = {
    data: request.data.lead,
    pageCount: request.data.pageCount,
  };
  return payload;
};

export const deleteALead = async (jwt, leadId) => {
  const request = await axios.delete(
    `${BaseUrl}/api/lead/delete-lead/${leadId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    message: request.data.message,
  };
  //console.log(payload);
  return payload;
};

export const sendLeadToContact = async (jwt, leadId, userType, ownerInfo) => {
  const request = await axios.post(
    `${BaseUrl}/api/contact/addcontact/${leadId}/${userType}`,
    {
      data: ownerInfo,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  const payload = {
    message: request.data.message,
  };
  //console.log(payload);
  return payload;
};
